import { Select, Input, DatePicker } from "antd";
import "./newEscalation.css";
import TextArea from "antd/lib/input/TextArea";
import APICaller from "../../../utils/APICaller";
import secureLocalStorage from "react-secure-storage";
import { useContext, useEffect, useState } from "react";
import { GlobalStateContext } from "../../../utils/GlobalStateContext";
const onChange = (value) => {};
const onSearch = (value) => {};

const NewEscalation = () => {
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const slsRole = secureLocalStorage.getItem("role");
  const slsToken = secureLocalStorage.getItem("accessToken");
  const [clients, setClients] = useState([]),
    [processes, setProcesses] = useState([]),
    [departments, setDepartments] = useState([]),
    [reportingTLs, setReportingTLs] = useState([]),
    [agents, setAgents] = useState([]),
    [auditors, setAuditors] = useState([]),
    [escalationForWhom, setEscalationForWhom] = useState(""),
    [processId, setProcessId] = useState(),
    [processDepCVal, setProcessDepCVal] = useState(),
    [aformVal, setAformVal] = useState(),
    [formId, setFormId] = useState();

  /*setEscalationFormInput state is used to show Perform Audit selected fields on Audit Form*/
  const [escalationFormInput, setEscalationFormInput] = useState({
    Stage: "Audit Pending",
    Auditor: secureLocalStorage.getItem("lIU"),
    "Audit Score": "0.00%",
  });

  {
    /*Fetch the list of Clients*/
  }
  const getClients = () => {
    const endpoint = `/fetchWithoutPagination`;
    const method = "POST";
    const payload = { route: "clients", action: "findAll" };

    APICaller(slsRole, slsToken, endpoint, method, payload)
      .then((response) => {
        const temp = [];
        response?.map((client, index) => {
          temp.push({ label: client?.name, value: client?._id });
        });
        setClients(temp);
      })
      .catch((error) => {});
  };

  {
    /*Fetch the list of Departments*/
  }
  const getDepartments = () => {
    const endpoint = `/fetchWithoutPagination`;
    const method = "POST";
    const payload = { route: "departments", action: "findAll" };

    APICaller(slsRole, slsToken, endpoint, method, payload)
      .then((response) => {
        const temp = [];
        response?.map((department, index) => {
          temp.push({ label: department?.name, value: department?._id });
        });
        setDepartments(temp);
      })
      .catch((error) => {});
  };

  {
    /*Fetch the list of agents w.r.t Clients*/
  }
  const getAgents = () => {
    if (globalState?.eClientId) {
      const endpoint = "/users/findUserByRoleAndClient";
      const method = "POST";
      const payload = {
        reqRole: "agent",
        client: globalState?.eClientId,
      };

      APICaller(slsRole, slsToken, endpoint, method, payload)
        .then((response) => {
          const temp = [];
          response?.map((agent, index) => {
            temp.push({
              label: agent?.name + " (Emp ID: " + agent.empID + ")",
              value: agent?._id,
            });
          });
          setAgents(temp);
        })
        .catch((error) => {});
    }
  };

  {
    /*Fetch the list of auditors*/
  }
  const getAuditors = () => {
    const endpoint = "/users/findUserByRoleAndClient";
    const method = "POST";
    const payload = {
      reqRole: "auditor",
    };

    APICaller(slsRole, slsToken, endpoint, method, payload)
      .then((response) => {
        const temp = [];
        response?.map((auditor, index) => {
          temp.push({
            label: auditor?.name + " (Emp ID: " + auditor.empID + ")",
            value: auditor?._id,
          });
        });
        setAuditors(temp);
      })
      .catch((error) => {});
  };

  {
    /*Fetch the list of Processes w.r.t Departments*/
  }
  const getProcesses = () => {
    if (globalState?.eDepartmentId) {
      const endpoint = "/processes/findProcessesByDept";
      const method = "POST";
      const payload = {
        role: secureLocalStorage.getItem("role"),
        department: globalState?.eDepartmentId,
      };

      APICaller(slsRole, slsToken, endpoint, method, payload)
        .then((response) => {
          const temp = [];
          response?.map((process, index) => {
            temp.push({ label: process?.name, value: process?._id });
          });
          setProcesses(temp);
        })
        .catch((error) => {});
    }
  };

  {
    /*Fetch the list of Reporting TLs w.r.t Agents or Auditors*/
  }
  const getReportingTLs = (option) => {
    const endpoint = "/processes/findReportingTLByAgent";
    const method = "POST";
    const payload = {
      role: secureLocalStorage.getItem("role"),
      _id: option?.value,
    };

    APICaller(slsRole, slsToken, endpoint, method, payload)
      .then((response) => {
        const temp = [];
        response?.map((rS, index) => {
          temp.push({
            label: rS?.reportingSupervisor
              ? rS?.reportingSupervisor?.name
              : rS?.name,
            value: rS?.reportingSupervisor
              ? rS?.reportingSupervisor?._id
              : rS?._id,
          });
        });
        setReportingTLs(temp);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getClients();
    getAuditors();
    getDepartments();
  }, []);

  useEffect(() => {
    if (globalState?.eClientName) {
      getAgents();
    }
  }, [globalState?.eClientName]);

  useEffect(() => {
    if (globalState?.eDepartmentName) {
      getProcesses();
    }
  }, [globalState?.eDepartmentName]);

  const [isValidEmail, setIsValidEmail] = useState(true);

  const handleEmailChange = (event) => {
    const enteredEmail = event.target.value;
    if (enteredEmail) {
      // Regular expression for basic email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      setIsValidEmail(emailRegex.test(enteredEmail));
      setGlobalState((prevGlobalState) => {
        const newGlobalState = {
          ...prevGlobalState,
          eClientSpocEmailVal: "",
          eClientSpocEmail: emailRegex.test(enteredEmail),
        };
        return newGlobalState;
      });
    } else {
      setIsValidEmail("false");
    }
  };

  return (
    <>
      <div id="antdformstyle">
        <div className="formtitle">Dispute Details</div>
        <div className="details-container px-3">
          {/*Client Dropdown, Client SPOC Email Input*/}
          <div className="details-wrap">
            {/*Client Dropdown*/}
            <div className="details-inner">
              <div className="form-label-antd">Client</div>
              <Select
                showSearch
                allowClear
                placeholder="Choose a Client"
                optionFilterProp="children"
                onChange={(val, option) => {
                  setGlobalState((prevGlobalState) => {
                    const newGlobalState = {
                      ...prevGlobalState,
                      eClientName: option?.label,
                      eClientId: val ? val : "Client is required",
                    };
                    return newGlobalState;
                  });
                }}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={clients}
              />

              {globalState?.eClientId === "Client is required" && (
                <div className="error">{globalState?.eClientId}</div>
              )}
            </div>
            {/*Client SPOC Email Input*/}
            <div className="details-inner">
              <div className="form-label-antd">Client SPOC Email</div>
              <Input
                placeholder="Enter the Client SPOC Email"
                onChange={(e) => {
                  handleEmailChange(e);
                  setGlobalState((prevGlobalState) => {
                    const newGlobalState = {
                      ...prevGlobalState,
                      eClientSpocEmailVal: e.target.value,
                      eClientSpocEmail: e.target.value
                        ? ""
                        : "Client SPOC Email is required",
                    };
                    return newGlobalState;
                  });
                }}
              />
              {globalState?.eClientSpocEmail ===
                "Client SPOC Email is required" && (
                <div className="error">{globalState?.eClientSpocEmail}</div>
              )}
              {!isValidEmail && (
                <div className="error">Please enter a valid email address.</div>
              )}
            </div>
          </div>
          {/*Agent Dropdown, Auditor Dropdown*/}
          <div className="details-wrap">
            <div className="details-inner">
              <div className="form-label-antd">Agent</div>
              <Select
                showSearch
                allowClear
                disabled={escalationForWhom === "Auditor"}
                placeholder="Choose a Agent"
                optionFilterProp="children"
                onChange={(val, option) => {
                  if (val === undefined) {
                    // Reset the state when cleared
                    setEscalationForWhom("");
                    setReportingTLs([]);
                    setGlobalState((prevGlobalState) => {
                      const newGlobalState = {
                        ...prevGlobalState,
                        eAgentName: null,
                        eAgentId: null,
                      };
                      return newGlobalState;
                    });
                  } else {
                    setEscalationForWhom("Agent");
                    getReportingTLs(option);
                    setGlobalState((prevGlobalState) => {
                      const newGlobalState = {
                        ...prevGlobalState,
                        eAgentName: option?.label,
                        eAgentId: val ? val : null,
                      };
                      return newGlobalState;
                    });
                  }
                }}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={agents}
              />
              {/* {globalState?.eAgentId === "Agent is required" && (
                <div className="error">{globalState?.eAgentId}</div>
              )} */}
            </div>
            <div className="details-inner">
              <div className="form-label-antd">Auditor</div>
              <Select
                showSearch
                allowClear
                disabled={escalationForWhom === "Agent"}
                placeholder="Choose a Auditor"
                optionFilterProp="children"
                onChange={(val, option) => {
                  if (val === undefined) {
                    // Reset the state when cleared
                    setEscalationForWhom("");
                    setReportingTLs([]);
                    setGlobalState((prevGlobalState) => {
                      const newGlobalState = {
                        ...prevGlobalState,
                        eAuditorName: null,
                        eAuditorId: null,
                      };
                      return newGlobalState;
                    });
                  } else {
                    setEscalationForWhom("Auditor");
                    getReportingTLs(option);
                    setGlobalState((prevGlobalState) => {
                      const newGlobalState = {
                        ...prevGlobalState,
                        eAuditorName: option?.label,
                        eAuditorId: val ? val : null,
                      };
                      return newGlobalState;
                    });
                  }
                }}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={auditors}
              />
            </div>
          </div>
          {/*Reporting TL Dropdown, Department Dropdown*/}
          <div className="details-wrap">
            <div className="details-inner">
              <div className="form-label-antd">Reporting TL</div>
              <Select
                showSearch
                allowClear
                placeholder="Choose a Reporting TL"
                optionFilterProp="children"
                onChange={(val, option) => {
                  setGlobalState((prevGlobalState) => {
                    const newGlobalState = {
                      ...prevGlobalState,
                      eReportingTlName: option?.label,
                      eReportingTlId: val ? val : "Reporting TL is required",
                    };
                    return newGlobalState;
                  });
                }}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={reportingTLs}
              />
              {globalState?.eReportingTlId === "Reporting TL is required" && (
                <div className="error">{globalState?.eReportingTlId}</div>
              )}
            </div>

            <div className="details-inner">
              <div className="form-label-antd">Department</div>
              <Select
                showSearch
                allowClear
                placeholder="Choose a Department"
                optionFilterProp="children"
                onChange={(val, option) => {
                  setProcessDepCVal("");
                  setProcessId("");
                  setAformVal("");
                  setFormId("");
                  setGlobalState((prevGlobalState) => {
                    const newGlobalState = {
                      ...prevGlobalState,
                      eDepartmentName: option?.label,
                      eDepartmentId: val ? val : "Department is required",
                    };
                    return newGlobalState;
                  });
                }}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={departments}
              />
              {globalState?.eDepartmentId === "Department is required" && (
                <div className="error">{globalState?.eDepartmentId}</div>
              )}
            </div>
          </div>
          {/*Process Dropdown, Error Raise Date*/}
          <div className="details-wrap">
            <div className="details-inner">
              <div className="form-label-antd">Process</div>
              <Select
                showSearch
                allowClear
                placeholder="Choose a Process"
                optionFilterProp="children"
                onChange={(val, option) => {
                  setGlobalState((prevGlobalState) => {
                    const newGlobalState = {
                      ...prevGlobalState,
                      eProcessName: option?.label,
                      eProcessId: val ? val : "Process is required",
                    };
                    return newGlobalState;
                  });
                }}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={processes}
              />
              {globalState?.eProcessId === "Process is required" && (
                <div className="error">{globalState?.eProcessId}</div>
              )}
            </div>

            <div className="details-inner">
              <div className="form-label-antd">Error Raise Date</div>
              <DatePicker
                showSearch
                placeholder="Choose a Date"
                optionFilterProp="children"
                onChange={(date) => {
                  setGlobalState((prevGlobalState) => {
                    const newGlobalState = {
                      ...prevGlobalState,
                      eErrorRaiseDateVal: date,
                      eErrorRaiseDate: date
                        ? date
                        : "Error raise date is required",
                    };
                    return newGlobalState;
                  });
                }}
                value={globalState?.eErrorRaiseDateVal}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
              {globalState?.eErrorRaiseDate ===
                "Error raise date is required" && (
                <div className="error">{globalState?.eErrorRaiseDate}</div>
              )}
            </div>
          </div>
        </div>
        <div className="formtitle">Transaction Details</div>
        <div className="details-container px-3">
          {/*Transaction ID Input, Transaction Date*/}
          <div className="details-wrap">
            <div className="details-inner">
              <div className="form-label-antd">Transaction ID</div>
              <Input
                placeholder="Enter the Transaction ID"
                onChange={(e) => {
                  setGlobalState((prevGlobalState) => {
                    const newGlobalState = {
                      ...prevGlobalState,
                      eTransactionIdVal: e.target.value,
                      eTransactionId: e.target.value
                        ? e.target.value
                        : "Transaction ID is required",
                    };
                    return newGlobalState;
                  });
                }}
              />
              {globalState?.eTransactionId === "Transaction ID is required" && (
                <div className="error">{globalState?.eTransactionId}</div>
              )}
            </div>

            <div className="details-inner">
              <div className="form-label-antd">Transaction Date</div>
              <DatePicker
                onChange={(date) => {
                  setGlobalState((prevGlobalState) => {
                    const newGlobalState = {
                      ...prevGlobalState,
                      eTransactionDateVal: date,
                      eTransactionDate: date
                        ? date
                        : "Transaction Date is required",
                    };
                    return newGlobalState;
                  });
                }}
                value={globalState?.eTransactionDateVal}
              />
              {globalState?.eTransactionDate ===
                "Transaction Date is required" && (
                <div className="error">{globalState?.eTransactionDate}</div>
              )}
            </div>
          </div>
        </div>

        <div className="formtitle">Error Details</div>
        <div className="details-container px-3">
          {/*Error Description Textarea and Error Investigation Remarks*/}
          <div className="details-wrap">
            <div className="details-inner">
              <div className="form-label-antd">Error Description</div>
              <TextArea
                placeholder="Free text, write in detail, what was done , what went wrong , what is the right process. If error is reversed, the explain reason also."
                onChange={(e) => {
                  setGlobalState((prevGlobalState) => {
                    const newGlobalState = {
                      ...prevGlobalState,
                      eErrorDescriptionVal: e.target.value,
                      eErrorDescription: e.target.value
                        ? e.target.value
                        : "Error description is required",
                    };
                    return newGlobalState;
                  });
                }}
              />
              {globalState?.eErrorDescription ===
                "Error description is required" && (
                <div className="error">{globalState?.eErrorDescription}</div>
              )}
            </div>
            <div className="details-inner">
              <div className="form-label-antd">Error Investigation Remarks</div>
              <TextArea
                placeholder="Enter remarks here"
                onChange={(e) => {
                  setGlobalState((prevGlobalState) => {
                    const newGlobalState = {
                      ...prevGlobalState,
                      eErrorInvestigationRemarksVal: e.target.value,
                      eErrorInvestigationRemarks: e.target.value
                        ? e.target.value
                        : "Error investigation remarks is required",
                    };
                    return newGlobalState;
                  });
                }}
              />
              {globalState?.eErrorInvestigationRemarks ===
                "Error investigation remarks is required" && (
                <div className="error">
                  {globalState?.eErrorInvestigationRemarks}
                </div>
              )}
            </div>
          </div>

          {/*Error Impact Dropdown*/}
          <div className="details-wrap">
            <div className="details-inner">
              <div className="form-label-antd">Error Impact</div>
              <Select
                showSearch
                allowClear
                placeholder="Choose an Impact"
                optionFilterProp="children"
                onChange={(val, option) => {
                  setGlobalState((prevGlobalState) => {
                    const newGlobalState = {
                      ...prevGlobalState,
                      eErrorImpactVal: option?.label,
                      eErrorImpact: val ? val : "Error impact is required",
                    };
                    return newGlobalState;
                  });
                }}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={[
                  {
                    value: "LOW",
                    label: "LOW",
                  },
                  {
                    value: "MEDIUM",
                    label: "MEDIUM",
                  },
                  {
                    value: "HIGH",
                    label: "HIGH",
                  },
                ]}
              />
              {globalState?.eErrorImpact === "Error impact is required" && (
                <div className="error">{globalState?.eErrorImpact}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewEscalation;
