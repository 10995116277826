import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../Header";
import NavBar from "../NavBar";
import "./main.css";
import { GlobalStateContext } from "../../../utils/GlobalStateContext";
import secureLocalStorage from "react-secure-storage";
import APICaller from "../../../utils/APICaller";

export default function Main({
  children = null,
  key_elem = "1",
  nameLink = "",
}) {
  const location = useLocation();
  const slsRole = secureLocalStorage.getItem("role");
  const slsToken = secureLocalStorage.getItem("accessToken");
  {
    /* Get Logo and Logo's title*/
  }
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const logoInfo = async (page = 1, pageSize = 1) => {
    try {
      setGlobalState({ ...globalState, list: [] });
      const endpoint = "/settings/findAll";
      const method = "POST";
      const payload = { page, pageSize };

      const response = await APICaller(
        slsRole,
        slsToken,
        endpoint,
        method,
        payload
      );
      const { settings } = response;

      const temp = settings.map((dataList, index) => ({
        title: dataList.title,
        logo: dataList.logo,
      }));

      setGlobalState({
        ...globalState,
        logo: temp[0]?.logo,
        logoTitle: temp[0]?.title,
      });
    } catch (error) {
      console.error("Error fetching settings", error);
    }
  };

  useEffect(() => {
    logoInfo();
  }, [1]);
  return (
    <div>
      {globalState?.logo && globalState?.logoTitle && (
        <>
          <Header nameLink={nameLink} />
          <div className="main-layout d-flex flex-wrap vh-100">
            <NavBar />

            <div
              className={
                (location.pathname.split("/")[1] === "auditForms" ||
                  location.pathname.split("/")[1] === "audits" ||
                  location.pathname.split("/")[1] === "deleted-audits" ||
                  location.pathname.split("/")[1] === "escalations" ||
                  (location.pathname.split("/")[2] === "preview" &&
                    location.pathname.split("/")[3])) &&
                location.pathname.split("/")[2]
                  ? "right-side detailPage"
                  : "right-side"
              }
            >
              {children}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
