import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Form,
  Layout,
  message,
  Row,
  Select,
  Table,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import "./dashboard.css";
import moment from "moment";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import DashboardCards from "./dashboardCards";
import { PDFDocument, rgb } from "pdf-lib";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import dayjs from "dayjs";
import secureLocalStorage from "react-secure-storage";
import APICaller from "../../utils/APICaller";
import CustomLoader from "../CustomLoader";

import ChartWithPDF from "./chartwithpdf";

Chart.register(CategoryScale, ChartDataLabels);

const Dashboard = () => {
  const { Content } = Layout;
  const { Panel } = Collapse;
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  
  const slsRole = secureLocalStorage.getItem("role");
  const slsToken = secureLocalStorage.getItem("accessToken");
  const slsLIUId = secureLocalStorage.getItem("lIUId");
  const slsSelectedMonthRange = secureLocalStorage.getItem("selectedMonthRange")
  // Initialize with the current month
  const currentMonth = dayjs();
  const minMonth = dayjs("2023-11")
  
  const [defaultValue, setDefaultValue] = useState([currentMonth, currentMonth]);
  const [rangeValue, setRangeValue] = useState([currentMonth, currentMonth]);
  const [isValid, setIsValid] = useState(true);
  const [selectedMonthRange, setSelectedMonthRange] = useState("");
  const initialMonth = currentMonth.format("MMM, YYYY");
   
  const startOfMonth = currentMonth.startOf("month");
  const endOfMonth = currentMonth.endOf("month");
  const [startMonth, setStartMonth] = useState(startOfMonth.format("MMM, YYYY"));
  const [endMonth, setEndMonth] = useState(endOfMonth.format("MMM, YYYY"));
   
  const [showAuditCountDetails, setShowAuditCountDetails] = useState(false);
  
  const [clients, setClients] = useState([]);
  const [agents,setAgents] = useState([]);
  const [tls, setTls] = useState([]);
  const [atls, setAtls] = useState([]);
  const [departments, setDepartments] = useState([]);  
  const [auditForms, setAuditForms] = useState([]);
  const [qcs, setQcs] = useState([]);
  const [auditors, setAuditors] = useState([]);
  
  const [dashboardDetails, setDashboardDetails] = useState({});
  const [showLoader, setShowLoader] = useState(false);

  /*begin::getUsers*/
    const getUsers = () => {
      const endpoint = "/fetchWithoutPagination";
      const method = "POST";
      const payload = {
        route: "users",
        action: "findAll",
        fields: ["qc","auditor","tl", "atl"],
        role: slsRole,        
        lIUId:slsLIUId
      };

      APICaller(slsRole, slsToken, endpoint, method, payload)
        .then((response) => { 
          const storeAllTls = [];
          const getAllTls = response.filter((user) => user?.role === "tl");
          
          slsRole.trim() === process.env.REACT_APP_MGRROLE.trim() ?
            getAllTls?.map((user, index) => { 
              user?.reportingSupervisor === slsLIUId &&
              storeAllTls.push({
                label: user?.name + " (EmpID: " + user?.empID + ")",
                value: user?._id,              
            })})
            :
            getAllTls?.map((user, index) => {
              storeAllTls.push({
                label: user?.name + " (EmpID: " + user?.empID + ")",
                value: user?._id,
              });
            });
          setTls(storeAllTls);

          const storeAllQcs = [];
          const getAllQcs = response.filter((user) => user?.role === "qc");

          getAllQcs?.map((user, index) => {
            storeAllQcs.push({
              label: user?.name + " (EmpID: " + user?.empID + ")",
              value: user?._id,
            });
          });
          setQcs(storeAllQcs);

          const storeAllAuditors = [];
          const getAllAuditors = response.filter((user) => user?.role === "auditor");

          getAllAuditors?.map((user, index) => {
            storeAllAuditors.push({
              label: user?.name + " (EmpID: " + user?.empID + ")",
              value: user?._id,
            });
          });
          setAuditors(storeAllAuditors);
        })
        .catch((error) => {});
    };
  /*end::getUsers*/

  /*begin::getDepartments*/
    const getDepartments = () => {
      const endpoint = "/fetchWithoutPagination";
      const method = "POST";
      const payload = { route: "departments", action: "findAll" };

      APICaller(slsRole, slsToken, endpoint, method, payload)
        .then((response) => {
          const temp = [];
          response?.map((department, index) => {
            temp.push({ label: department?.name, value: department?._id });
          });
          setDepartments(temp);
        })
        .catch((error) => {});
    };
  /*end::getDepartments*/

  const getDashboardDetails = async () => {
    try {      
      secureLocalStorage.setItem("selectedMonthRange", selectedMonthRange || initialMonth);
      setShowLoader(true);
      const endpoint = "/dashboardDetails";
      const method = "POST";
      const payload = {
        role: slsRole,
        lIUId: slsLIUId,
        filterValues:{initialMonth:!selectedMonthRange ? true:false,startMonth,endMonth,clients,tls,departments,auditForms,auditors}
      };
      
      const response = await APICaller(
        slsRole,
        slsToken,
        endpoint,
        method,
        payload,        
      );
      if (response) {
        setDashboardDetails(response);
        setShowLoader(false);
      }
    } catch (error) {
      const errorMessage = error.data?.message || "An error occurred";
    }
  };
  useEffect(() => {
    getUsers();
    getDepartments();        
    if(slsRole===process.env.REACT_AGROLE){
      getAgentClients();       
    }else{
      getClients(); 
    }
    getDashboardDetails();    
  }, [slsRole,slsToken,slsLIUId]);
  
  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // Colors for pie chart
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
  // const handleMonthChange = (date) => {
  //   setFilterSelectedValues({selectedMonth:date});
  // };

  // const handleTlChange = (value) => {
  //   setFilterSelectedValues({tlID:value});
  // };

  // const handleDepartmentChange = (value) => {
  //   setFilterSelectedValues({departmentID:value});
  // };

  // const handleAuditorChange = (value) => {
  //   setFilterSelectedValues({auditorID:value});
  // };

  // const handleFormChange = (value) => {
  //   setFilterSelectedValues({auditFormID:value});
  // };
  const handleFormSubmit = async (values) => {
    setShowLoader(true);
    secureLocalStorage.setItem("selectedMonthRange", selectedMonthRange || initialMonth);
    const selectedTls=slsRole===process.env.REACT_APP_MGRROLE && values?.tls?.length===0?tls:values?.tls;
    const endpoint = "/dashboardDetails";
    const method = "POST";
    const payload = { 
      role: slsRole,
      lIUId: slsLIUId,
      filterValues:{startMonth,endMonth,clients:values?.clients,tls:selectedTls,departments:values?.departments,auditForms:values?.auditForms,auditors:values?.auditors}
    };
    
    const response = await APICaller(
      slsRole,
      slsToken,
      endpoint,
      method,
      payload,        
    );
    if (response) {      
      setDashboardDetails(response);
      setShowLoader(false);
    }
    // Add logic to handle form values
  };
  
  // New Audit Count Chart Data
  const auditCountChartdata = {
    labels: dashboardDetails?.auditCountAsPerClient?.labels,
    datasets: [
      {
        label: slsSelectedMonthRange,
        data: dashboardDetails?.auditCountAsPerClient?.values,
        backgroundColor: "rgba(77, 192, 192, 0.6)",
        barThickness: 20,
      },
    ],
  };

  const auditCountChartoptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      datalabels: {
        display: true,
        color: "black",
        anchor: "end",
        align: "top",
        font: {
          weight: "bold",
          size: "12px",
        },
        formatter: (value, context) => {
          const month = context.chart.data.labels[context.dataIndex];
          const year = moment.utc(new Date()).local().format("YYYY");
          return `${value}`;
        },
        backgroundColor: "#000000",
        color: "#ffffff",
        borderRadius: "5", // Add border radius
        padding: "4",
      },
    },
  };

  // New Client Wise Chart Data
  const clientWiseChartdata = {
    labels: dashboardDetails?.clientWiseAuditScore?.labels,
    datasets: [
      {
        label: slsSelectedMonthRange,
        data: dashboardDetails?.clientWiseAuditScore?.values,
        backgroundColor: "rgba(255, 0, 0, 0.5)",
        barThickness: 5,
        borderRadius: 10,
        barPercentage: 0.7,
        categoryPercentage: 0.5,
      },
    ],
  };

  const clientWiseChartoptions1 = {
    responsive: true,
    scales: {
      x: {
        title: {
          display: false,
          text: "Values", // Title for the X-axis
        },
        ticks: {
          // color: "blue", // Customize tick color
          // font: {
          //   size: 14, // Customize font size
          //   weight: "bold", // Customize font weight
          // },
          callback: (value) => {
            return `${value} %`; // Custom formatting for labels
          },
        },
      },
    },
    indexAxis: "y", // Set this to 'y' for horizontal bars
    plugins: {
      legend: {
        position: "top",
      },
      datalabels: {
        display: true,
        color: "black",
        anchor: "top",
        align: "right", // Align to the right for horizontal bars
        font: {
          weight: "bold",
          size: "12px",
        },
        formatter: (value, context) => {
          const month = context.chart.data.labels[context.dataIndex];
          const year = moment.utc(new Date()).local().format("YYYY");
          const currencySymbol = "";
          return `${currencySymbol} ${value} %`;
        },
        backgroundColor: "#000000",
        color: "#ffffff",
        borderRadius: 0, // Add border radius
        padding: 4,
      },
    },
  };

  const clientWiseChartoptions = {
    responsive: true,
    scales: {
      x: {
        title: {
          display: false,
          text: "Values",
        },
        ticks: {
          callback: (value) => {
            return `${value} %`; // Custom formatting for labels
          },
        },
      },
    },
    indexAxis: "y", // Set this to 'y' for horizontal bars
    layout: {
      padding: {
        top: 10,
        right: 80, // Add more space for labels
        bottom: 10,
        left: 10,
      },
    },
    
    plugins: {
      legend: {
        position: "top",
      },
      datalabels: {
        display: true,
        color: "white",
        anchor: "end", // Place the label at the end of the bar
        align: "end", // Align the label at the edge of the bar
        clip: false, // Prevent labels from being clipped at the chart's edge
        font: {
          weight: "bold",
          size: 12,
        },
        formatter: (value, context) => {
          const month = context.chart.data.labels[context.dataIndex];
          const year = moment.utc(new Date()).local().format("YYYY");
          const currencySymbol = "";
          return `${currencySymbol} ${value} %`;
        },
        backgroundColor: "rgba(0, 0, 0, 0.7)", // Optional: Semi-transparent black background
        borderRadius: 4,
        padding: 6,
      },
    },
  };

  //Audit Count Table
  

  const additionalColumns = slsRole===process.env.REACT_APP_MGRROLE ? [{
    title: "TL",
    dataIndex: "tl",      
    render: (text, record) => {
      const tls=text && typeof text === 'string' && text?.includes(",")?text?.split(",").length:text;
      return (
        <div>{tls}</div>
      )
    }
  }]:[];
  const columns = [    
    {
      title: "Client",
      dataIndex: "client",      
    },
    ...additionalColumns,
    {
      title: "Department",
      dataIndex: "departments",      
    },
    {
      title: "Agents",
      dataIndex: "agents",
      render: (text, record) => {
        const agents=text && typeof text === 'string' && text?.includes(",")?text?.split(",").length:text;
        return (
          <div>{agents}</div>
        )
      }      
    },
    {
      title: "Audit Score",
      dataIndex: "auditScore",
    },
    
  ];

  // Sample data
  const departmentWiseAuditScorePieData = {
    labels: dashboardDetails?.departmentWiseAuditScore?.labels,
    datasets: [
      {
        data: dashboardDetails?.departmentWiseAuditScore?.values,
        backgroundColor: [
          "rgba(255, 0, 0, 0.4)",
          "rgba(0, 255, 0, 0.4)",
          "rgba(0, 0, 255, 0.4)",
          "rgba(255, 9, 132, 0.4)",
        ],
        hoverBackgroundColor: [
          "rgba(255, 0, 0, 0.8)",
          "rgba(0, 255, 0, 0.8)",
          "rgba(0, 0, 255, 0.8)",
          "rgba(255, 9, 132, 0.8)",
        ],
      },
    ],
  };

  const departmentWiseChartoptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || "";
            const value = context.raw || 0;
            return ` ${value} %`;
          },
        },
        bodyFont: {
          size: 12, // Font size for tooltip
        },
      },
      datalabels: {
        color: "white",
        anchor: "middle",
        align: "center",
        formatter: (value, context) => {
          return `${value} %`;
        },
        font: {
          weight: "bold",
          size: 10,
        },
        // Adding background to the labels
        backgroundColor: "#000000ba",
        borderRadius: 4,
        padding: 3,
        // Add shadow for better visibility
        shadowColor: "rgba(0, 0, 0, 0.5)",
        shadowBlur: 6,
        shadowOffsetX: 2,
        shadowOffsetY: 2,
      },
    },
  };

  const chartRef = useRef(null);
  const downloadPDF = async (rf) => {
    const chartCanvas = rf.current.canvas;
    const chartImage = chartCanvas.toDataURL("image/png");

    // Extract chart data
    const labels = rf.current.data.labels;
    const dataset = rf.current.data.datasets[0];

    // Create a new PDF document
    const pdfDoc = await PDFDocument.create();
    const pageWidth = 595; // Width for A4 size in points
    const pageHeight = 842; // Height for A4 size in points

    // ** NEW ** Embed company logo image (replace with your logo URL or data)
    const companyLogoUrl = "/PdfLogo.png"; // Replace this URL with the actual logo path
    const logoImageBytes = await fetch(companyLogoUrl).then((res) =>
      res.arrayBuffer()
    );
    const logoImage = await pdfDoc.embedPng(logoImageBytes); // or embedJpg if JPG format

    const drawChartOnPage = async (page) => {
      // Embed the chart image into the PDF
      const pngImage = await pdfDoc.embedPng(chartImage);
      page.drawImage(pngImage, {
        x: 20,
        y: pageHeight - 370,
        width: 550,
        height: 300,
      });
    };

    const drawTextOnPage = (page, text, yPosition) => {
      page.drawText(text, {
        x: 20,
        y: yPosition,
        size: 12,
        color: rgb(0, 0, 0),
      });
    };

    const addExpenseDetails = (page, startY) => {
      page.drawText("Expense Details:", {
        x: 20,
        y: startY,
        size: 16,
        color: rgb(0, 0, 0),
      });
      return startY - 20;
    };

    // Start creating pages
    let page = pdfDoc.addPage([pageWidth, pageHeight]);
    let yPosition = pageHeight - 100;

    // Add chart title and company logo side by side
    page.drawText("Expense Report", {
      x: 20,
      y: pageHeight - 50,
      size: 20,
      color: rgb(0, 0, 0),
    });

    page.drawImage(logoImage, {
      x: pageWidth - 120, // Adjust X position based on the logo width
      y: pageHeight - 60, // Adjust Y position based on the logo height
      width: 100, // Set the width of the logo
      height: 30, // Set the height of the logo
    });

    // Add chart
    await drawChartOnPage(page);

    // Initialize for expense details
    yPosition -= 300; // Adjust yPosition based on chart height
    yPosition = addExpenseDetails(page, yPosition); // Add "Expense Details" title only on the first page

    let currentPageIndex = 0;
    const linesPerPage = Math.floor((pageHeight - 100 - 300 - 20) / 20); // Calculate how many lines fit per page
    const totalLines = labels.length;

    const addTextToPage = () => {
      let startIndex = currentPageIndex * linesPerPage;
      let endIndex = Math.min(startIndex + linesPerPage, totalLines);

      if (endIndex <= totalLines) {
        for (let i = startIndex; i < endIndex; i++) {
          const label = labels[i];
          const value = dataset.data[i];
          const currencySymbol = "";
          drawTextOnPage(
            page,
            `${label}: ${currencySymbol}${value} (${moment
              .utc(new Date())
              .local()
              .format("YYYY")})`,
            yPosition
          );
          yPosition -= 20;
        }

        if (endIndex < totalLines) {
          // If more lines to add, create a new page
          currentPageIndex++;
          page = pdfDoc.addPage([pageWidth, pageHeight]);
          yPosition = pageHeight - 50; // Reset position on new page without re-adding the "Expense Details" title
          addTextToPage(); // Recurse to add remaining lines without re-adding "Expense Details" title
        }
      }
    };

    addTextToPage(); // Start adding text

    // Save the PDF and trigger download
    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    saveAs(blob, "expenseReport.pdf");
  };

  {
    /*Fetch Clients*/
  }
  const getClients = () => {
    const endpoint = "/fetchWithoutPagination";
    const method = "POST";
    const payload = { route: "clients", action: "findAll" };

    APICaller(slsRole, slsToken, endpoint, method, payload)
      .then((response) => {
        const temp = [];
        response?.map((client, index) => {
          temp.push({ label: client?.name, value: client?._id });
        });
        setClients(temp);
      })
      .catch((error) => {});
  };
  
  {
    /*Fetch Client's Agents*/
  }
  
  const handleClientAgents = (clientID) => {    
    const endpoint = "/fetchWithoutPagination";
    const method = "POST";
    const payload = { route: "clients", action: "findAll" };

    APICaller(slsRole, slsToken, endpoint, method, payload)
      .then((response) => {
        const temp = [];
        response?.map((client, index) => {
          temp.push({ label: client?.name, value: client?._id });
        });
        setClients(temp);
      })
      .catch((error) => {});
  };

  {
    /*Fetch Agent's Tls*/
  }
  const handleAgentTls = (agentID) => {
    
  }

  {
    /*Fetch Tl's Atls*/
  }
  const handleTlAtls = (tlID) => {
    
  }

  //getAgentTls    

  //getAuditForms

  const getAgentClients = () => {
    const endpoint = "/fetchWithoutPagination";
    const method = "POST";
    const payload = { route: "agentClients", action: "findAll", slsRole, slsLIUId };

    APICaller(slsRole, slsToken, endpoint, method, payload)
      .then((response) => {
        const temp = [];
        response?.map((client, index) => {
          temp.push({ label: client?.name, value: client?._id });
        });
        setClients(temp);
      })
      .catch((error) => {});
  };
  const handleDepartmentAuditForms = (val) =>{
    const endpoint = "/fetchWithoutPagination";
    const method = "POST";
    const payload = { route: "departmentAuditForms", action: "findAll", slsRole, slsLIUId, value:val };

    APICaller(slsRole, slsToken, endpoint, method, payload)
      .then((response) => {
        const temp = [];
        response?.map((auditForm, index) => {
          temp.push({ label: auditForm?.formName, value: auditForm?._id });
        });
        setAuditForms(temp);        
      })
      .catch((error) => {});
  }

  const downloadExcel = () => {
    const flattenData = (data, parent = {}) => {
      const rows = [];
  
      data.forEach((row) => {
        // Add the current row
        const flatRow = {
          ...parent, // Include parent details (if any)
          ...columns.reduce((acc, col) => {
            acc[col.title] = row[col.dataIndex];
            return acc;
          }, {}),
        };
        rows.push(flatRow);
  
        // Add children (if any)
        if (row.children && row.children.length > 0) {
          const childRows = flattenData(row.children, {
            "Parent Client/TL": row.client || row.tl || "N/A",
          });
          rows.push(...childRows);
        }
      });
  
      return rows;
    };
  
    // Flatten the dashboard details
    const data = flattenData(dashboardDetails?.auditCountAsPerClientDetails || []);
  
    // Generate Excel
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "client_wise_audit_count_details.xlsx");
  };
  
  
  const handleRangeChange = (dates) => {    
    if (!dates) {
      setStartMonth(defaultValue[0].format("MMM, YYYY")); // Reset start month
      setEndMonth(defaultValue[1].format("MMM, YYYY")); // Reset end month
      setSelectedMonthRange(defaultValue[0].format("MMM, YYYY"));
      // Reset if clear is clicked
      setRangeValue([currentMonth, currentMonth]);
      setIsValid(true);
      return;
    }

    const [start, end] = dates;
    const startMonth = start.format("MMM, YYYY");
    const endMonth = end.format("MMM, YYYY");
    
    // Check if the start month is before 2023-11    
    if (start.isSame(end, "month")) {
      // If both are from the same month
      setStartMonth(startMonth); //For Filter
      setEndMonth(startMonth); //For Filter
      setSelectedMonthRange(startMonth); //Showing on JSX
      setIsValid(true);
    } else if (dayjs(start.format("YYYY-MM")).isBefore(minMonth)) {
      setRangeValue([currentMonth, currentMonth]);
      setIsValid(false);
      setSelectedMonthRange(`${startMonth} - ${endMonth}`); //Showing on JSX      
      message.error(`Cannot select months before ${minMonth.format("MMM, YYYY")}`);
    }else {       
      setIsValid(true);
      setRangeValue(dates);
      setStartMonth(startMonth); //For Filter
      setEndMonth(endMonth); //For Filter      
      setSelectedMonthRange(`${startMonth} - ${endMonth}`); //Showing on JSX      
    }
  };

  return (
    <>    
      <Layout>           
        <CustomLoader showLoader={showLoader} />
        <Content className="dContent">
          <div className="filters-container mt-0">
            <Collapse accordion className="collapse-filters">
              <Panel className="panel-title" header="Filters" key="1">
                <Form onFinish={handleFormSubmit}>
                  <Row gutter={24}>
                    {/*Month*/}
                    <Col span={slsRole==='admin'?5:5}>
                      <Form.Item name="month" className="m-0">
                      <RangePicker
                        format="MMMM YYYY"
                        picker="month"
                        allowClear
                        value={rangeValue}
                        onChange={handleRangeChange}
                        defaultValue={[currentMonth, currentMonth]}
                      />
                            
                      </Form.Item>
                    </Col>
                    
                    {/*Clients*/}
                    <Col span={slsRole==='admin'?3:3}>
                      <Form.Item name="clients" className="m-0">
                        <Select
                          showSearch
                          allowClear
                          mode={"multiple"}
                          isclearable="true"
                          className="dsh-filter-style"
                          placeholder="Select Client"
                          
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              ?.toLowerCase()
                              .includes(input?.toLowerCase())
                          }
                          options={clients}
                          onChange={(val, option) => {
                            setAgents([]);
                            if(val){
                              handleClientAgents(val);
                            }
                          }}
                        >                          
                        </Select>
                      </Form.Item>
                    </Col>

                    {/*Tls w.r.t. Agent*/}
                    {(slsRole!=='tl' && slsRole!=='atl' && slsRole!=='agent') &&
                      <Col span={slsRole==='admin'?3:3}>
                        <Form.Item name="tls" className="m-0">
                          <Select
                            showSearch
                            allowClear
                            mode={"multiple"}
                            isclearable="true"
                            className="dsh-filter-style"
                            placeholder="Select TL"
                            
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                ?.toLowerCase()
                                .includes(input?.toLowerCase())
                            }
                            options = {tls}
                            onChange={(val, option) => {
                              setAtls([]);
                              if(val){
                                handleTlAtls(val);
                              }
                            }}
                          >
                          </Select>
                        </Form.Item>
                      </Col>
                    }

                    {/*Agents w.r.t. Client*/}
                    {(slsRole==='tl' && slsRole==='atl') && <Col span={slsRole==='admin'?3:3}>
                        <Form.Item name="agents" className="m-0">
                          <Select
                            showSearch
                            allowClear
                            className="dsh-filter-style"
                            placeholder="Select Agent"
                            
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                ?.toLowerCase()
                                .includes(input?.toLowerCase())
                            }
                            options = {agents}
                            onChange={(val, option) => {
                              setTls([]);
                              if(val){
                                handleAgentTls(val);
                              }
                            }}
                          >                                                
                          </Select>
                        </Form.Item>
                      </Col>
                    }
                    
                    {/*Atls w.r.t. Tl*/}
                    {/* {slsRole==='admin' &&
                    <Col span={slsRole==='admin'?3:3}>
                      <Form.Item name="atl" className="m-0">
                        <Select
                          showSearch
                          allowClear
                          className="dsh-filter-style"
                          placeholder="Select ATL"
                          
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              ?.toLowerCase()
                              .includes(input?.toLowerCase())
                          }
                          options = {atls}
                          onChange={(val, option) => {
                            setAtls([]);
                            
                          }}
                        >                          
                        </Select>
                      </Form.Item>
                    </Col>}                   */}

                    {/*Department*/}
                    <Col span={slsRole==='admin'?3:3}>
                      <Form.Item name="departments" className="m-0">
                        <Select
                          showSearch
                          allowClear
                          mode={"multiple"}
                          isclearable="true"
                          className="dsh-filter-style"
                          placeholder="Select Department"
                          
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              ?.toLowerCase()
                              .includes(input?.toLowerCase())
                          }
                          options = {departments}
                          onChange={(val, option) => {
                            setAuditForms([]);
                            if(val){
                              handleDepartmentAuditForms(val);
                            }
                          }}
                        >                          
                        </Select>
                      </Form.Item>
                    </Col>
                    
                    {/*AuditForm w.r.t Department's process*/}
                    <Col span={slsRole==='admin'?4:3}>
                      <Form.Item name="auditForms" className="m-0">
                        <Select
                          showSearch
                          allowClear
                          mode={"multiple"}
                          isclearable="true"
                          className="dsh-filter-style"
                          placeholder="Select AuditForm"                          
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              ?.toLowerCase()
                              .includes(input?.toLowerCase())
                          }
                          options={auditForms}
                        >                          
                        </Select>
                      </Form.Item>
                    </Col>
                    
                    {/*Qcs*/}
                    {/* {slsRole==='admin' && <Col span={slsRole==='admin'?3:3}>
                      <Form.Item name="qc" className="m-0">
                        <Select
                          showSearch
                          allowClear
                          className="dsh-filter-style"
                          placeholder="Select Qc"
                          
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              ?.toLowerCase()
                              .includes(input?.toLowerCase())
                          }

                          options={qcs}
                        >                          
                        </Select>
                      </Form.Item>
                    </Col>} */}

                    {/*Auditors*/}
                    {(slsRole==='admin' || slsRole==='qc' || slsRole==='agent') &&
                    <Col span={slsRole==='admin'?4:3}>
                      <Form.Item name="auditors" className="m-0">
                        <Select
                          showSearch
                          allowClear
                          mode={"multiple"}
                          isclearable="true"
                          className="dsh-filter-style"
                          placeholder="Select Auditor"
                          
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              ?.toLowerCase()
                              .includes(input?.toLowerCase())
                          }
                          options={auditors}
                        >                          
                        </Select>
                      </Form.Item>
                    </Col>}
                    {isValid &&
                    <Col span={slsRole==='admin'?2:3}>
                      <Form.Item className="mb-0">
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="main-sign-out dsh-submit-btn"
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </Col>}
                  </Row>
                </Form>
              </Panel>
            </Collapse>
          </div>
          <div className="graph-outer-container">
            <p className="namelinkclass">{slsSelectedMonthRange}</p>
            <div className="graph-inner-container">
              <Row gutter={16}>
                <DashboardCards itemName={"Audit Count"} itemValue={dashboardDetails?.auditCount} />
                <DashboardCards
                  itemName={"Overall Audit Score"}
                  itemValue={`${dashboardDetails?.overallAuditScore}`}
                />
                <DashboardCards itemName={"Invoice Count"} itemValue={dashboardDetails?.invoiceCount} />
                <DashboardCards itemName={"Transaction Amount"} itemValue={dashboardDetails?.transactionAmount} />
                <DashboardCards itemName={"Fatal Count"} itemValue={dashboardDetails?.fatalCount} />
                <DashboardCards itemName={"Not-Fatal Count"} itemValue={dashboardDetails?.notFatalCount} />
              </Row>
              <Row gutter={16} className="mt-20">
                {/* Audit Count */}
                <Col span={(slsRole===process.env.REACT_APP_AROLE || slsRole===process.env.REACT_APP_QC)? 12:24}>
                  <div className="card px-3 py-4 auditCount-container">
                    <div className="auditCount-container-header">
                      <p className="dsh-chart-p m-0">Audit Count</p>
                      <div className="d-flex align-items-center gp-10">                        
                        <p className="dsh-chart-p m-0 vDetails">
                          <div
                            className={
                              showAuditCountDetails ? "triangle-up" : "triangle-down"
                            }
                          ></div>
                          <span
                            onClick={() => {
                              showAuditCountDetails === false
                                ? setShowAuditCountDetails(true)
                                : setShowAuditCountDetails(false);
                            }}
                          >
                            View Details
                          </span>
                        </p>
                      </div>
                    </div>
                      <ChartWithPDF type="bar" resHeight="400" data={auditCountChartdata} options={auditCountChartoptions} fileName="Client_Wise_Audit_Count" customClass={(slsRole===process.env.REACT_APP_AROLE || slsRole===process.env.REACT_APP_QC)?"":showAuditCountDetails?"cwac":""} />   
                      <div className="auditCountTable-outer-container">                                      
                        {showAuditCountDetails && (
                          <>
                          <button className="auditCountBarChart-dsh-download-excel border border-light-dark btn font-size-md font-weight-bold font-weight-bolder p-2 text-dark-custom" onClick={downloadExcel}>Download as Excel</button>                                              
                            <Table
                              className={(slsRole===process.env.REACT_APP_AROLE || slsRole===process.env.REACT_APP_QC)?"border mt-3 rounded dsh-table":"border mt-3 rounded dsh-table w-100"}
                              dataSource={dashboardDetails?.auditCountAsPerClientDetails}
                              columns={columns}
                              scroll={{ x: 1000 }}
                              pagination={false} // Pagination settings
                            />
                             
                          </>
                        )} 
                      </div>
                  </div>
                </Col>

                {/* Department Wise Audit Score */}
                {(slsRole===process.env.REACT_APP_AROLE || slsRole===process.env.REACT_APP_QC) &&
                  <Col span={12}>
                    <div className="card px-3 py-4">
                      <div className="d-flex justify-content-between gp-10">
                        <p className="dsh-chart-p m-0">Department Wise Audit Score</p>                                    
                      </div>
                      <ChartWithPDF type="pie" resHeight="400" data={departmentWiseAuditScorePieData} options={departmentWiseChartoptions} fileName="Department_Wise_Audit_Count" customClass="" />                     
                    </div>
                  </Col>
                }
              </Row>
              <Row gutter={16} className="mt-20">
                <Col span={24}>
                  <div className="card px-3 py-4">
                    <div className="d-flex justify-content-between gp-10">
                      <p className="dsh-chart-p m-0">Client Wise Audit Score</p>                                        
                    </div>
                    <ChartWithPDF type="bar" resHeight="700" data={clientWiseChartdata} options={clientWiseChartoptions} fileName="Client_Wise_Audit_Score" customClass="" />                        
                  </div>                  
                </Col>
              </Row>
            </div>
          </div>
        </Content>
      </Layout>
    </>
  );
};

export default Dashboard;
