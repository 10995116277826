import { Col } from "antd";
import React from "react";

const DashboardCards = ({ itemName, itemValue }) => {
  return (
    <Col span={4}>
      <div
        className={`card dsh-cards p-2 h-100 ${itemName
          .toLowerCase()
          .replaceAll(" ", "")}`}
      >
        <p className="mb-4 text-capitalize">{itemName}</p>
        <span>{itemValue}</span>
      </div>
    </Col>
  );
};

export default DashboardCards;
