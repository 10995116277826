import React, { useState, useEffect, useContext } from "react";
import {
  Form,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Select,
  Table,
  Typography,
} from "antd";
import { useLocation } from "react-router-dom";
import "./users.css";
import secureLocalStorage from "react-secure-storage";
import CustomLoader from "../CustomLoader";
import APICaller from "../../utils/APICaller";
import HandleSearch from "../../utils/CommonSearchComponent";
import BeforeTableHeader from "../layouts/beforeTableHeader";
import { GlobalStateContext } from "../../utils/GlobalStateContext";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  roles,
  clients,
  allReportingSupervisors,
  allAtls,
  statuses,
  ...restProps
}) => {
  let inputNode;
  if (dataIndex === "status") {
    inputNode = (
      <Select
        showSearch
        allowClear
        options={statuses}
        filterOption={(input, option) =>
          option.label.toLowerCase().includes(input.toLowerCase())
        }
      />
    );
  } else if (dataIndex === "role") {
    inputNode = (
      <Select
        showSearch
        allowClear
        options={roles}
        filterOption={(input, option) =>
          (option?.label ?? "")?.toLowerCase().includes(input?.toLowerCase())
        }
      />
    );
  } else if (dataIndex === "client") {
    inputNode = (
      <Select
        showSearch
        allowClear
        options={clients}
        filterOption={(input, option) =>
          (option?.label ?? "")?.toLowerCase().includes(input?.toLowerCase())
        }
      />
    );
  } else if (dataIndex === "reportingSupervisor") {
    inputNode = (
      <Select
        showSearch
        allowClear
        options={allReportingSupervisors}
        filterOption={(input, option) =>
          (option?.label ?? "")?.toLowerCase().includes(input?.toLowerCase())
        }
      />
    );
  } else if (dataIndex === "atl") {
    inputNode = (
      <Select
        showSearch
        allowClear
        options={allAtls}
        filterOption={(input, option) =>
          (option?.label ?? "")?.toLowerCase().includes(input?.toLowerCase())
        }
      />
    );
  } else {
    inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required:
                dataIndex !== "role" &&
                dataIndex !== "reportingSupervisor" &&
                dataIndex !== "atl" &&
                dataIndex !== "client",
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const Users = ({ children }) => {
  const location = useLocation();
  const slsRole = secureLocalStorage.getItem("role");
  const slsToken = secureLocalStorage.getItem("accessToken");
  const slsLIUId = secureLocalStorage.getItem("lIUId");

  //Search Filter
  const { Search } = Input;
  const [searchText, setSearchText] = useState("");
  const [selectionType, setSelectionType] = useState("checkbox");
  const [clients, setClients] = useState([]),
    [roles, setRoles] = useState([]),
    [allReportingSupervisors, setAllReportingSupervisors] = useState([]),
    [allAtls, setAllAtls] = useState([]);
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const [showLoader, setShowLoader] = useState(true);
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {},
  };
  const [statuses, setStatuses] = useState([
    { label: "ACTIVE", value: "ACTIVE" },
    { label: "INACTIVE", value: "INACTIVE" },
  ]);

  const [tableForm] = Form.useForm();
  const columns = [
    {
      title: () => {
        return <div className="tableTitle"> ID</div>;
      },
      dataIndex: "id",
      width: "5%",
      render: (text) => <span className="rowstyle">{text}</span>,
    },
    {
      title: () => {
        return <div className="tableTitle"> Emp ID</div>;
      },
      dataIndex: "empID",
      width: "5%",
      editable: true,
      render: (text, row) => <span className="rowstyle">{text}</span>,
    },
    ,
    {
      title: () => {
        return <div className="tableTitle"> Name</div>;
      },
      dataIndex: "name",
      width: "8%",
      editable: true,
      render: (text, row) => <span className="rowstyle">{text}</span>,
    },
    {
      title: () => {
        return <div className="tableTitle"> Email</div>;
      },
      dataIndex: "email",
      width: "8%",
      editable: true,
      render: (text) => <span className="rowstyle">{text}</span>,
    },
    {
      title: () => {
        return <div className="tableTitle"> Role</div>;
      },
      dataIndex: "role",
      width: "7%",
      editable: true,
      render: (text) => (
        <span className="rowstyle">
          {text && text.charAt(0).toUpperCase() + text.slice(1)}
        </span>
      ),
    },
    {
      title: () => {
        return <div className="tableTitle">Supervisor</div>;
      },
      dataIndex: "reportingSupervisor",
      width: "12%",
      editable: true,
      render: (text) => <span className="rowstyle">{text ? text : ""}</span>,
    },
    {
      title: () => {
        return <div className="tableTitle">ATL</div>;
      },
      dataIndex: "atl",
      width: "12%",
      editable: true,
      render: (text) => <span className="rowstyle">{text ? text : ""}</span>,
    },
    {
      title: () => {
        return <div className="tableTitle">Client</div>;
      },
      dataIndex: "client",
      width: "10%",
      editable: true,
      render: (text) => <span className="rowstyle">{text ? text : ""}</span>,
    },
    {
      title: () => {
        return <div className="tableTitle">Status</div>;
      },
      dataIndex: "status",
      width: "10%",
      editable: true,
      render: (text) => <span className="rowstyle">{text}</span>,
    },
    {
      title: () => {
        return <div className="tableTitle"> Created At</div>;
      },
      dataIndex: "createdAt",
      width: "10%",
      render: (text) => <span className="rowstyle">{text}</span>,
    },
    {
      title: () => {
        return <div className="tableTitle">Updated At</div>;
      },
      dataIndex: "updatedAt",
      width: "10%",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "10%",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        roles,
        clients,
        allReportingSupervisors,
        allAtls,
        statuses,
      }),
    };
  });

  /*begin::getSupervisors will fetch the list of ReportingSupervisors*/
  const getSupervisors = () => {
    const endpoint = "/fetchWithoutPagination";
    const method = "POST";
    const payload = {
      route: "users",
      action: "findAll",
      fields: ["manager", "tl", "atl"],
    };

    APICaller(slsRole, slsToken, endpoint, method, payload)
      .then((response) => {
        const storeAllTls = [];
        const getAllTls = response.filter(
          (user) => user?.role === "tl" || user?.role === "manager"
        );

        getAllTls?.map((user, index) => {
          storeAllTls.push({
            label: user?.name + " (EmpID: " + user?.empID + ")",
            value: user?._id,
          });
        });
        setAllReportingSupervisors(storeAllTls);

        const storeAllATls = [];
        const getAllATls = response.filter((user) => user?.role === "atl");

        getAllATls?.map((user, index) => {
          storeAllATls.push({
            label: user?.name + " (EmpID: " + user?.empID + ")",
            value: user?._id,
          });
        });
        setAllAtls(storeAllATls);
      })
      .catch((error) => {});
  };
  /*end::getSupervisors will fetch the list of Clients*/

  /*begin::getClients will fetch the list of Clients*/
  const getClients = () => {
    const endpoint = "/fetchWithoutPagination";
    const method = "POST";
    const payload = { route: "clients", action: "findAll" };
    APICaller(slsRole, slsToken, endpoint, method, payload)
      .then((response) => {
        const temp = [];
        response?.map((client, index) => {
          temp.push({ label: client?.name, value: client?._id });
        });
        setClients(temp);
      })
      .catch((error) => {});
  };
  /*end::getClients will fetch the list of Clients*/

  /*begin::getRoles will fetch the list of Roles*/
  const getRoles = async () => {
    const endpoint = "/roles/fetchWithoutPagination";
    const method = "GET";
    const payload = {};
    await APICaller(slsRole, slsToken, endpoint, method, payload)
      .then((response) => {
        const temp = [];
        response?.map((role, index) => {
          temp.push({ label: role?.name, value: role?.name?.toLowerCase() });
        });
        setRoles(temp);
      })
      .catch((error) => {});
  };
  /*end::getRoles will fetch the list of Roles*/

  /*begin::getUserList will fetch list of users*/
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 50 });
  const getUserList = async (source = "", pagination = "") => {
    let hasSearchedValue, searchKeyword;
    if (secureLocalStorage.getItem("hasSearchedValue")) {
      hasSearchedValue = "yes";
      searchKeyword = secureLocalStorage.getItem("searchKeyword");
    } else {
      hasSearchedValue = "";
      searchKeyword = "";
    }
    const searchIn = location.pathname.replace("/", "");
    const callActionFrom = source ? source : "";
    const globalStates = {
      globalState: globalState,
      setGlobalState: setGlobalState,
    };

    const allStates = {
      showLoader: showLoader,
      setShowLoader: setShowLoader,
      pagination: pagination
        ? pagination
        : { current: 1, page: 1, pageSize: 50 },
      setPagination: setPagination,
      list: list,
      setList: setList,
    };

    await HandleSearch(
      hasSearchedValue,
      searchIn,
      searchKeyword,
      callActionFrom,
      globalStates,
      allStates
    );
  };

  useEffect(() => {
    getUserList();
    getRoles();
    getClients();
    getSupervisors();
  }, []);

  /*end::getUserList will fetch list of users*/

  // Filter the storeList based on the searchText

  const searchFilterData =
    globalState.list &&
    globalState.list.filter((item) => {
      const role = item.role ? item.role.toLowerCase() : "";
      const empID = item.empID ? item.empID : "";
      const name = item.name ? item.name.toLowerCase() : "";
      const email = item.email ? item.email : "";
      const client = item.client ? item.client.toLowerCase() : "";
      const status = item.status ? item.status.toLowerCase() : "";

      return (
        role.includes(searchText.toLowerCase()) ||
        empID === Number(searchText) ||
        name.includes(searchText.toLowerCase()) ||
        email.includes(searchText) ||
        client.includes(searchText.toLowerCase()) ||
        status.includes(searchText.toLowerCase())
      );
    });
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    tableForm.setFieldsValue({
      name: "",
      email: "",
      role: null,
      client: null,
      reportingSupervisor: null,
      atl: null,
      createdAt: "",
      updatedAt: "",
      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey("");
  };
  const save = async (key) => {
    try {
      const row = await tableForm.validateFields();
      const newData = globalState?.list;
      //const newData = [...searchFilterData];

      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];

        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey("");

        const endpoint = "/users/" + key;
        const method = "PUT";
        const payload = {
          empID: newData[index]?.empID,
          name: newData[index]?.name,
          email: newData[index]?.email,
          historySource: "backend",
          historyAction: "update",
          historyUserID: slsLIUId,
        };

        // Only include roleID if it has been edited
        if (newData[index]?.role !== item?.role) {
          payload.role = newData[index]?.role;
        }
        if (newData[index]?.reportingSupervisor !== item?.reportingSupervisor) {
          payload.reportingSupervisor = newData[index]?.reportingSupervisor;
        }
        if (newData[index]?.atl !== item?.atl) {
          payload.atl = newData[index]?.atl;
        }
        if (newData[index]?.client !== item?.client) {
          payload.client = newData[index]?.client;
        }

        if (newData[index]?.reportingSupervisor !== item?.reportingSupervisor) {
          payload.reportingSupervisor = newData[index]?.reportingSupervisor;
        }

        if (newData[index]?.atl !== item?.atl) {
          payload.atl = newData[index]?.atl;
        }

        if (newData[index]?.status !== item?.status) {
          payload.status = newData[index]?.status;
        }

        APICaller(slsRole, slsToken, endpoint, method, payload)
          .then((response) => {
            message.success("User updated successfully.");
            tableForm.resetFields();
            getUserList();
          })
          .catch((error) => {});
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };
  return (
    <>
      <div className="all-content">
        <div className="d-flex">
          <BeforeTableHeader
            list={list && list.length}
            showListingCount="yes"
            href="/users/add"
          />
        </div>

        <div className="all-table-holder">
          <CustomLoader showLoader={showLoader} />

          <div className="searchCon">
            <Search
              className="table-search"
              placeholder="Search User's EmpID, Name, Email, Role, TL, ATL, Client & Status.."
              onSearch={(value) => setSearchText(value)}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <Form form={tableForm} component={false}>
            <Table
              rowSelection={{
                type: selectionType,
                ...rowSelection,
              }}
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              bordered
              columns={mergedColumns}
              dataSource={searchFilterData}
              rowClassName="editable-row"
              pagination={{
                onChange: cancel,
              }}
            />
          </Form>
        </div>
      </div>
    </>
  );
};
export default Users;
