/*Import files dynamically*/
import React, {
  useState,
  Suspense,
  useContext,
  useEffect,
  useRef,
} from "react";
import { useMsal } from "@azure/msal-react";
import "./login.css";
import secureLocalStorage from "react-secure-storage";

import { GlobalStateContext } from "../../utils/GlobalStateContext";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { storeAccessToken, storeRefreshToken } from "../../utils/tokenStorage";
import APICaller from "../../utils/APICaller";
import { message } from "antd";

const EmailVerification = React.lazy(() => import("../EmailVerification"));

const Login = () => {
  const navigate = useNavigate();
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const { instance, accounts } = useMsal();
  const hasCheckedServerStatus = useRef(false); // To track if the server status has been checked

  const [serverStatus, setServerStatus] = useState("Error");

  const navigateHome = (role) => {
    navigate("/dashboard");
  };

  const handleSignIn = async () => {
    secureLocalStorage.clear();
    try {
      const loginResponse = await instance.loginPopup({
        scopes: ["user.read", "offline_access"],
      });
      if (loginResponse) {
        postToken(loginResponse);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Declaring API for Getting User Reward
  const postToken = (loginResponse) => {
    const endpoint = "/validateToken";
    const method = "POST";
    const payload = {
      auth: loginResponse.accessToken,
    };
    APICaller("", loginResponse.accessToken, endpoint, method, payload)
      .then((response) => {
        if (response.statusCode === 200) {
          response.statusCode === 200 && validateEmail(loginResponse);
        } else {
          // Handle non-2xx status codes
          // You might want to log or handle the error accordingly
          console.error("Non-2xx status code:", response);
        }
      })
      .catch((error) => {});
  };

  const validateEmail = (loginResponse) => {
    const token = loginResponse.accessToken;
    const email = loginResponse?.account?.username;
    const getAccessTokenExpiresOn = loginResponse.expiresOn.getTime();
    const endpoint = "/emailVerification";
    const method = "POST";
    const payload = {
      email: email,
    };
    APICaller("", token, endpoint, method, payload)
      .then((response) => {
        // Handle successful login here
        setGlobalState((prevGlobalState) => ({
          ...prevGlobalState,
          isLoggedIn: true,
        }));
        console.log("response:", response);
        storeAccessToken(token, getAccessTokenExpiresOn);
        secureLocalStorage.setItem("lIUId", response?.respnse?._id);
        secureLocalStorage.setItem("role", response?.respnse?.role);
        secureLocalStorage.setItem(
          "reportingSupervisor",
          response?.respnse?.reportingSupervisor
        );
        response.statusCode === 200 && navigateHome(response?.respnse?.role);
      })
      .catch((error) => {
        showNotification(
          "The email you used does not exist in our system. Please logout and try logging in with a different account."
        );
        // Trigger the MSAL login popup again to allow the user to log in with a different email
        clearAndLogout();
      });
  };

  const checkServerStatus = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND + "/checkServerStatus"
      );

      setServerStatus(response.data.status);
    } catch (error) {
      navigate("/underMaintenance");
      setServerStatus("Error");
    }
  };

  // Function to show notification using Ant Design's notification component
  const showNotification = (errorMsg, moreThan = "no") => {
    message.error({
      content: (
        <div style={{ whiteSpace: "pre-line" }}>
          {moreThan === "yes" ? (
            <strong>Errors occurred:</strong>
          ) : (
            <strong>An error occurred:</strong>
          )}
          <p>{errorMsg}</p> {/* This ensures line breaks are respected */}
        </div>
      ),
      duration: 5, // duration in seconds
      className: "error-message", // Add any custom styles you need
    });
  };
  // Function to trigger the MSAL login popup again
  const clearAndLogout = async () => {
    try {
      // Clear the Redux state using the dispatch function.
      secureLocalStorage.clear();

      // Log the user out
      await instance.logoutPopup();

      const newLoginResponse = await instance.loginPopup({
        scopes: ["user.read", "offline_access"],
      });

      if (newLoginResponse) {
        postToken(newLoginResponse);
      }
    } catch (error) {
      console.error("Logout/Login failed:", error);
      // Optionally, handle login errors (e.g., show an error message to the user)
    }
  };

  useEffect(() => {
    if (!hasCheckedServerStatus.current) {
      checkServerStatus();
      hasCheckedServerStatus.current = true; // Mark as checked
    }
  }, []);

  return (
    <>
      {serverStatus !== "Error" && (
        <div className="login_box">
          <div className="login-wrap">
            <div className="login-page-logo">
              <img src="/logo.svg" style={{ width: "200px" }} alt="logo" />
              <span> &nbsp;</span>
              <span> &nbsp;</span>
              <span className="separator"></span>
              <span className="qcc_logo">&nbsp;QCC</span>
            </div>
            <span
              className="sign_in"
              style={{ textAlign: "center" }}
              onClick={() => {
                handleSignIn();
              }}
            >
              Sign in with Microsoft
            </span>
            {/* {globalState.isLoggedIn && (
              <Suspense fallback={<div>Loading...</div>}>
                <EmailVerification />
              </Suspense>
            )} */}
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
