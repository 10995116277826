import { Form, Radio, Select, DatePicker } from "antd";
import "./form.css";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";

/*Added selectedValue for fetching the saved value of select box of Audit Form*/
const FormElement = ({
  type = "radio-group",
  required = true,
  showAsterisk = true,
  isQualityMetric = false,
  f1 = () => {},
  questionId = "",
  questionDetail = "cddsc",
  options = ["QA", "TL", "ATL"],
  selectedValue = "",
  weightage = "",
  serial_no = "",
}) => {
  const location = useLocation();

  {
    /*Setting datepicker value*/
  }
  const [selectedDate, setSelectedDate] = useState(null); // State to store the selected date
  const handleDateChange = (momentDate, questionId, isQualityMetric) => {
    // Update the selectedDate state with the momentDate
    setSelectedDate(momentDate);
    f1(momentDate, questionId, isQualityMetric, weightage, serial_no);
  };

  let returnElement = <></>;
  if (type === "radio-group") {
    returnElement = (
      <Form.Item
        className="auditFormField"
        name={questionId}
        rules={[{ required: required, message: "" }]}
      >
        <>
          <div className="d-flex justify-content-between">
            <div className="first-inputs">
              <span className="text123">{questionDetail}</span>
              {required ? <span style={{ color: "red" }}>&nbsp;*</span> : <></>}
            </div>

            <div className="all-inputs">
              <span>
                {selectedValue ? (
                  <Radio.Group
                    onChange={(e) => {
                      f1(
                        e.target.value,
                        questionId,
                        isQualityMetric,
                        weightage,
                        serial_no
                      );
                    }}
                    value={selectedValue}
                  >
                    <Radio className="text123" value="Yes" tabIndex={0}>
                      {" "}
                      Yes{" "}
                    </Radio>
                    <Radio className="text123" value="No" tabIndex={0}>
                      {" "}
                      No{" "}
                    </Radio>
                    <Radio
                      className="text123"
                      value="Not Applicable"
                      tabIndex={0}
                    >
                      {" "}
                      Not Applicable{" "}
                    </Radio>
                  </Radio.Group>
                ) : (
                  <Radio.Group
                    onChange={(e) => {
                      f1(
                        e.target.value,
                        questionId,
                        isQualityMetric,
                        weightage,
                        serial_no
                      );
                    }}
                  >
                    <Radio className="text123" value="Yes">
                      {" "}
                      Yes{" "}
                    </Radio>
                    <Radio className="text123" value="No">
                      {" "}
                      No{" "}
                    </Radio>
                    <Radio className="text123" value="Not Applicable">
                      {" "}
                      Not Applicable{" "}
                    </Radio>
                  </Radio.Group>
                )}
              </span>
            </div>
          </div>
        </>
      </Form.Item>
    );
  }
  if (type === "comment") {
    returnElement = (
      <Form.Item
        className="auditFormField"
        name={questionId}
        rules={[{ required: required, message: "" }]}
      >
        <>
          <div className="d-flex justify-content-between">
            <div className="first-inputs">
              <span className="text123">{questionDetail}</span>
              {required && showAsterisk ? (
                <span style={{ color: "red" }}>&nbsp;*</span>
              ) : (
                <></>
              )}
            </div>

            <div className="all-inputs w-25 pr-1">
              {selectedValue ? (
                <TextArea
                  rows={2}
                  onChange={(e) => {
                    f1(e.target.value, questionId, isQualityMetric);
                  }}
                  className="text123"
                  value={selectedValue}
                ></TextArea>
              ) : (
                <TextArea
                  rows={2}
                  onChange={(e) => {
                    f1(e.target.value, questionId, isQualityMetric);
                  }}
                  className="text123"
                ></TextArea>
              )}
            </div>
          </div>
        </>
      </Form.Item>
    );
  }
  if (type === "dropdown") {
    returnElement = (
      <Form.Item
        className="auditFormField"
        name={questionId}
        rules={[{ required: required, message: "" }]}
      >
        <>
          <div className="d-flex justify-content-between">
            <div className="first-inputs">
              <span className="text123">{questionDetail}</span>
              {required && showAsterisk ? (
                <span style={{ color: "red" }}>&nbsp;*</span>
              ) : (
                <></>
              )}
            </div>

            <div className="all-inputs w-25 pr-1">
              <span>
                {selectedValue ? (
                  questionId === "correction-user" &&
                  location?.state?.formMethd === "update" ? (
                    <Select
                      onChange={(e) => {
                        f1(e, questionId, isQualityMetric);
                      }}
                      showSearch
                      allowClear
                      value={selectedValue}
                      label={questionId}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          ?.toLowerCase()
                          .includes(input?.toLowerCase())
                      }
                    >
                      {options.map((item) => {
                        return (
                          <Select.Option key={item} value={item}>
                            {item}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  ) : (
                    <Select
                      showSearch
                      allowClear
                      onChange={(e) => {
                        f1(e, questionId, isQualityMetric);
                      }}
                      value={selectedValue}
                      label={questionId}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          ?.toLowerCase()
                          .includes(input?.toLowerCase())
                      }
                    >
                      {options.map((item) => {
                        return (
                          <Select.Option key={item} value={item}>
                            {item}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  )
                ) : questionId === "correction-user" &&
                  location?.state?.formMethd === "create" ? (
                  <Select
                    showSearch
                    allowClear
                    onChange={(e) => {
                      f1(e, questionId, isQualityMetric);
                    }}
                    value="Not yet confirm"
                    label={questionId}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        ?.toLowerCase()
                        .includes(input?.toLowerCase())
                    }
                  >
                    {options.map((item) => {
                      return (
                        <Select.Option key={item} value={item}>
                          {item}
                        </Select.Option>
                      );
                    })}
                  </Select>
                ) : (
                  <Select
                    showSearch
                    allowClear
                    onChange={(e) => {
                      f1(e, questionId, isQualityMetric);
                    }}
                    label={questionId}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        ?.toLowerCase()
                        .includes(input?.toLowerCase())
                    }
                  >
                    {options.map((item) => {
                      return (
                        <Select.Option key={item} value={item}>
                          {item}
                        </Select.Option>
                      );
                    })}
                  </Select>
                )}
              </span>
            </div>
          </div>
        </>
      </Form.Item>
    );
  }
  if (type === "date") {
    returnElement = (
      <Form.Item
        className="auditFormField"
        name={questionId}
        rules={[
          { required: true, message: "Please select your Date of Birth!" },
        ]}
      >
        <div className="d-flex justify-content-between">
          <div className="first-inputs">
            <span className="dateText123">{questionDetail}</span>
            {required && showAsterisk ? (
              <span style={{ color: "red" }}>&nbsp;*</span>
            ) : (
              <></>
            )}
          </div>

          <div className="all-inputs w-25 pr-1">
            {selectedValue ? (
              <DatePicker
                onChange={(momentDate) => {
                  handleDateChange(momentDate, questionId, isQualityMetric);
                }}
                value={moment(selectedValue)}
                selected={selectedDate}
                label={questionId}
              />
            ) : (
              <DatePicker
                onChange={(momentDate) => {
                  handleDateChange(momentDate, questionId, isQualityMetric);
                }}
                value={selectedDate && moment(selectedDate)}
                selected={selectedDate}
                label={questionId}
              />
            )}
          </div>
        </div>
      </Form.Item>
    );
  }
  if (type === "number") {
    returnElement = (
      <Form.Item
        className="auditFormField"
        name={questionId}
        rules={[
          { required: true, message: "Please select your Date of Birth!" },
        ]}
      >
        <div className="d-flex justify-content-between">
          <div className="first-inputs">
            <span className="text123">{questionDetail}</span>
            {required && showAsterisk ? (
              <span style={{ color: "red" }}>&nbsp;*</span>
            ) : (
              <></>
            )}
          </div>
          <div className="all-inputs w-25 pr-1">
            {selectedValue ? (
              <input
                type="number"
                value={selectedValue} // Make sure to set the input value to the state variable
                onChange={(e) => {
                  f1(e.target.value, questionId, isQualityMetric);
                }}
                className="border py-2 text123 w-100"
              />
            ) : (
              <input
                type="number" // Make sure to set the input value to the state variable
                onChange={(e) => {
                  f1(e.target.value, questionId, isQualityMetric);
                }}
                className="border py-2 text123 w-100"
              />
            )}
          </div>
        </div>
      </Form.Item>
    );
  }
  return returnElement;
};
export default FormElement;
