//import get from "lodash.get";
const getNestedProperty = (obj, path, defaultValue = undefined) => {
  const keys = path ? path.split(".") : [];
  let result = obj;

  for (let key of keys) {
    if (result && typeof result === "object" && key in result) {
      result = result[key];
    } else {
      return defaultValue;
    }
  }

  return result;
};
export const exportJson = (
  selectedRows,
  listingOf,
  exportFor,
  columns,
  setGlobalState,
  globalState
) => {
  // Ensure listingOf has a response property
  if (!listingOf?.hasOwnProperty("response")) {
    console.log("Issue in fetching the data");
    return false;
  }

  // Extract ids from selectedRows
  const selectedRowKeysChildInfoArr = selectedRows.map((row) => row.id);

  /*const selectedRowKeysChildInfoArr = [];

  Object.keys(selectedRows).map((key) => {     //Object.keys(selectedRows) to iterate over the keys of selectedRows, the refactored version directly uses selectedRows.map().
    selectedRowKeysChildInfoArr.push(selectedRows?.[key]?.id);
  });*/

  // Retrieve all records from listingOf's response
  const allRecArr = listingOf.response;

  // Prepare reports array to store formatted data
  const reports = allRecArr
    .filter((list) => selectedRowKeysChildInfoArr.includes(list._id))
    .map((list) => {
      const report = {};
      // Process each column definition
      columns.forEach((col) => {
        let value = getNestedProperty(list, col.path);
        // let value = get(list, col.path); // Get value from list using lodash.get
        if (col.formatter) {
          value = col.formatter(value); // Apply formatter if present
        }
        report[col.key] = value || ""; // Assign value to report object
      });

      // Handle additional fields from formContent
      const formContent = list?.formContent && JSON.parse(list?.formContent);
      const allQuestions = formContent?.elements?.questions;

      let ky = 1;
      if (allQuestions) {
        Object.keys(allQuestions).forEach((key) => {
          const display_text = allQuestions[key]?.display_text;
          const selectedValue =
            exportFor === "FeedbackRequests"
              ? allQuestions[key]?.defaultValue
              : allQuestions[key]?.selectedValue;
          report[display_text] = selectedValue || "";

          // Check for sub_questions under the current question
          if (
            allQuestions[key]?.meta?.sub_questions &&
            allQuestions[key]?.meta.sub_questions.length > 0
          ) {
            allQuestions[key]?.meta.sub_questions.forEach((subQuestion) => {
              const sub_display_text = subQuestion?.display_text;
              const sub_selectedValue =
                exportFor === "FeedbackRequests"
                  ? subQuestion?.defaultValue
                  : subQuestion?.selectedValue;

              // Store sub-question in report
              report[`${ky} - ${sub_display_text}`] = sub_selectedValue || "";
            });
            ky = ky + 1;
          }
        });
      }

      return report;
    });

  // Update global state with reports and selected row keys
  setGlobalState({
    ...globalState,
    [`export${exportFor}Json`]: reports,
    selectedRowKeysChildInfo: selectedRowKeysChildInfoArr,
  });
  return true;
};
