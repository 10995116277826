import React, { useState, useContext } from "react";
import { Button, Form, Input, Select, message, Row, Col } from "antd";

import secureLocalStorage from "react-secure-storage";
import { useLocation, useNavigate } from "react-router-dom";
import APICaller from "../../utils/APICaller";
import { GlobalStateContext } from "../../utils/GlobalStateContext";
import HandleSearch from "../../utils/CommonSearchComponent";

const { Option } = Select;

const AddPermission = ({ children }) => {
  const location = useLocation();
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(true);
  const [errors, setErrors] = useState([]);
  const [form] = Form.useForm();
  const slsRole = secureLocalStorage.getItem("role");
  const slsToken = secureLocalStorage.getItem("accessToken");
  const slsLIUId = secureLocalStorage.getItem("lIUId");

  /*begin::call to api for creating new role*/
  const onFinish = (values) => {
    const endpoint = "/permissions";
    const method = "POST";
    const payload = {
      name: values?.name,
      historySource: "backend",
      historyAction: "create",
      historyUserID: slsLIUId,
    };

    APICaller(slsRole, slsToken, endpoint, method, payload)
      .then((response) => {
        setErrors([]);
        message.success("Permission created successfully");
        // Reset form fields
        form.resetFields();
        getPermissionsList();
      })
      .catch((error) => {
        if (error.data && error.data.message) {
          setErrors(error.data.message);
        } else {
          message.error("An error occurred while creating the permission");
        }
      });
  };

  /*begin::getPermissionsList will fetch list of users*/
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 50 });
  const getPermissionsList = async (source = "", pagination = "") => {
    let hasSearchedValue, searchKeyword;
    if (secureLocalStorage.getItem("hasSearchedValue")) {
      hasSearchedValue = "yes";
      searchKeyword = secureLocalStorage.getItem("searchKeyword");
    } else {
      hasSearchedValue = "";
      searchKeyword = "";
    }
    const searchIn = location.pathname.replace("/", "");
    const callActionFrom = source ? source : "";
    const globalStates = {
      globalState: globalState,
      setGlobalState: setGlobalState,
    };

    const allStates = {
      showLoader: showLoader,
      setShowLoader: setShowLoader,
      pagination: pagination
        ? pagination
        : { current: 1, page: 1, pageSize: 50 },
      setPagination: setPagination,
      list: list,
      setList: setList,
    };

    await HandleSearch(
      hasSearchedValue,
      searchIn,
      searchKeyword,
      callActionFrom,
      globalStates,
      allStates
    );
  };
  /*end::call to api for creating new permission*/

  return (
    <>
      <div className="all-content roleCont commonTCont">
        <div className="all-table-holder">
          <Form
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            initialValues={{ remember: true }}
            id="antdformstyle"
            form={form}
          >
            <Row gutter={[12, 12]}>
              <Col sm={12}>
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[
                    { required: true, message: "Please enter the name!" },
                  ]}
                >
                  <Input className="w-100 p-2 rounded-2" />
                </Form.Item>
              </Col>

              <div className="d-flex justify-content-center w-100">
                <Button
                  type="primary"
                  htmlType="reset"
                  className="submit-btn border-0 me-4 rounded"
                >
                  Clear
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit-btn border-0 me-0 rounded"
                >
                  Submit
                </Button>
              </div>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
};
export default AddPermission;
