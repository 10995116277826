import { message, Avatar } from "antd";
import { Divider } from "antd";
import React, { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import "./escalationView.css";
import { useLocation } from "react-router-dom";
import { GlobalStateContext } from "../../../utils/GlobalStateContext";
import secureLocalStorage from "react-secure-storage";
import APICaller from "../../../utils/APICaller";
import EscalationDetails from "./escalationDetails";
import moment from "moment";
import BeforeTableHeader from "../../layouts/beforeTableHeader";
import CustomLoader from "../../CustomLoader";

const EscalationView = ({ children = null, key_elem = "1", nameLink = "" }) => {
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const slsRole = secureLocalStorage.getItem("role");
  const slsToken = secureLocalStorage.getItem("accessToken");

  const location = useLocation();
  const prevData = location?.state;

  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const id = location.pathname.split("/")[2];
  //begin:: show Audit Form
  const getEscalationDetail = () => {
    if (window.performance) {
      const entries = performance.getEntriesByType("navigation");
      if (
        secureLocalStorage.getItem("clickedLink") !==
        "escalationDetailLinkClick"
      ) {
        if (entries.length > 0 && entries[0].type === "reload") {
          // Page is refreshed
          secureLocalStorage.setItem(
            "clickedLink",
            "escalationDetailLinkVisit"
          );
        }
      }
    }

    const action = "/escalations/";
    const endpoint = `${action}${id}`;
    const method = "GET";
    const payload = {};

    APICaller(slsRole, slsToken, endpoint, method, payload)
      .then((response) => {
        setGlobalState((prevGlobalState) => ({
          ...prevGlobalState,
          eDetails: response,
          eListingPrevData: prevData,
        }));
        setShowLoader(false);
      })
      .catch((error) => {
        error?.data?.message && message.error(error?.data?.message);
      });
  };

  useEffect(() => {
    getEscalationDetail();
  }, [location?.state?.route === "/escalations"]);

  const divStructure = (
    <>
      <img
        src={
          globalState?.eDetails?.errorImpact === "HIGH"
            ? "../high.png"
            : globalState?.eDetails?.errorImpact === "MEDIUM"
            ? "../medium.png"
            : "../low.png"
        }
        style={{ width: "17px" }}
        alt="logo"
      />{" "}
      Escalations: {globalState?.eDetails?.transactionId} From{" "}
      {globalState?.eDetails?.client?.name}
    </>
  );
  const singlePageContent = { divStructure };
  return (
    <div className="single-page">
      <CustomLoader showLoader={showLoader} />  
      
      <BeforeTableHeader
        singlePage="escalationView"
        singlePageContent={singlePageContent}
        list=""
        href=""
      />
      <div className="container1 escalationViewC">
        <div className="scrollbar" id="style-4">
          <span className="text11">
            <Avatar className="avatar">
              {globalState?.eDetails?.client?.name.charAt(0)?.toUpperCase()}
            </Avatar>
            &nbsp;{globalState?.eDetails?.client?.name} &nbsp;&nbsp;
            <span className="box_tag">CLIENT</span>{" "}
            <span style={{ color: "#9C9FA0" }}>
              {" "}
              {moment(globalState?.eDetails?.createdAt).format(
                "DD/MM/YYYY H:mm A"
              )}
            </span>
          </span>

          <Divider style={{ marginTop: "3px", zIndex: 1 }} />
          {globalState?.eDetails?.errorDescription && (
            <>
              <div
                className="text11 px-3"
                style={{
                  marginTop: "25px",
                  fontWeight: 600,
                  marginBottom: "10px",
                  fontSize: 20,
                }}
              >
                Error Description
              </div>
              <p className="headermodal px-3">
                {globalState?.eDetails?.errorDescription}
              </p>
            </>
          )}
          {globalState?.eDetails?.errorInvestigationRemarks && (
            <>
              <div
                className="text11 px-3"
                style={{
                  marginTop: "25px",
                  fontWeight: 600,
                  marginBottom: "10px",
                  fontSize: 20,
                }}
              >
                Error Investigation Remarks
              </div>
              <p className="headermodal px-3">
                {globalState?.eDetails?.errorInvestigationRemarks}
              </p>
            </>
          )}
        </div>
        <div className="audit-detailscontainer p-3">
          <EscalationDetails />
        </div>
      </div>
    </div>
  );
};
export default EscalationView;
