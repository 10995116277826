import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  message,
  Modal,
} from "antd";
import "./addAuditForms.css";
import secureLocalStorage from "react-secure-storage";
import { useLocation } from "react-router-dom";

import { GlobalStateContext } from "../../../utils/GlobalStateContext";
import APICaller from "../../../utils/APICaller";
import HandleSearch from "../../../utils/CommonSearchComponent";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

const AddAuditForm = ({ children }) => {
  const slsRole = secureLocalStorage.getItem("role");
  const slsToken = secureLocalStorage.getItem("accessToken");
  const slsLIUId = secureLocalStorage.getItem("lIUId");
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const [showLoader, setShowLoader] = useState(true);
  const location = useLocation();
  const [errors, setErrors] = useState([]),
    [processes, setProcesses] = useState([]);
  const [isWeightageForm, setIsWeightageForm] = useState(false);

  /*begin::Add new audit form*/
  const [form] = Form.useForm();

  /*begin::getAuditFormList will fetch list of audits*/
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 100 });

  const getAuditFormList = async (source = "", pagination = "") => {
    let hasSearchedValue, searchKeyword;
    if (secureLocalStorage.getItem("hasSearchedValue")) {
      hasSearchedValue = "yes";
      searchKeyword = secureLocalStorage.getItem("searchKeyword");
    } else {
      hasSearchedValue = "";
      searchKeyword = "";
    }
    const searchIn = location.pathname.replace("/", "");
    const callActionFrom = source ? source : "";
    const globalStates = {
      globalState: globalState,
      setGlobalState: setGlobalState,
    };

    const allStates = {
      showLoader: showLoader,
      setShowLoader: setShowLoader,
      pagination: pagination
        ? pagination
        : { current: 1, page: 1, pageSize: 100 },
      setPagination: setPagination,
      list: list,
      setList: setList,
    };

    await HandleSearch(
      hasSearchedValue,
      searchIn,
      searchKeyword,
      callActionFrom,
      globalStates,
      allStates
    );
  };
  /*end::Add new audit form*/

  /*begin::getProcesses will fetch the list of Processes*/
  const getProcesses = async () => {
    const endpoint = "/processes/fetchWithoutPagination";
    const method = "GET";
    const payload = {};

    await APICaller(slsRole, slsToken, endpoint, method, payload)
      .then((response) => {
        const temp = [];
        response?.map((process, index) => {
          temp.push({ label: process?.name, value: process?._id });
        });

        setProcesses(temp);
      })
      .catch((error) => {});
  };

  /*end::getProcesses will fetch the list of Processes*/

  const handleCheckboxChange = (e) => {
    setIsWeightageForm(e.target.checked); // Update state when checkbox is toggled
  };

  const [parameters, setParameters] = useState([]);
  const [error, setError] = useState("");

  const handleAddParameter = () => {
    const newParameter = {
      type: "radio-group",
      displayText: "",
      selectedValue: null,
      weightage: null,
      critical: false,
      mandatory: false,
    };

    setParameters([...parameters, newParameter]);
    setError(""); // Clear the error when adding a parameter

    // Update form values to include the new parameter
    const updatedValues = {
      ...form.getFieldsValue(),
      [`type_${parameters.length}`]: newParameter.type,
      [`displayText_${parameters.length}`]: newParameter.displayText,
      [`weightage_${parameters.length}`]: newParameter.weightage,
      [`critical_${parameters.length}`]: newParameter.critical || false,
      [`required_${parameters.length}`]: newParameter.required || false,
    };
    form.setFieldsValue(updatedValues);
  };

  const handleRemoveParameter = (index) => {
    const updatedParameters = parameters.filter((_, i) => i !== index);
    setParameters(updatedParameters);
    // Update form values to reflect the removal
    const updatedValues = {};
    updatedParameters.forEach((param, i) => {
      updatedValues[`type_${i}`] = param.type;
      updatedValues[`displayText_${i}`] = param.displayText;
      updatedValues[`weightage_${i}`] = param.weightage;
      updatedValues[`critical_${i}`] = param.critical || false;
      updatedValues[`required_${i}`] = param.required || false;
    });
    form.setFieldsValue(updatedValues);
  };

  const handleFieldChange = (value, index, field) => {
    const updatedParameters = [...parameters];
    updatedParameters[index][field] = value;
    setParameters(updatedParameters);
  };

  const { confirm } = Modal;
  const onFinish = (formContent) => {
    if (parameters.length === 0) {
      setError("Please add at least one parameter.");
      return;
    } // Show a confirmation modal
    confirm({
      title:
        "Please review your selected or entered information carefully before submitting the form, as changes may not be possible once it's submitted.",
      content: "Are you sure you want to submit the form?",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        setError("");
        const endpoint = "/auditForms";
        const method = "POST";
        const selectedProcess = processes.find(
          (process) => process.value === formContent.process
        );
        const payload = {
          slsRole,
          formName: selectedProcess && selectedProcess?.label,
          process: selectedProcess && selectedProcess?.value,
          activeAudits: 0,
          formContent,
          historySource: "backend",
          historyAction: "create",
          historyUserID: slsLIUId,
          createdAt: new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .slice(0, 19)
            .replace("T", " "),
        };

        APICaller(slsRole, slsToken, endpoint, method, payload)
          .then((response) => {
            setErrors([]);
            message.success("AuditForm created successfully");
            form.resetFields();
            setParameters([]);
            getAuditFormList();
          })
          .catch((error) => {
            const errorMessage =
              error.data?.message ||
              "An error occurred while creating the auditForm";
            setErrors([errorMessage]);
            message.error(errorMessage);
          });
      },
      onCancel() {
        // User canceled
        console.log("Form submission canceled");
      },
    });
  };

  useEffect(() => {
    getProcesses();
  }, []);

  useEffect(() => {
    // Setting type values
    const initialVals = {};
    parameters.forEach((param, index) => {
      initialVals[`type_${index}`] = "radio-group";
    });
    form.setFieldValue(initialVals);
  }, [parameters, form]);

  return (
    <>
      <div className="all-content roleCont commonTCont">
        <div className="all-table-holder">
          <Form
            form={form}
            layout="vertical"
            id="antdformstyle"
            onFinish={onFinish}
          >
            <Row gutter={[12, 12]}>
              <Col sm={12}>
                <Form.Item
                  name="isWeightageForm"
                  valuePropName="checked"
                  initialValue={false}
                >
                  <Checkbox
                    onChange={handleCheckboxChange}
                    className="weightTage"
                  >
                    Is this a Weightage Form?
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[12, 12]}>
              <Col sm={6}>
                <Form.Item
                  className="m-0"
                  label="Process"
                  name="process"
                  rules={[
                    { required: true, message: "Please select a process!" },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    options={processes}
                    className=""
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        ?.toLowerCase()
                        .includes(input?.toLowerCase())
                    }
                  />
                </Form.Item>
              </Col>

              <Col sm={24}>
                <h3 className="qualityParameters">Quality Parameters</h3>

                {parameters.map((param, index) => {
                  return (
                    <Row key={index} gutter={[12, 12]}>
                      {/* Hidden Form Item for type */}
                      <Form.Item
                        name={`type_${index}`}
                        initialValue={param.type}
                        hidden
                      >
                        <span></span>
                      </Form.Item>
                      <Col sm={3}>
                        <Form.Item
                          name={`displayText_${index}`}
                          label=""
                          rules={[
                            {
                              required: true,
                              message: "Please enter a title",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter a title"
                            value={param.displayText}
                            onChange={(e) =>
                              handleFieldChange(
                                e.target.value,
                                index,
                                "displayText"
                              )
                            }
                          />
                        </Form.Item>
                      </Col>

                      {isWeightageForm && (
                        <Col sm={3}>
                          <Form.Item
                            name={`weightage_${index}`}
                            label=""
                            rules={[
                              {
                                required: true,
                                message: "Please enter weightage",
                              },
                            ]}
                          >
                            <InputNumber
                              placeholder="Enter weightage"
                              value={param.weightage}
                              onChange={(value) =>
                                handleFieldChange(value, index, "weightage")
                              }
                            />
                          </Form.Item>
                        </Col>
                      )}

                      <Col sm={3}>
                        <Form.Item
                          name={`critical_${index}`}
                          valuePropName="checked"
                          initialValue={false}
                          label=""
                        >
                          <Checkbox
                            checked={param.critical}
                            onChange={(e) => {
                              const isChecked = e.target.checked; // Get true/false based on checkbox state
                              handleFieldChange(isChecked, index, "critical"); // Pass true/false to handleFieldChange
                            }}
                          >
                            Critical
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col sm={3}>
                        <Form.Item
                          name={`required_${index}`}
                          valuePropName="checked"
                          initialValue={false} // Ensure the checkbox starts as unchecked
                          label=""
                        >
                          <Checkbox
                            checked={param.required} // Bind the checkbox value to param.required
                            onChange={(e) => {
                              const isChecked = e.target.checked; // Get true/false based on checkbox state
                              handleFieldChange(isChecked, index, "required"); // Pass true/false to handleFieldChange
                            }}
                          >
                            Mandatory
                          </Checkbox>
                        </Form.Item>
                      </Col>

                      <Col sm={3}>
                        <Button
                          danger
                          onClick={() => handleRemoveParameter(index)}
                        >
                          Remove
                        </Button>
                      </Col>
                    </Row>
                  );
                })}

                <Button
                  type="dashed"
                  onClick={handleAddParameter}
                  icon={<PlusOutlined />}
                  className="mt-2"
                >
                  Add Parameter
                </Button>

                {error && (
                  <div style={{ color: "red", marginTop: "10px" }}>{error}</div>
                )}
              </Col>

              <div className="d-flex justify-content-center w-100">
                <Button
                  type="primary"
                  htmlType="reset"
                  className="submit-btn border-0 me-4 rounded"
                >
                  Clear
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit-btn border-0 me-0 rounded"
                >
                  Submit
                </Button>
              </div>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
};
export default AddAuditForm;
