import React, { useRef, useState, useLayoutEffect } from "react";
import { Bar, Pie } from "react-chartjs-2";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { ResponsiveContainer } from "recharts";
import { PDFDocument, rgb } from "pdf-lib";
import { saveAs } from "file-saver";
import moment from "moment";

const ChartWithPDF = ({ type, data, options, fileName, resHeight, tableRef, customClass }) => {
  const chartRef = useRef(null);
  const [isChartRendered, setIsChartRendered] = useState(false);

  useLayoutEffect(() => {
    if (chartRef.current) {
      setIsChartRendered(true);
    }
  }, [chartRef.current]);

  const downloadPdf1 = async () => {
    if (!isChartRendered) {
      console.error("Chart element is not yet rendered.");
      return;
    }

    if (chartRef.current) {
      try {
        const parentElement = chartRef.current.parentElement;
        const originalStyle = {
            width: parentElement.style.width,
            height: parentElement.style.height,
        };

        // Temporarily set the container to a larger size
        parentElement.style.width = "100%";
        parentElement.style.height = "auto";
        const canvas = await html2canvas(chartRef.current, { scale:2, useCORS: true });
        
        // Revert the parent container to its original size
        parentElement.style.width = originalStyle.width;
        parentElement.style.height = originalStyle.height;

        //Generate PDF
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("landscape", "mm", "a4");
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    
        // Capture the table
        if (tableRef.current) {
            const canvas = await html2canvas(tableRef.current, { scale: 2 });
            const imgData = canvas.toDataURL("image/png");
            const tableHeight = (canvas.height * pdfWidth) / canvas.width;
    
            // Add space if chart is present
            const yOffset = chartRef.current ? 20 + (chartRef.current.clientHeight * 210) / chartRef.current.clientWidth : 10;
    
            pdf.addImage(imgData, "PNG", 0, yOffset, pdfWidth, tableHeight);
        }
        pdf.save(`${fileName}.pdf`);
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    } else {
      console.error("Chart element not found!");
    }
  };
  const downloadPdf2 = async () => {
    const pdf = new jsPDF("portrait", "mm", "a4"); // Set page size to A4
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    let yOffset = 10; // Initial Y-offset for content positioning
  
    // Helper function to add canvas to PDF
    const addCanvasToPDF = async (element) => {
      if (element) {
        const canvas = await html2canvas(element, { scale: 3 }); // Increase scale for better resolution
        const imgData = canvas.toDataURL("image/png");
        const imgWidth = pageWidth;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
  
        if (yOffset + imgHeight > pageHeight) {
          pdf.addPage(); // Add a new page if the content exceeds the current page
          yOffset = 10; // Reset Y-offset for the new page
        }
  
        pdf.addImage(imgData, "PNG", 0, yOffset, imgWidth, imgHeight);
        yOffset += imgHeight + 10; // Update Y-offset for the next element
      }
    };
  
    try {
      // Add the chart(s) to the PDF
      if (chartRef.current) {
        await addCanvasToPDF(chartRef.current);
      }
  
      // Add the table to the PDF
      if (tableRef.current) {
        await addCanvasToPDF(tableRef.current);
      }
  
      pdf.save("Dashboard_Report.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };
  
  const downloadPdf = async () => {
    const pdf = new jsPDF("portrait", "mm", "a4");
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    let yOffset = 10;
  
    const captureElement = async (element, scale = 3) => {
      try {
        const canvas = await html2canvas(element, { scale });
        const imgData = canvas.toDataURL("image/png");
        const imgWidth = pageWidth - 20; // Adjust width with margin
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
  
        if (yOffset + imgHeight > pageHeight) {
          pdf.addPage();
          yOffset = 10;
        }
  
        pdf.addImage(imgData, "PNG", 10, yOffset, imgWidth, imgHeight);
        yOffset += imgHeight + 10;
      } catch (error) {
        console.error("Error capturing element:", error);
      }
    };
  
    try {
      // Capture and add charts
      if (chartRef.current) {
        await captureElement(chartRef.current);
      }
  
  
      pdf.save(`${fileName}.pdf`);
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };
  
 
  return (    
    <ResponsiveContainer height={resHeight} ref={chartRef} className={customClass} > 
      {type === "bar" && <Bar ref={chartRef} data={data} options={options} />}
      {type === "pie" && <Pie ref={chartRef} data={data} options={options} />}
      <button className={`${fileName==="Client_Wise_Audit_Count" ? 'auditCountBarChart':''} dsh-download-pdf border border-light-dark btn font-size-md font-weight-bold font-weight-bolder p-2 text-dark-custom`} onClick={downloadPdf} disabled={!isChartRendered}>Download as PDF</button>                    
    </ResponsiveContainer>    
  );
};

export default ChartWithPDF;
