import React, { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import secureLocalStorage from "react-secure-storage";
import { GlobalStateContext } from "../../utils/GlobalStateContext";
import APICaller from "../../utils/APICaller";
import HandleSearch from "../../utils/CommonSearchComponent";
import moment from "moment";

const Header = ({ nameLink }) => {
  /*begin::To fetch the global search results*/
  const location = useLocation();
  const slsRole = secureLocalStorage.getItem("role");
  const slsToken = secureLocalStorage.getItem("accessToken");
  const slsLIUId = secureLocalStorage.getItem("lIUId");
  const [list, setList] = useState([]),
    [searchData, setSearchData] = useState([]),
    [allTransactionIdsArr, setAllTransactionIdsArr] = useState([]),
    [selectedRowsArray, setSelectedRowsArray] = useState([]);
  const { instance } = useMsal();
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const navigate = useNavigate();
  const navigateSignIn = () => {
    navigate("/", { replace: true });
  };

  const handleSignOut = async () => {
    try {
      // Log the user out using the appropriate method.
      await instance.logoutPopup();
      setGlobalState(false);
      secureLocalStorage.clear();
      // Navigate the user to the sign-in page after logout.
      navigateSignIn();
    } catch (error) {}
  };

  const [pagination, setPagination] = useState({ current: 1, pageSize: 50 });
  const [listItems, setListItems] = useState([]);
  const [showLoader, setShowLoader] = useState(true);

  const handleOnChange = async (e) => {
    let hasSearchedValue = "";
    if (e.target.value) {
      hasSearchedValue = "yes";
      secureLocalStorage.setItem("hasSearchedValue", hasSearchedValue);
      secureLocalStorage.setItem("searchKeyword", e.target.value);
    }
    setShowLoader(true);
    const searchIn = location.pathname.replace("/", "");
    secureLocalStorage.setItem("lastPageSearchIn",searchIn);
    const searchKeyword = e.target.value ? e.target.value : "";
    const callActionFrom = "searchBar";
    const globalStates = {
      globalState: globalState,
      setGlobalState: setGlobalState,
    };
    const allStates = {
      searchData: searchData,
      setSearchData: setSearchData,
      selectedRowsArray: selectedRowsArray,
      setSelectedRowsArray: setSelectedRowsArray,
      allTransactionIdsArr: allTransactionIdsArr,
      setAllTransactionIdsArr: setAllTransactionIdsArr,
      listItems: listItems,
      setListItems: setListItems,
      showLoader: showLoader,
      setShowLoader: setShowLoader,
      pagination: pagination,
      setPagination: setPagination,
      list: list,
      setList: setList,
    };

    const response = await HandleSearch(
      hasSearchedValue,
      searchIn,
      searchKeyword,
      callActionFrom,
      globalStates,
      allStates
    );
    if (response) {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    if (searchData !== null && searchData.length > 0) {
      setList(searchData);
      setGlobalState({
        ...globalState,
        list: secureLocalStorage.getItem("searchData"),
      });
    }
  }, [searchData]);

  const handleHamburger = () => {
    // Get elements by class name
    let sideNavElements = document.getElementsByClassName("sidenav");
    let rightSideElements = document.getElementsByClassName("right-side");

    // Check if there are elements with the specified class
    if (sideNavElements.length > 0) {
      // Access the first element in the collection and toggle the class
      sideNavElements[0].classList.toggle("open");
    }

    if (rightSideElements.length > 0) {
      // Access the first element in the collection and toggle the class
      rightSideElements[0].classList.toggle("right-side-open");
    }
  };

  return (
    <div className="top-header">
      {/*TOP HEAder Start**/}
      {((location.pathname.split("/")[1] !== "auditForms" ||
        location.pathname.split("/")[1] !== "audits" ||
        location.pathname.split("/")[1] === "escalations") &&
        !location.pathname.split("/")[2]) ||
      ((location.pathname.split("/")[1] === "users" ||
        location.pathname.split("/")[1] === "roles" ||
        location.pathname.split("/")[1] === "forms" || location.pathname.split("/")[1] === "feedback-requests") &&
        location.pathname.split("/")[2]) ? (
        <>
          <div className="hamburger" onClick={handleHamburger}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </>
      ) : (
        <></>
      )}
      <div className="logo-area" onClick={()=>{navigate("/dashboard");}}>
        {globalState?.logo && (
          <img
            src={process.env.REACT_APP_BACKEND + `/images/${globalState?.logo}`}
            className="logo-img"
            alt="logo"            
          />
        )}
        <span className="qcc_logo">{globalState?.logoTitle}</span>
      </div>
      {((location.pathname.split("/")[1] !== "auditForms" ||
        location.pathname.split("/")[1] !== "audits" ||
        location.pathname.split("/")[1] !== "escalations") &&
        !location.pathname.split("/")[2] &&
        location.pathname.split("/")[1] !== "forms" &&
        location.pathname.split("/")[1] !== "feedback-requests") ||
      ((location.pathname.split("/")[1] === "users" ||
        location.pathname.split("/")[1] === "roles") &&
        location.pathname.split("/")[2] &&
        location.pathname.split("/")[1] !== "forms" &&
        location.pathname.split("/")[1] !== "feedback-requests") ? (
        <div className="align-items-center d-flex name_search">
          <div className="namelinkclass">{nameLink}</div>
          <div className="search-area ml-2">
            {location?.pathname?.split("/")[2] !== "add" &&
            location.pathname.replace("/", "") !== "settings" &&
            location.pathname.replace("/", "") !== "dashboard" ? (
              <>
                <div className="searchInput">
                  {location?.pathname?.split("/")[2] !== "add" ? (
                    <>
                      <img
                        src="/search.png"
                        style={{ width: "17px" }}
                        alt="logo"
                        className="searchSymb"
                      />{" "}
                    </>
                  ) : (
                    <></>
                  )}

                  <input
                    type="text"
                    className="inputS"
                    placeholder={
                      "Search " + location.pathname.replace("/", "") + "....."
                    }
                    onChange={handleOnChange}
                  />
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <>
          {location.pathname.split("/")[1] === "forms" ||
          (location.pathname.split("/")[1] === "feedback-requests" &&
            location.pathname.split("/")[2] !== "preview") ? (
            <span className="namelinkclass name_search">
              {location?.pathname.split("/")?.[1] &&
                location?.pathname.split("/")?.[1].charAt(0).toUpperCase() +
                  location?.pathname.split("/")?.[1].slice(1)}
            </span>
          ) : (
            <span className="namelinkclass name_search">
              {location?.pathname.split("/")?.[1] &&
                location?.pathname.split("/")?.[1].charAt(0).toUpperCase() +
                  location?.pathname.split("/")?.[1].slice(1)}
              /#{location.pathname.split("/")[2]}
            </span>
          )}
        </>
      )}

      <button className="main-sign-out" onClick={handleSignOut}>
        Sign Out
      </button>

      {/*TOP HEAder Start**/}
    </div>
  );
};

export default Header;
