import React, { useState, useContext, useEffect } from "react";
import { Button, Form, Input, Select, message, Row, Col } from "antd";
import secureLocalStorage from "react-secure-storage";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalStateContext } from "../../utils/GlobalStateContext";
import APICaller from "../../utils/APICaller";
import HandleSearch from "../../utils/CommonSearchComponent";

const { Option } = Select;

const AddSection = ({ children }) => {
  const location = useLocation();
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(true);
  const [errors, setErrors] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [form] = Form.useForm();
  const slsRole = secureLocalStorage.getItem("role");
  const slsToken = secureLocalStorage.getItem("accessToken");
  const slsLIUId = secureLocalStorage.getItem("lIUId");

  const onFinish = (values) => {
    const endpoint = "/sections";
    const method = "POST";
    const payload = {
      name: values?.name,
      icon: values?.icon,
      permissions: values.permissions,
      historySource: "backend",
      historyAction: "create",
      historyUserID: slsLIUId,
    };

    APICaller(slsRole, slsToken, endpoint, method, payload)
      .then((response) => {
        setErrors([]);
        message.success("Section created successfully");
        // Reset form fields
        form.resetFields();
        getSectionsList();
      })
      .catch((error) => {
        if (error.data && error.data.message) {
          setErrors(error.data.message);
        } else {
          message.error("An error occurred while creating the section");
        }
      });
  };

  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 50 });

  //Fetch Sections
  const getSectionsList = async (source = "", pagination = "") => {
    let hasSearchedValue, searchKeyword;
    if (secureLocalStorage.getItem("hasSearchedValue")) {
      hasSearchedValue = "yes";
      searchKeyword = secureLocalStorage.getItem("searchKeyword");
    } else {
      hasSearchedValue = "";
      searchKeyword = "";
    }
    const searchIn = location.pathname.replace("/", "");
    const callActionFrom = source ? source : "";
    const globalStates = {
      globalState: globalState,
      setGlobalState: setGlobalState,
    };

    const allStates = {
      showLoader: showLoader,
      setShowLoader: setShowLoader,
      pagination: pagination
        ? pagination
        : { current: 1, page: 1, pageSize: 50 },
      setPagination: setPagination,
      list: list,
      setList: setList,
    };

    await HandleSearch(
      hasSearchedValue,
      searchIn,
      searchKeyword,
      callActionFrom,
      globalStates,
      allStates
    );
  };

  // Fetch permissions
  const getPermissions = () => {
    const endpoint = "/fetchWithoutPagination";
    const method = "POST";
    const payload = { route: "permissions", action: "findAll" };

    APICaller(slsRole, slsToken, endpoint, method, payload)
      .then((response) => {
        const temp = [];
        response?.map((permission, index) => {
          temp.push({ label: permission?.name, value: permission?._id });
        });
        setPermissions(temp);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getPermissions();
  }, []);

  return (
    <>
      <div className="all-content roleCont commonTCont">
        <div className="all-table-holder">
          <Form
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            initialValues={{ remember: true }}
            id="antdformstyle"
            form={form}
          >
            <Row gutter={[12, 12]}>
              <Col sm={12}>
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[
                    { required: true, message: "Please enter the name!" },
                  ]}
                >
                  <Input className="w-100 p-2 rounded-2" />
                </Form.Item>
              </Col>
              <Col sm={12}>
                <Form.Item label="Icon" name="icon">
                  <Input className="w-100 p-2 rounded-2" />
                </Form.Item>
              </Col>
              <Col sm={12}>
                <Form.Item
                  className="m-0"
                  label="Permissions"
                  name="permissions"
                  rules={[
                    {
                      required: true,
                      message: "Please enter permission",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    size="large"
                    className="rounded-md h-45px rounded-md"
                    isclearable="true"
                    mode="multiple"
                    placeholder="Select Permission"
                    options={permissions}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        ?.toLowerCase()
                        .includes(input?.toLowerCase())
                    }
                  />
                </Form.Item>
              </Col>
              <div className="d-flex justify-content-center w-100">
                <Button
                  type="primary"
                  htmlType="reset"
                  className="submit-btn border-0 me-4 rounded"
                >
                  Clear
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit-btn border-0 me-0 rounded"
                >
                  Submit
                </Button>
              </div>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
};
export default AddSection;
