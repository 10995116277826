import React, { useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import APICaller from "../utils/APICaller";
import { useMsal } from "@azure/msal-react";
import { message } from "antd";

const EmailVerification = () => {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const token = secureLocalStorage.getItem("accessToken")
    ? secureLocalStorage.getItem("accessToken")
    : "";
  const currentAccount = instance.getActiveAccount();
  useEffect(() => {
    token && postToken();
  }, [token || currentAccount?.username]);

  const navigateHome = (role) => {
   navigate("/dashboard");
  };

  // Declaring API for Getting User Reward
  const postToken = () => {
    const endpoint = "/validateToken";
    const method = "POST";
    const payload = {
      auth: token,
    };
    APICaller("", token, endpoint, method, payload)
      .then((response) => {
        if (response.statusCode === 200) {
          response.statusCode === 200 && validateEmail();
        } else {
          // Handle non-2xx status codes
          // You might want to log or handle the error accordingly
          console.error("Non-2xx status code:", response);
        }
      })
      .catch((error) => {
        if (error.data && error.data.message) {
          message.error(error.data.message);
        }
      });
  };

  const validateEmail = () => {
    const endpoint = "/emailVerification";
    const method = "POST";
    const payload = {
      email: currentAccount?.username,
    };
    APICaller("", token, endpoint, method, payload)
      .then((response) => {
        secureLocalStorage.setItem("lIUId", response?.respnse?._id);
        secureLocalStorage.setItem("role", response?.respnse?.role);
        secureLocalStorage.setItem(
          "reportingSupervisor",
          response?.respnse?.reportingSupervisor
        );
        response.statusCode === 200 && navigateHome(response?.respnse?.role);
      })
      .catch((error) => {
        showNotification(
          "The email you used does not exist in our system. Please logout and try logging in with a different account."
        );
        // Trigger the MSAL login popup again to allow the user to log in with a different email
        clearAndLogout();
      });
  };
  // Function to show notification using Ant Design's notification component
  const showNotification = (errorMsg, moreThan = "no") => {
    message.error({
      content: (
        <div style={{ whiteSpace: "pre-line" }}>
          {moreThan === "yes" ? (
            <strong>Errors occurred:</strong>
          ) : (
            <strong>An error occurred:</strong>
          )}
          <p>{errorMsg}</p> {/* This ensures line breaks are respected */}
        </div>
      ),
      duration: 5, // duration in seconds
      className: "error-message", // Add any custom styles you need
    });
  };
  // Function to trigger the MSAL login popup again
  const clearAndLogout = async () => {
    try {
      // Clear the Redux state using the dispatch function.
      secureLocalStorage.clear();

      // Log the user out
      await instance.logoutPopup();

      const newLoginResponse = await instance.loginPopup({
        scopes: ["user.read", "offline_access"],
      });

      if (newLoginResponse) {
        postToken(newLoginResponse);
      }
    } catch (error) {
      console.error("Logout/Login failed:", error);
      // Optionally, handle login errors (e.g., show an error message to the user)
    }
  };
  return <></>;
};

export default EmailVerification;
