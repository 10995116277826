import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AddUser from "../../users/addUser";
import AddRole from "../../roles/addRole";

import AddClient from "../../qms/clients/addClient";
import AddDepartment from "../../qms/departments/addDepartment";
import AddProcess from "../../qms/processes/addProcess";
import AddAuditForm from "../../qms/auditForms/addAuditForms";
import { GlobalStateContext } from "../../../utils/GlobalStateContext";
import SendVForm from "../../voc/forms/sendForm";
import secureLocalStorage from "react-secure-storage";
import AddPermission from "../../permissions/addPermission";
import AddSection from "../../sections/addSection";

export default function BeforeTableHeader({
  list,
  singlePage,
  singlePageContent,
  showListingCount,
  href,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(true);

  const handleToggle = () => {
    setCollapsed(!collapsed);
  };

  const notHrefList = ["/forms/add"];
  const { globalState, setGlobalState } = useContext(GlobalStateContext);

  const replaceWith = (count, str) => {
    return count <= 1
      ? str === "processes"
        ? "process"
        : str === "auditForms"
        ? "audit form"
        : str.replace(new RegExp(`${"s"}+$`), "")
      : str === "auditForms"
      ? "audit forms"
      : str;
  };

  const divStructure = singlePageContent
    ? singlePageContent?.divStructure
    : null;

  const headLine = singlePageContent ? singlePageContent.headLine : null;

  const handleBack = (fromComponent, prevComponent) => {
    const allKeys = Object.keys(localStorage);
    let removeItems;
    switch (fromComponent) {
      case "auditForms": {
        removeItems = [
          "@secure.n.totalAuditedCount",
          "@secure.j.parameterSelectedList",
          "@secure.s.auditScore",
          "@secure.b.auditFormSubmit",
        ];
        break;
      }
      case "delete-audits":
      case "audits": {
        removeItems = [
          "@secure.n.totalAuditedCount",
          "@secure.s.auditScore",
          "@secure.j.parameterSelectedList",
          "@secure.j.auditFormInput",
          "@secure.s.auditFormCall",
          "@secure.n.auditedQuestionScoreCount",
          "@secure.n.auditedQuestionCount",
          "@secure.j.countMap",
          "@secure.s.clickedLink",
          "@secure.j.timeStampsDetails",
          "@secure.n.weightageFlag",
          "@secure.j.updatedQMF",
          "@secure.s.savedAuditId",
          "@secure.b.auditedFormSubmit",
          "@secure.b.auditFormSubmit",
          "@secure.s.aID",
          "@secure.j.savePayloadRequestParams",
        ];
        break;
      }
      case "escalations": {
        removeItems = ["@secure.s.clickedLink"];
        break;
      }
      default: {
        break;
      }
    }
    allKeys &&
      allKeys.forEach((key) => {
        if (removeItems && removeItems.includes(key)) {
          localStorage.removeItem(key);
        }
      });
  };

  return (
    <div
      className={
        singlePage
          ? singlePage === "escalationView" || singlePage === "auditView"
            ? "table-count-area d-flex justify-content-between justify-content-center mx-2"
            : "table-count-area"
          : "table-count-area"
      }
    >
      {singlePage && (
        <>
          <span className="text11">
            {" "}
            <span
              className="back-btn"
              onClick={() => {
                navigate(-1);
                handleBack(
                  secureLocalStorage.getItem("fromComponent"),
                  location?.pathname.split("/")?.[1]
                );
              }}
            >
              &#8592;
            </span>{" "}
            {headLine && headLine}
            {singlePage === "escalationView" && divStructure && divStructure}
          </span>
          {singlePage === "auditView" && divStructure && divStructure}
        </>
      )}
      {showListingCount === "yes" && (
        <div className="count-inner">
          <span>
            {location?.pathname.split("/")?.[1] === globalState?.recordCountFor
              ? globalState?.recordCount +
                " " +
                replaceWith(
                  globalState?.recordCount,
                  globalState.recordCountFor
                ) +
                " found"
              : ""}
          </span>
        </div>
      )}
      {href && (
        <>
          <div className="d-flex justify-content-end">
            <span
              type="primary"
              className={href === "/users/add" ? "addNewbtn ms-2" : "addNewbtn"}
              onClick={handleToggle}
            >
              {notHrefList.includes(href) ? "Send Forms" : "Add New"}
            </span>
          </div>

          {!collapsed && (
            <>
              {href === "/auditForms/add" && <AddAuditForm />}
              {href === "/processes/add" && <AddProcess />}
              {href === "/clients/add" && <AddClient />}
              {href === "/departments/add" && <AddDepartment />}
              {href === "/forms/add" && <SendVForm />}
              {href === "/permissions/add" && <AddPermission />}
              {href === "/sections/add" && <AddSection />}
              {href === "/roles/add" && <AddRole />}
              {href === "/users/add" && <AddUser />}
            </>
          )}
        </>
      )}
    </div>
  );
}
