import React, { useContext } from "react";
import { Divider } from "antd";
import moment from "moment";
import { GlobalStateContext } from "../../../utils/GlobalStateContext";

const EscalationDetails = () => {
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const escalationDetailsInfo = globalState?.eDetails && globalState?.eDetails;

  const escalationDetails =
    escalationDetailsInfo &&
    Object.keys(escalationDetailsInfo)?.map((key) => ({
      key,
      value: escalationDetailsInfo[key],
    }));

  escalationDetails && escalationDetails.forEach((item) => {});

  const keysToSkip = [
    "_id",
    "createdBy",
    "createdAt",
    "updatedBy",
    "updatedAt",
    "__v",
    "errorDescription",
    "errorInvestigationRemarks",
  ];

  return (
    <>
      <span className="text11 escalation-details-left">Escalation Details</span>
      {escalationDetails &&
        escalationDetails?.map((item) => {
          // Specify custom text for specific keys
          const customTextForKey = {
            clientSpocEmail: "Client SPOC Email",
            reportingTl: "Reporting TL",
            errorRaiseDate: "Error Raise Date",
            transactionId: "Transaction ID",
            transactionDuplicateReason: "Transaction Duplicate Reason",
            transactionDate: "Transaction Date",
            errorImpact: "Error Impact",
            createdAt: "Created At",
          };

          // Check if the current key should be skipped
          if (keysToSkip.includes(item.key)) {
            return null; // Skip this iteration
          }

          return (
            <>
              {item.key !== "createdAt" && (
                <div
                  className="align-items-center d-flex justify-content-between mt-4"
                  key={item.key}
                >
                  <span className="text11 escalation-details-left color-grey">
                    {customTextForKey[item.key] ||
                      (item.key &&
                        item.key.charAt(0)?.toUpperCase() + item.key.slice(1))}
                  </span>
                  <span className="text11 escalation-details-left">
                    {item.key === "stage" && (
                      <img
                        src={
                          item.value === "Completed"
                            ? "../audited.PNG"
                            : item.value === "Pending"
                            ? "../pending.png"
                            : item.value === "Accepted"
                            ? "../completed.png"
                            : "../disputed.png"
                        }
                        style={{ width: "17px" }}
                        alt="logo"
                      />
                    )}{" "}
                    {item.key === "errorImpact" && (
                      <img
                        src={
                          item?.errorImpact === "HIGH"
                            ? "../high.png"
                            : item?.errorImpact === "MEDIUM"
                            ? "../medium.png"
                            : "../low.png"
                        }
                        style={{ width: "17px" }}
                        alt="logo"
                      />
                    )}{" "}
                    {(item.key === "reportingTl" ||
                      item.key === "agent" ||
                      item.key === "auditor") &&
                      item?.value?.name && (
                        <img
                          className="auditProfileImage"
                          src={
                            item?.value?.profilePicUrl
                              ? process.env.REACT_APP_BACKEND +
                                "/images/" +
                                item?.value?.profilePicUrl
                              : process.env.REACT_APP_BACKEND +
                                "/images/dummy.jpg"
                          }
                          alt={item?.value?.name}
                        />
                      )}{" "}
                    {item.key === "transactionDate" ||
                    item.key === "createdAt" ||
                    item.key === "errorRaiseDate" ? (
                      moment(item?.value).format("DD/MM/YYYY H:mm A")
                    ) : (
                      <>
                        {customTextForKey[item.key]?.name
                          ? customTextForKey[item.key]?.name
                          : customTextForKey[item.key]?.value ||
                            item?.value?.name
                          ? item?.value?.name
                          : item?.value}
                      </>
                    )}
                  </span>
                </div>
              )}
            </>
          );
        })}
      <Divider style={{ marginTop: "3px", zIndex: 1 }} />
      <span className="text11 escalation-details-left">Time Stamps</span>
      {escalationDetails &&
        escalationDetails.map((item) => {
          // Specify custom text for specific keys
          const customTextForKey = {
            createdAt: "Created At",
          };

          // Check if the current key should be skipped
          if (!keysToSkip.includes(item.key)) {
            return null; // Skip this iteration
          }

          return (
            <>
              {item.key === "createdAt" && (
                <div
                  className="align-items-center d-flex justify-content-between mt-4"
                  key={item?.key}
                >
                  <span className="text11 escalation-details-left color-grey">
                    {customTextForKey[item.key] ||
                      (item.key &&
                        item.key.charAt(0)?.toUpperCase() + item.key.slice(1))}
                  </span>
                  <span className="text11 escalation-details-left">
                    {item.key === "createdAt"
                      ? moment(item?.value).format("DD/MM/YYYY H:mm A")
                      : ""}
                  </span>
                </div>
              )}
            </>
          );
        })}
    </>
  );
};
export default EscalationDetails;
