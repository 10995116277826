import {
  Button,
  Modal,
  Select,
  Input,
  DatePicker,
  Checkbox,
  Form,
  message,
} from "antd";
import React, { useState, useEffect } from "react";
import APICaller from "../../../utils/APICaller";
import { Table } from "antd";
import "./audits.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { GlobalStateContext } from "../../../utils/GlobalStateContext";
import { useContext } from "react";
import moment from "moment";
import DuplicateTransactionReasonForm from "./duplicateTransactionReasonForm";
import { JsonToExcel } from "react-json-to-excel";
import BeforeTableHeader from "../../layouts/beforeTableHeader";
import "react-datetime/css/react-datetime.css";
import AuditBackDateReasonForm from "./auditBackDateReasonForm";
import HandleSearch from "../../../utils/CommonSearchComponent";
import { exportJson } from "../../../utils/export";

import CustomLoader from "../../CustomLoader";
import DownloadAllModal from "./downloadAllModal";

const Audits = ({ children }) => {
  const slsRole = secureLocalStorage.getItem("role");
  const slsToken = secureLocalStorage.getItem("accessToken");
  const slsLIUId = secureLocalStorage.getItem("lIUId");
  const slsReportingSupervisor = secureLocalStorage.getItem(
    "reportingSupervisor"
  );
  const location = useLocation();
  const navigate = useNavigate();
  const { globalState, setGlobalState } = useContext(GlobalStateContext);

  {
    /*Declaring states*/
  }

  const [selectionType, setSelectionType] = useState("checkbox");
  const [searchData, setSearchData] = useState([]),
    [selectedRowsArray, setSelectedRowsArray] = useState([]),
    [clients, setClients] = useState([]),
    [departments, setDepartments] = useState([]),
    [tasks, setTasks] = useState([]),
    [forms, setForms] = useState([]),
    [agents, setAgents] = useState([]);
  const [clientId, setClientId] = useState(),
    [formId, setFormId] = useState(),
    [agentId, setAgentId] = useState(),
    [departmentId, setDepartmentId] = useState(),
    [processId, setProcessId] = useState(),
    [transactionId, setTransactionId] = useState(),
    [transactionDate, setTransactionDate] = useState(),
    [auditDateTime, setAuditDateTime] = useState(),
    [transactionAmount, setTransactionAmount] = useState(),
    [invoiceCount, setInvoiceCount] = useState(),
    [insuredName, setInsuredName] = useState();
  /*setagentCVal, setProcessDepCVal, setAformVal states are for reset respected values for Process as well as Form*/
  const [agentCVal, setAgentCVal] = useState(),
    [processDepCVal, setProcessDepCVal] = useState(),
    [aformVal, setAformVal] = useState();
  /*setAuditFormInput state is used to show Perform Audit selected fields on Audit Form*/
  const [auditFormInput, setAuditFormInput] = useState({
    Stage: "Audit Pending",
    Auditor: secureLocalStorage.getItem("lIU"),
    "Audit Score": "0.00%",
  });

  const [isActiveLoad, setIsActiveLoad] = useState("isActionLoad");
  const [list, setList] = useState([]);
  const [listItems, setListItems] = useState([]);
  const [auditFormErrors, setAuditFormErrors] = useState({});
  const [showLoader, setShowLoader] = useState(true);
  const [allTransactionIdsArr, setAllTransactionIdsArr] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false),
    [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false),
    [auditBackDateReasonVisible, setAuditBackDateReasonVisible] =
      useState(false),
    [visible, setVisible] = useState(false),
    [isModalVisible, setIsModalVisible] = useState(false),
    [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false),
    [DownloadAllModalVisible, setDownloadAllModalVisible] = useState(false);
  const [transactionDuplicateReason, setTransactionDuplicateReason] =
      useState(""),
    [auditBackDateReason, setAuditBackDateReason] = useState("");

  {
    /*Fetch the list of Audits*/
  }
  const [pagination, setPagination] = useState({ current: 1, pageSize: 100 });
  const getAudits = async (source = "", pagination = "") => {
    const allKeys = Object.keys(localStorage);
    let removeItems;
    switch (secureLocalStorage.getItem("fromComponent")) {
      case "audits": {
        removeItems = [
          "@secure.n.totalAuditedCount",
          "@secure.s.auditScore",
          "@secure.j.parameterSelectedList",
          "@secure.j.auditFormInput",
          "@secure.s.auditFormCall",
          "@secure.n.auditedQuestionScoreCount",
          "@secure.n.auditedQuestionCount",
          "@secure.j.countMap",
          "@secure.s.clickedLink",
          "@secure.j.timeStampsDetails",
          "@secure.n.weightageFlag",
          "@secure.j.updatedQMF",
          "@secure.s.savedAuditId",
          "@secure.b.auditedFormSubmit",
          "@secure.s.aID",
          "@secure.j.savePayloadRequestParams",
        ];
        break;
      }
      default: {
        break;
      }
    }
    allKeys &&
      allKeys.forEach((key) => {
        if (removeItems && removeItems.includes(key)) {
          localStorage.removeItem(key);
        }
      });

    let hasSearchedValue, searchKeyword;
    if (secureLocalStorage.getItem("hasSearchedValue")) {
      hasSearchedValue = "yes";
      searchKeyword = secureLocalStorage.getItem("searchKeyword");
    } else {
      hasSearchedValue = "";
      searchKeyword = "";
    }
    const searchIn = location.pathname.replace("/", "");
    const callActionFrom = source ? source : "";
    const globalStates = {
      globalState: globalState,
      setGlobalState: setGlobalState,
    };
    const { current, pageSize } = pagination;

    setSelectedRowKeys([]);
    const allStates = {
      searchData: searchData,
      setSearchData: setSearchData,
      selectedRowsArray: selectedRowsArray,
      setSelectedRowsArray: setSelectedRowsArray,
      showLoader: showLoader,
      setShowLoader: setShowLoader,
      pagination: pagination
        ? pagination
        : { current: 1, page: 1, pageSize: 100 },
      setPagination: setPagination,
      list: list,
      setList: setList,

      listItems,
      setListItems,
      allTransactionIdsArr: allTransactionIdsArr,
      setAllTransactionIdsArr: setAllTransactionIdsArr,
    };

    await HandleSearch(
      hasSearchedValue,
      searchIn,
      searchKeyword,
      callActionFrom,
      globalStates,
      allStates
    );
  };

  {
    /*Defining functions*/
  }
  {
    /*PerformAudit Popup Form*/
  }
  const showModal = () => {
    const allKeys = Object.keys(localStorage);
    // Loop through the keys and remove items that are not the specific item
    let exceptItems = [
      "@secure.s.lIU",
      "@secure.s.lIUId",
      "@secure.s.role",
      "@secure.s.accessToken",
      "@secure.s.accessTokenExpiry",
      "@secure.s.refreshToken",
      "@secure.s.refreshTokenExpiry",
    ];
    let removeItems = [
      "@secure.s.isSubMenuActive",
      "@secure.j.auditFormInput",
      "@secure.s.auditFormCall",
      "@secure.n.totalAuditedCount",
      "@secure.j.parameterSelectedList",
      "@secure.n.auditedQuestionScoreCount",
      "@secure.n.auditedQuestionCount",
      "@secure.j.countMap",
      "@secure.s.clickedLink",
      "@secure.s.auditScore",
      "@secure.j.timeStampsDetails",
      "@secure.n.weightageFlag",
      "@secure.j.updatedQMF",
      "@secure.s.savedAuditId",
      "@secure.j.savePayloadRequestParams",
    ];
    allKeys.forEach((key) => {
      /* if (!exceptItems.includes(key)) {
        localStorage.removeItem(key);
      } */
      if (removeItems.includes(key)) {
        localStorage.removeItem(key);
      }
    });
    setIsModalOpen(true);
  };

  {
    /*Setting state for showing Duplication Transaction ID popup as well as it value on Audits Detail Section*/
  }
  const onCreate = (values) => {
    values?.transactionDuplicateReason &&
      setTransactionDuplicateReason(values?.transactionDuplicateReason);
    setAuditFormInput({
      ...auditFormInput,
      "Reason for duplicate Transaction ID":
        values?.transactionDuplicateReason &&
        values?.transactionDuplicateReason,
    });
    setVisible(false);
  };

  {
    /*Save Audit Back Date reason*/
  }
  const onCreateBackDateReason = (values) => {
    values?.auditBackDateReason &&
      setAuditBackDateReason(values?.auditBackDateReason);
    setAuditFormInput({
      ...auditFormInput,
      "Reason for Audit's back date":
        values?.auditBackDateReason && values?.auditBackDateReason,
    });
    setAuditBackDateReasonVisible(false);
  };

  {
    /*Audit Complete and Delete action*/
  }
  const handleAuditAction = (params) => {
    switch (params) {
      case "complete":
        const endpoint = "/audits/updateMul";
        const method = "POST";
        const payload = {
          role: slsRole,
          ids: globalState.selectedRowKeysChildInfo,
          markedAsCompleteBy: slsLIUId,
        };

        APICaller(slsRole, slsToken, endpoint, method, payload)
          .then((response) => {
            getAudits();
            setSelectedRowKeys([]);
          })
          .catch((error) => {});
        break;
      case "delete":
        let submitDeleteAuditFormModal = "";

        if (isDeleteModalVisible === true) {
          if (
            secureLocalStorage.getItem("role") === "admin" &&
            secureLocalStorage.getItem("reportingSupervisor") === null
          ) {
            submitDeleteAuditFormModal = "clickedDeleteAuditOK";
          }
          if (
            secureLocalStorage.getItem("role") === "qc" &&
            secureLocalStorage.getItem("reportingSupervisor") === null
          ) {
            submitDeleteAuditFormModal = "clickedDeleteAuditWithReasonOK";
          }
        }

        if (submitDeleteAuditFormModal === "clickedDeleteAuditOK") {
          setShowLoader(true);
          const endpoint = "/audits/deleteMul";
          const method = "POST";
          const payload = {
            role: slsRole,
            ids: globalState.selectedRowKeysChildInfo,
          };

          APICaller(slsRole, slsToken, endpoint, method, payload)
            .then((response) => {
              setIsDeleteModalVisible(false);
              getAudits();
              setSelectedRowKeys([]);
            })
            .catch((error) => {});
        } else if (
          submitDeleteAuditFormModal === "clickedDeleteAuditWithReasonOK"
        ) {
          form
            .validateFields()
            .then((values) => {
              setShowLoader(true);
              const endpoint = "/audits/softDeleteMul";
              const method = "POST";
              const payload = {
                role: slsRole,
                reportingSupervisor: slsReportingSupervisor,
                ids: globalState.selectedRowKeysChildInfo,
                auditDeleteReason: values?.auditDeleteReason,
              };

              APICaller(slsRole, slsToken, endpoint, method, payload)
                .then((response) => {
                  setIsDeleteModalVisible(false);
                  getAudits();
                  setSelectedRowKeys([]);
                })
                .catch((error) => {});
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
              setIsDeleteModalVisible(true);
            });
        } else {
          setIsDeleteModalVisible(true);
          form.resetFields();
        }
        break;
    }
  };

  {
    /*PerformAudit - Submit click*/
  }
  const handleOk = () => {
    let submitFormModal = "";
    if (isModalVisible === true) {
      submitFormModal = "clickedOK";
    }
    secureLocalStorage.removeItem("updatedQMF");
    secureLocalStorage.removeItem("auditedQuestionCount");
    secureLocalStorage.removeItem("auditScore");
    secureLocalStorage.removeItem("auditFormCall");
    secureLocalStorage.removeItem("savedAuditId");

    secureLocalStorage.removeItem("auditedFormSubmit");
    secureLocalStorage.removeItem("savePayloadRequestParams");
    secureLocalStorage.setItem("auditFormCall", "performAudit");
    if (backAuditDateChecked && auditBackDateReason === "") {
      setIsModalOpen(true);
      setAuditBackDateReasonVisible(true);
    } else if (
      allTransactionIdsArr.includes(transactionId) &&
      transactionDuplicateReason === ""
    ) {
      //Case: Duplicate Transaction ID Reason popup open
      setIsModalOpen(true);
      setVisible(true);
    } else if (
      clientId &&
      departmentId &&
      processId &&
      agentId &&
      formId &&
      transactionId &&
      transactionDate
    ) {
      let transactionDte = moment(transactionDate).format("YYYY-MM-DD");
      secureLocalStorage.setItem("auditFormInput", auditFormInput);

      if (submitFormModal === "clickedOK") {
        //Case: When all form fields are valid for submission then navigate to Audit View
        setIsModalOpen(false);
        setIsModalVisible(false);
        setVisible(true);
        navigate("/audits/" + formId, {
          state: {
            route: location.pathname,
            clientId,
            departmentId,
            auditorId: secureLocalStorage.getItem("lIUId"),
            agentId,
            processId,
            transactionId,
            transactionDte,
            transactionDuplicateReason: transactionDuplicateReason,
            auditBackDateReason: auditBackDateReason,
            formMethd: "create",
            insuredName: insuredName,
            transactionAmount: transactionAmount,
            invoiceCount: invoiceCount,
          },
        });
        setAuditFormErrors({});
      } else {
        setIsModalOpen(true);
        setIsModalVisible(true);
      }
    } else {
      setIsModalOpen(true);

      {
        /*For showing form errors*/
      }
      if (
        auditFormInput &&
        auditFormInput?.Client === "Lisa Baker" &&
        auditFormInput?.Department === "Accounting" &&
        auditFormInput?.Process === "Cash Posting"
      ) {
        setAuditFormErrors((state) => ({
          ...state,
          client: clientId ? "" : "Client is required",
          department: departmentId ? "" : "Department is required",
          process: processId ? "" : "Process is required",
          agentId: agentId ? "" : "Agent is required",
          auditForm: formId ? "" : "Form is required",
          transactionId: transactionId ? "" : "Transaction ID is required",
          transactionDate: transactionDate
            ? ""
            : "Transaction Date is required",
          auditDateTime: auditDateTime ? "" : "Audit Date and Time is required",
          transactionAmount: transactionAmount
            ? ""
            : "Transaction Amount is required",
          invoiceCount: invoiceCount ? "" : "Invoice Count is required",
        }));
      } else {
        setAuditFormErrors((state) => ({
          ...state,
          client: clientId ? "" : "Client is required",
          department: departmentId ? "" : "Department is required",
          process: processId ? "" : "Process is required",
          agentId: agentId ? "" : "Agent is required",
          auditForm: formId ? "" : "Form is required",
          transactionId: transactionId ? "" : "Transaction ID is required",
          transactionDate: transactionDate
            ? ""
            : "Transaction Date is required",
          auditDateTime: auditDateTime ? "" : "Audit Date and Time is required",
          transactionAmount: "",
          invoiceCount: "",
        }));
      }
    }
  };

  {
    /*PerformAudit - Cancel click*/
  }
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  {
    /*Calling audit detail page on its ID's click*/
  }
  const handleAuditDetailClickedLink = () => {
    secureLocalStorage.setItem("clickedLink", "auditsDetailLinkClick");
    secureLocalStorage.setItem("fromComponent", "audits");
  };

  {
    /*Fetch the list of Processes*/
  }
  const getProcesses = () => {
    const endpoint = "/processes/findProcessesByDept";
    const method = "POST";
    const payload = {
      role: slsRole,
      department: departmentId,
    };

    APICaller(slsRole, slsToken, endpoint, method, payload)
      .then((response) => {
        const temp = [];
        response?.map((task, index) => {
          temp.push({ label: task?.name, value: task?._id });
        });
        setTasks(temp);
      })
      .catch((error) => {});
  };

  {
    /*Fetch the list of audit forms*/
  }
  const getForms = () => {
    const endpoint = "/auditForms/findAuditFormByProcess";
    const method = "POST";
    const payload = {
      role: slsRole,
      process: processId,
    };

    APICaller(slsRole, slsToken, endpoint, method, payload)
      .then((response) => {
        const temp = [];
        response?.map((form, index) => {
          temp.push({ label: form?.formName, value: form?._id });
        });
        setForms(temp);
      })
      .catch((error) => {});
  };

  {
    /*Fetch the list of agents*/
  }
  const getAgents = () => {
    const endpoint = "/users/findUserByRoleAndClient";
    const method = "POST";
    const payload = {
      reqRole: "agent",
      client: clientId,
    };

    APICaller(slsRole, slsToken, endpoint, method, payload)
      .then((response) => {
        const temp = [];
        response?.map((agent, index) => {
          temp.push({
            label: agent?.name + " (Emp ID: " + agent.empID + ")",
            value: agent?._id,
          });
        });
        setAgents(temp);
      })
      .catch((error) => {});
  };

  {
    /*Fetch the list of Clients*/
  }
  const getClients = () => {
    const endpoint = "/fetchWithoutPagination";
    const method = "POST";
    const payload = { route: "clients", action: "findAll" };

    APICaller(slsRole, slsToken, endpoint, method, payload)
      .then((response) => {
        const temp = [];
        response?.map((client, index) => {
          temp.push({ label: client?.name, value: client?._id });
        });
        setClients(temp);
      })
      .catch((error) => {});
  };

  {
    /*Fetch the list of Departments*/
  }
  const getDepartments = () => {
    const endpoint = "/fetchWithoutPagination";
    const method = "POST";
    const payload = { route: "departments", action: "findAll" };

    APICaller(slsRole, slsToken, endpoint, method, payload)
      .then((response) => {
        const temp = [];
        response?.map((department, index) => {
          temp.push({ label: department?.name, value: department?._id });
        });
        setDepartments(temp);
      })
      .catch((error) => {});
  };

  {
    /*Export functionality on Audit Listing's table clicking on checkboxes*/
  }
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const exportColumns = [
    { key: "Audit ID", path: "_id" },
    { key: "Auditor", path: "auditor.name" },
    { key: "Audit Score", path: "score" },
    { key: "Client", path: "client.name" },
    { key: "Department", path: "department.name" },
    { key: "Process", path: "process.name" },
    { key: "Audit Form", path: "auditForm.formName" },
    { key: "Transaction ID", path: "transaction_id" },
    {
      key: "Transaction Date",
      path: "transactionDate",
      formatter: (value) =>
        value ? moment(value).format("YYYY-MM-DD HH:mm A") : "",
    },
    { key: "Reason for Audit's back date", path: "auditBackDateReason" },
    {
      key: "Reason for duplicate Transaction ID",
      path: "transactionDuplicateReason",
    },
    { key: "Transaction Amount", path: "transactionAmount" },
    { key: "Invoice count", path: "invoiceCount" },
    { key: "Insured Name", path: "insuredName" },
    { key: "Supervisor", path: "agentReportingSupervisor.name" },
    { key: "Status", path: "stage" },
    { key: "Agent Name", path: "agent.name" },
    { key: "Agent Emp ID", path: "agent.empID" },
    {
      key: "Fatal",
      path: "fatalNotFatalInfo",
      formatter: (value) => (value ? JSON.parse(value).fatal : ""),
    },
    {
      key: "Not Fatal",
      path: "fatalNotFatalInfo",
      formatter: (value) => (value ? JSON.parse(value).notFatal : ""),
    },
    {
      key: "Created At",
      path: "createdAt",
      formatter: (value) =>
        value ? moment(value).format("YYYY-MM-DD HH:mm A") : "",
    },
    {
      key: "Updated At",
      path: "updatedAt",
      formatter: (value) =>
        value ? moment(value).format("YYYY-MM-DD HH:mm A") : "",
    },
  ];
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);

      exportJson(
        selectedRows,
        globalState?.auditLists,
        "AuditReport",
        exportColumns,
        setGlobalState,
        globalState
      );
    },
  };

  {
    /*Defining columns for Audit Listing*/
  }
  const columns = [
    {
      title: () => {
        return <div className="tableTitle">AUDIT ID</div>;
      },
      dataIndex: "id",
      render: (text, row) => {
        // Log row object to console
        return (
          <Link
            to={"/audits/" + row.id}
            state={{
              route: location.pathname,
              auditFormHasWeightage: row.hasWeightage,
              formMethd: row.formMethd,
              process: row.process?._id,
            }}
            style={{ color: "black" }}
          >
            <span
              className="rowstyle"
              style={{ color: "#1875CB" }}
              onClick={handleAuditDetailClickedLink}
            >
              {text}
            </span>
          </Link>
        );
      },
    },
    {
      title: () => {
        return <div className="tableTitle">Form</div>;
      },
      dataIndex: "form",
      render: (text, row) =>
        secureLocalStorage.getItem("role") === "tl" ||
        secureLocalStorage.getItem("role") === "atl" ? (
          <span className="rowstyle">{text}</span>
        ) : (
          <Link
            to={"/auditForms/" + row.formId}
            state={{ route: "/auditForms" }}
            style={{ color: "black" }}
          >
            <span
              className="rowstyle"
              style={{ color: "#1875CB" }}
              onClick={() =>
                secureLocalStorage.setItem("fromComponent", "audits")
              }
            >
              {text}
            </span>
          </Link>
        ),
    },
    {
      title: () => {
        return <div className="tableTitle">Transaction ID</div>;
      },
      dataIndex: "transaction_id",
      render: (text) => <span className="rowstyle">{text}</span>,
    },
    {
      title: () => {
        return <div className="tableTitle">Auditor</div>;
      },
      dataIndex: "auditor",
      render: (text) => <span className="rowstyle">{text?.name}</span>,
    },
    {
      title: () => {
        return <div className="tableTitle">Agent</div>;
      },
      dataIndex: "agent",
      render: (text) => <span className="rowstyle">{text?.name}</span>,
    },
    {
      title: () => {
        return <div className="tableTitle">Supervisor</div>;
      },
      dataIndex: "supervisor",
      render: (text) => <span className="rowstyle">{text}</span>,
    },
    {
      title: () => {
        return <div className="tableTitle">Audit Date</div>;
      },
      dataIndex: "createdAt",
      render: (text) => (
        <span className="rowstyle">
          {text && moment(text).format("DD-MM-YY HH:mm A")}
        </span>
      ),
    },
    {
      title: () => {
        return <div className="tableTitle">Transaction Date</div>;
      },
      dataIndex: "transaction_date",
      render: (text) => (
        <span className="rowstyle">
          {text && moment(text).format("DD-MM-YY HH:mm A")}
        </span>
      ),
    },
    {
      title: () => {
        return <div className="tableTitle">Status</div>;
      },
      dataIndex: "stage",
      render: (text) => (
        <span className="rowstyle">
          {" "}
          <img
            src={
              text === "Audit Completed"
                ? "audited.PNG"
                : text === "Audit Pending"
                ? "pending.png"
                : text === "Accepted"
                ? "completed.png"
                : "disputed.png"
            }
            style={{ width: "17px" }}
            alt="logo"
          />
          &nbsp;{text?.toUpperCase()}
        </span>
      ),
    },
    {
      title: () => {
        return <div className="tableTitle"> Score</div>;
      },
      dataIndex: "score",
      render: (text, row) => <span className="rowstyle">{text}%</span>,
    },
    {
      title: () => {
        return <div className="tableTitle"> Active</div>;
      },
      dataIndex: "active",
      render: (text) => (
        <span
          className={
            text?.toUpperCase() === "ACTIVE"
              ? "rowstyle adtAS"
              : "rowstyle adtCS"
          }
        >
          {text && text?.toUpperCase()}
        </span>
      ),
    },
    {
      title: () => {
        return <div className="tableTitle"> </div>;
      },
      dataIndex: "comments",
      render: (text) => (
        <span className="rowstyle">
          {" "}
          <img
            src={text > 0 ? "comment.png" : "noComment.png"}
            style={{ width: "17px" }}
            alt="logo"
          />
          &nbsp;{text}
        </span>
      ),
    },
    {
      title: () => {
        return <div className="tableTitle"> </div>;
      },
      dataIndex: "auditor",
      render: (text) => (
        <span className="rowstyle">
          {text && text?.profilePicUrl ? (
            <img
              className="auditProfileImage"
              src={
                process.env.REACT_APP_BACKEND + "/images/" + text?.profilePicUrl
              }
              alt={text?.name}
            />
          ) : (
            <img
              className="auditProfileImage"
              src={process.env.REACT_APP_BACKEND + "/images/dummy.jpg"}
              alt={text?.name}
            />
          )}
        </span>
      ),
    },
  ];

  useEffect(() => {
    //Permission
    if (
      secureLocalStorage.getItem("role") === "admin" ||
      secureLocalStorage.getItem("role") === "qc" ||
      secureLocalStorage.getItem("role") === "auditor"
    ) {
      getClients();
      getDepartments();
    }
    getAudits();
  }, []);

  useEffect(() => {
    if (departmentId !== undefined) {
      getProcesses();
    }
    if (processId !== undefined) {
      getForms();
    }
    if (clientId !== undefined) {
      getAgents();
    }
  }, [
    departmentId,
    processId,
    clientId,
    transactionId,
    transactionDate,
    agentId,
  ]);

  /*Audit Date*/
  var today = moment();
  var valid = function (current) {
    return current.isBefore(today);
  };

  /*Audit Date*/

  const [backAuditDateChecked, setBackAuditDateChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setBackAuditDateChecked(e.target.checked);
  };

  const [form] = Form.useForm();

  const handleCreate = () => {
    form
      .validateFields()
      .then((values) => {
        onCreate(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  {
    /*begin:: Download all audits*/
  }
  const onCreateDownloadAll = async (values) => {
    secureLocalStorage.setItem("isActiveLoad", "activeLoad");
    const month = values?.month.format("YYYY-MM");

    try {
      let hasSearchedValue, searchKeyword;
      if (secureLocalStorage.getItem("hasSearchedValue")) {
        hasSearchedValue = "yes";
        searchKeyword = secureLocalStorage.getItem("searchKeyword");
      } else {
        hasSearchedValue = "";
        searchKeyword = "";
      }

      const endpoint = "/downloadAll";
      const method = "POST";
      const payload = {
        role: secureLocalStorage.getItem("role"),
        lIUId: secureLocalStorage.getItem("lIUId"),
        searchKeyword: searchKeyword,
        month: month,
        exportOf: "audit",
        exportColumns: exportColumns,
      };

      const response = await APICaller(
        slsRole,
        slsToken,
        endpoint,
        method,
        payload,
        "blob"
      );

      // Check if the response data is a Blob
      if (response instanceof Blob) {
        // Create a Blob from the response
        const blob = new Blob([response], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Create a link element
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "audits.xlsx";

        // Append to the DOM
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Revoke the Blob URL after the download
        window.URL.revokeObjectURL(link.href);

        // Clean up
        document.body.removeChild(link);

        message.success("Download done successfully");
        setIsDownloadModalOpen(false);
        secureLocalStorage.setItem("isActiveLoad", "");
      } else {
        console.error("Expected a Blob, but got:", response);
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleDownloadAll = async () => {
    secureLocalStorage.setItem("isActiveLoad", "false");
    setIsDownloadModalOpen(true);
  };

  {
    /*end: Download all audits*/
  }

  // Disable dates after the current date
  const disableFutureDates = (current) => {
    const startDate = moment(current).subtract(1, "month").startOf("day");

    // Calculate the end date for the next financial year
    const currentYear = moment().year();
    const nextFinancialYearEnd = moment()
      .month(2)
      .date(31)
      .year(
        currentYear + (moment().isAfter(moment().month(2).date(31)) ? 1 : 0)
      )
      .endOf("day");

    // Disable dates outside the range two months ago from today to next financial year's 31st March
    return (
      current &&
      (current.isBefore(startDate) || current.isAfter(moment().endOf("day")))
    );
  };

  const isAdmin = secureLocalStorage.getItem("role") === "admin";
  const isAuditor = secureLocalStorage.getItem("role") === "auditor";
  const isQC = secureLocalStorage.getItem("role") === "qc";
  const isTL = secureLocalStorage.getItem("role") === "tl";
  const isQCSupervisor =
    isQC && secureLocalStorage.getItem("reportingSupervisor") === null;

  const hasSelectedRows =
    globalState.selectedRowKeysChildInfo &&
    globalState.selectedRowKeysChildInfo.length > 0;
  return (
    <>
      <div className="all-content">
        <div className="d-flex">
          <BeforeTableHeader list={listItems} showListingCount="yes" href="" />
          <div className="right-buttons">
            {(isAdmin || isAuditor || isQC) && (
              <>
                {/*Download as Excel*/}
                {hasSelectedRows && (
                  <JsonToExcel
                    title="Download as Excel"
                    data={globalState.exportAuditReportJson}
                    fileName="auditReport"
                    btnClassName="table-buttons"
                  />
                )}
                {/*Delete, Mark as Complete and Download All*/}
                {(isAdmin || isQCSupervisor) && hasSelectedRows && (
                  <span
                    className="auditDelete table-buttons"
                    onClick={() => {
                      handleAuditAction("delete");
                    }}
                  >
                    Delete
                  </span>
                )}
                {(isAdmin || isQC) && hasSelectedRows && (
                  <span
                    className="auditComplete table-buttons"
                    onClick={() => {
                      handleAuditAction("complete");
                    }}
                  >
                    Mark as Complete
                  </span>
                )}
              </>
            )}
            {(isAdmin || isAuditor || isQC || isTL) && (
              <>
                <div className="downloadAllLoader-container">
                  <span className="" onClick={handleDownloadAll}>
                    Download All
                  </span>

                  <DownloadAllModal
                    open={isDownloadModalOpen}
                    setVisible={setIsDownloadModalOpen}
                    onCreate={onCreateDownloadAll}
                  />
                </div>
              </>
            )}
            {(isAdmin || isAuditor || isQC) && (
              <>
                {/*Perform New Audit*/}
                <span className="performAudit" onClick={showModal}>
                  <Link to="" style={{ color: "black" }}>
                    {" "}
                    + Perform New Audit
                  </Link>
                </span>

                <Modal
                  className="all-modal"
                  onCancel={handleCancel}
                  onOk={handleOk}
                  title={
                    <div className="headermodaltitle">Perform New Audit</div>
                  }
                  open={isModalOpen}
                  footer={[
                    <Button
                      key="back"
                      className="submit-btn border-0 rounded"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>,
                    <Button
                      key="submit"
                      className="submit-btn border-0 rounded"
                      onClick={handleOk}
                    >
                      Perform Audit
                    </Button>,
                  ]}
                  width={"50vw"}
                >
                  {" "}
                  <div id="antdformstyle">
                    <div className="formtitle">Audit Details</div>
                    <div className="details-container px-3">
                      {/*Client and Department Dropdown*/}
                      <div className="details-wrap">
                        {/*Client Dropdown*/}
                        <div className="details-inner ">
                          <div className="form-label-antd">Client</div>
                          <Select
                            showSearch
                            allowClear
                            placeholder="Choose a Client"
                            optionFilterProp="children"
                            onChange={(val, option) => {
                              setAgentCVal("");
                              setAgentId("");
                              setAuditFormInput({
                                ...auditFormInput,
                                Client: option?.label,
                              });
                              setClientId(val);
                              setAuditFormErrors((state) => ({
                                ...state,
                                client: option?.label
                                  ? ""
                                  : "Client is required",
                              }));
                              secureLocalStorage.setItem(
                                "auditFormInput",
                                auditFormInput
                              );
                            }}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                ?.toLowerCase()
                                .includes(input?.toLowerCase())
                            }
                            options={clients}
                          />
                          {auditFormErrors?.client && (
                            <div className="error">
                              {auditFormErrors?.client}
                            </div>
                          )}
                        </div>
                        {/*Department Dropdown*/}
                        <div className="details-inner">
                          <div className="form-label-antd">Department</div>
                          <Select
                            showSearch
                            allowClear
                            placeholder="Choose a Department"
                            optionFilterProp="children"
                            onChange={(val, option) => {
                              setProcessDepCVal("");
                              setProcessId("");
                              setAformVal("");
                              setFormId("");

                              setAuditFormInput({
                                ...auditFormInput,
                                Department: option?.label,
                              });
                              setDepartmentId(val);
                              setAuditFormErrors((state) => ({
                                ...state,
                                department: option?.label
                                  ? ""
                                  : "Department is required",
                              }));
                              secureLocalStorage.setItem(
                                "auditFormInput",
                                auditFormInput
                              );
                            }}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                ?.toLowerCase()
                                .includes(input?.toLowerCase())
                            }
                            options={departments}
                          />
                          {auditFormErrors?.department && (
                            <div className="error">
                              {auditFormErrors?.department}
                            </div>
                          )}
                        </div>
                      </div>

                      {/*Process and Task Dropdown*/}
                      <div className="details-wrap">
                        {/*Process Dropdown*/}
                        <div className="details-inner">
                          <div className="form-label-antd">Process</div>
                          <Select
                            value={
                              processDepCVal === ""
                                ? "Choose a Process"
                                : processDepCVal
                            }
                            showSearch
                            allowClear
                            placeholder="Choose a Process"
                            optionFilterProp="children"
                            onChange={(val, option) => {
                              setAformVal("");
                              setFormId("");
                              setAuditFormInput({
                                ...auditFormInput,
                                Process: option?.label,
                              });
                              setProcessDepCVal(val);
                              setProcessId(val);
                              setAuditFormErrors((state) => ({
                                ...state,
                                process: option?.label
                                  ? ""
                                  : "Process is required",
                              }));
                              secureLocalStorage.setItem(
                                "auditFormInput",
                                auditFormInput
                              );
                            }}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                ?.toLowerCase()
                                .includes(input?.toLowerCase())
                            }
                            options={tasks}
                          />
                          {auditFormErrors?.process && (
                            <div className="error">
                              {auditFormErrors?.process}
                            </div>
                          )}
                        </div>
                        {/*Form Dropdown*/}
                        <div className="details-inner">
                          <div className="form-label-antd">Form</div>
                          <Select
                            value={aformVal === "" ? "Choose a Form" : aformVal}
                            showSearch
                            allowClear
                            placeholder="Choose a Form"
                            optionFilterProp="children"
                            onChange={(val, option) => {
                              setAuditFormInput({
                                ...auditFormInput,
                                "Audit Form": option?.label,
                              });
                              setAformVal(val);
                              setFormId(val);
                              setAuditFormErrors((state) => ({
                                ...state,
                                auditForm: option?.label
                                  ? ""
                                  : "Form is required",
                              }));
                              secureLocalStorage.setItem(
                                "auditFormInput",
                                auditFormInput
                              );
                            }}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                ?.toLowerCase()
                                .includes(input?.toLowerCase())
                            }
                            options={forms}
                          />
                          {auditFormErrors?.auditForm && (
                            <div className="error">
                              {auditFormErrors?.auditForm}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="formtitle">Transaction Details</div>
                    <div className="details-container px-3">
                      {/*Transaction Amount and Invoice Count*/}
                      <div className="details-wrap">
                        {/*Transaction Amount*/}
                        <div className="details-inner">
                          <div className="form-label-antd">
                            Transaction Amount
                          </div>
                          <Input
                            placeholder="Enter the Transaction Amount"
                            onChange={(e) => {
                              setAuditFormInput({
                                ...auditFormInput,
                                "Transaction Amount": e.target.value,
                              });
                              setTransactionAmount(e.target.value);
                              setAuditFormErrors((state) => ({
                                ...state,
                                transactionAmount: e.target.value
                                  ? ""
                                  : "Transaction Amount is required",
                              }));
                              secureLocalStorage.setItem(
                                "auditFormInput",
                                auditFormInput
                              );
                            }}
                          />
                          {auditFormErrors?.transactionAmount && (
                            <div className="error">
                              {auditFormErrors?.transactionAmount}
                            </div>
                          )}
                        </div>
                        {/*Invoice Count*/}
                        <div className="details-inner">
                          <div className="form-label-antd">Invoice Count</div>
                          <Input
                            placeholder="Enter the Invoice Count"
                            onChange={(e) => {
                              setAuditFormInput({
                                ...auditFormInput,
                                "Invoice Count": e.target.value,
                              });
                              setAuditFormErrors((state) => ({
                                ...state,
                                invoiceCount: e.target.value
                                  ? ""
                                  : "Invoice Count is required",
                              }));
                              setInvoiceCount(e.target.value);
                              secureLocalStorage.setItem(
                                "auditFormInput",
                                auditFormInput
                              );
                            }}
                          />
                          {auditFormErrors?.invoiceCount && (
                            <div className="error">
                              {auditFormErrors?.invoiceCount}
                            </div>
                          )}
                        </div>
                      </div>
                      {/*Insured Name and Agent Dropdown*/}
                      <div className="details-wrap">
                        {/*Insured Name*/}
                        <div className="details-inner">
                          <div className="form-label-antd">Insured Name</div>
                          <Input
                            placeholder="Enter the Insured Name"
                            onChange={(e) => {
                              setAuditFormInput({
                                ...auditFormInput,
                                "Insured Name": e.target.value,
                              });
                              setInsuredName(e.target.value);
                              secureLocalStorage.setItem(
                                "auditFormInput",
                                auditFormInput
                              );
                            }}
                          />
                        </div>
                        {/*Agent Dropdown*/}
                        <div className="details-inner">
                          <div className="form-label-antd">Agent</div>
                          <Select
                            value={
                              agentCVal === "" ? "Choose a Agent" : agentCVal
                            }
                            showSearch
                            allowClear
                            placeholder="Choose a Agent"
                            optionFilterProp="children"
                            onChange={(val, option) => {
                              setAuditFormInput({
                                ...auditFormInput,
                                agent: option?.label,
                              });

                              setAgentId(val);
                              setAgentCVal(val);
                              setAuditFormErrors((state) => ({
                                ...state,
                                agentId: option?.label
                                  ? ""
                                  : "Agent is required",
                              }));
                              secureLocalStorage.setItem(
                                "auditFormInput",
                                auditFormInput
                              );
                            }}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                ?.toLowerCase()
                                .includes(input?.toLowerCase())
                            }
                            options={agents}
                          />
                          {auditFormErrors?.agentId && (
                            <div className="error">
                              {auditFormErrors?.agentId}
                            </div>
                          )}
                        </div>
                      </div>
                      {/*Transaction ID and Transaction Date*/}
                      <div className="details-wrap">
                        {/*Transaction ID*/}
                        <div className="details-inner">
                          <div className="form-label-antd">Transaction ID</div>
                          <Input
                            placeholder="Enter the Transaction ID"
                            onChange={(e) => {
                              setAuditFormInput({
                                ...auditFormInput,
                                Transaction: e.target.value,
                              });
                              setTransactionId(e.target.value);
                              setAuditFormErrors((state) => ({
                                ...state,
                                transactionId: e.target.value
                                  ? ""
                                  : "Transaction ID is required",
                              }));
                              secureLocalStorage.setItem(
                                "auditFormInput",
                                auditFormInput
                              );
                            }}
                          />
                          {auditFormErrors?.transactionId && (
                            <div className="error">
                              {auditFormErrors?.transactionId}
                            </div>
                          )}
                        </div>
                        {/*Transaction Date*/}
                        <div className="details-inner">
                          <div className="form-label-antd">
                            Transaction Date
                          </div>
                          <DatePicker
                            disabledDate={disableFutureDates}
                            onChange={(date) => {
                              setTransactionDate(date);

                              setAuditFormInput({
                                ...auditFormInput,
                                "Transaction Date": date,
                              });
                              setAuditFormErrors((state) => ({
                                ...state,
                                transactionDate: date
                                  ? ""
                                  : "Transaction Date is required",
                              }));
                              secureLocalStorage.setItem(
                                "auditFormInput",
                                auditFormInput
                              );
                            }}
                            value={transactionDate}
                          />
                          {auditFormErrors?.transactionDate && (
                            <div className="error">
                              {auditFormErrors?.transactionDate}
                            </div>
                          )}
                        </div>
                      </div>
                      {/*Audit's Back Date check*/}
                      <div className="details-wrap">
                        <div className="details-inner">
                          <div className="form-label-antd">
                            <Checkbox
                              checked={backAuditDateChecked}
                              onChange={handleCheckboxChange}
                              className="form-label-antd"
                            >
                              Is Audit's Back date?
                            </Checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              </>
            )}
          </div>
        </div>
        <div className="all-table-holder">
          <CustomLoader showLoader={showLoader} />
          <Table
            rowSelection={{
              type: selectionType,
              ...rowSelection,
            }}
            columns={columns}
            dataSource={globalState.list}
            rowClassName={(record, index) =>
              record.auditBackDateReason ? "auditBackDateReasonRow" : ""
            }
            pagination={globalState?.pagination}
            onChange={(e) => {
              getAudits("pagination", e);
            }}
          />
        </div>
      </div>
      <AuditBackDateReasonForm
        open={auditBackDateReasonVisible}
        setVisible={setAuditBackDateReasonVisible}
        onCreate={onCreateBackDateReason}
      />

      <DuplicateTransactionReasonForm
        open={visible}
        setVisible={setVisible}
        onCreate={onCreate}
      />

      {/*Confirmation Modal before submitting Perform an Audit's form*/}
      <Modal
        title=""
        open={isModalVisible}
        onOk={handleOk}
        onCancel={() => {
          setIsModalVisible(false);
          setIsModalOpen(true);
        }}
        okText="OK"
        cancelText="Cancel"
        className="audtModal"
      >
        Please review your selected or entered information carefully before
        submitting the form, as changes may not be possible once it's submitted.
      </Modal>

      {/*Audit Delete Modal for Admin Role*/}
      <Modal
        title=""
        open={isDeleteModalVisible}
        onOk={() => {
          handleAuditAction("delete");
        }}
        onCancel={() => {
          setIsDeleteModalVisible(false);
        }}
        okText={
          secureLocalStorage.getItem("role") === "qc" &&
          secureLocalStorage.getItem("reportingSupervisor") === null
            ? "Submit"
            : "Yes"
        }
        cancelText={
          secureLocalStorage.getItem("role") === "qc" &&
          secureLocalStorage.getItem("reportingSupervisor") === null
            ? ""
            : "No"
        }
        className="audtModal"
      >
        {secureLocalStorage.getItem("role") === "qc" &&
        secureLocalStorage.getItem("reportingSupervisor") === null ? (
          <>
            <Form form={form} layout="">
              <Form.Item
                label={
                  <>
                    <span className="auditDeleteReason-label">
                      Provide reason for deleting
                    </span>
                  </>
                }
                name="auditDeleteReason"
                rules={[
                  { required: true, message: "Please input the reason!" },
                ]}
                labelAlign="left"
                className="auditDeleteReason"
              >
                <Input.TextArea rows={2} />
              </Form.Item>
            </Form>
          </>
        ) : (
          "Are you sure you want to delete this audit?"
        )}
      </Modal>
    </>
  );
};
export default Audits;
