import { Form, Modal, Button, DatePicker } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";

const DownloadAllModal = (props) => {
  const { MonthPicker } = DatePicker;
  const { open, setVisible, onCreate } = props;
  const [form] = Form.useForm();

  const startMonth = moment("2023-11", "YYYY-MM"); // Set your start month here
  const endMonth = moment(); // Current month
  const disabledDate = (current) => {
    return (
      current &&
      (current < startMonth.startOf("month") ||
        current > endMonth.endOf("month"))
    );

    /*  const disabledMonths = [0, 2, 11]; return current && disabledMonths.includes(current.month());*/
  };
  const [isActiveLoad, setIsActiveLoad] = useState("");
  useEffect(() => {
    if (secureLocalStorage.getItem("isActiveLoad") === "false") {
      setIsActiveLoad("");
    }
  }, [secureLocalStorage.getItem("isActiveLoad")]);

  const handleCreate = () => {
    form
      .validateFields()
      .then((values) => {
        setIsActiveLoad("activeLoad");
        secureLocalStorage.setItem("isActiveLoad", "activeLoad");
        form.resetFields();

        onCreate(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    setVisible(false);
  };

  return (
    <>
      <Modal
        className="all-modal downloadMdl"
        onCancel={handleCancel}
        onOk={handleCreate}
        title=""
        open={open}
        footer={[
          <div>
            <Button
              key="submit"
              className="submit-btn border-0 rounded"
              onClick={handleCreate}
            >
              Export
            </Button>
            <div className={`loader ${isActiveLoad}`}></div>
          </div>,
          <Button
            key="back"
            className="submit-btn border-0 rounded"
            onClick={handleCancel}
          >
            Cancel
          </Button>,
        ]}
        width={"20vw"}
      >
        {isActiveLoad === "" ? (
          <Form form={form} layout="">
            <Form.Item
              label=""
              name="month"
              rules={[{ required: true, message: "Please select the month!" }]}
              labelAlign="left"
              className=""
            >
              <MonthPicker
                disabledDate={disabledDate}
                placeholder="Select Month"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Form>
        ) : (
          <div className={`downloadAllLoader`}>
            <div>In-progress</div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default DownloadAllModal;
