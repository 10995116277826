import React, { useState, useEffect, useContext } from "react";
import {
  Form,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Select,
  Table,
  Typography,
} from "antd";
import { useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { GlobalStateContext } from "../../utils/GlobalStateContext";
import APICaller from "../../utils/APICaller";
import HandleSearch from "../../utils/CommonSearchComponent";
import CustomLoader from "../CustomLoader";
import BeforeTableHeader from "../layouts/beforeTableHeader";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  statuses,
  ...restProps
}) => {
  let inputNode;
  if (dataIndex === "status") {
    inputNode = (
      <Select
        showSearch
        allowClear
        options={statuses}
        filterOption={(input, option) =>
          option.value.toLowerCase().includes(input.toLowerCase())
        }
      />
    );
  } else {
    inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const Permissions = ({ children }) => {
  const [tableForm] = Form.useForm();
  const location = useLocation();
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const [showLoader, setShowLoader] = useState(true);
  const slsRole = secureLocalStorage.getItem("role");
  const slsToken = secureLocalStorage.getItem("accessToken");
  const slsLIUId = secureLocalStorage.getItem("lIUId");
  const [data, setData] = useState([]);
  const [statuses, setStatuses] = useState([
    { label: "ACTIVE", value: "ACTIVE" },
    { label: "INACTIVE", value: "INACTIVE" },
  ]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {},
  };
  const additionalColumns = [];
  const columns = [
    {
      title: () => {
        return <div className="tableTitle"> ID</div>;
      },
      dataIndex: "id",
      width: "2%",
      render: (text) => <span className="rowstyle">{text}</span>,
    },
    {
      title: () => {
        return <div className="tableTitle">Name</div>;
      },
      dataIndex: "name",
      width: "20%",
      editable: true,
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, row) => <span className="rowstyle">{text}</span>,
    },
    {
      title: () => {
        return <div className="tableTitle">Status</div>;
      },
      dataIndex: "status",
      width: "10%",
      editable: true,
    },
    {
      title: () => {
        return <div className="tableTitle"> Created At</div>;
      },
      dataIndex: "createdAt",
      width: "10%",
      render: (text) => <span className="rowstyle">{text}</span>,
    },
  ];

  additionalColumns.push(
    {
      title: () => {
        return <div className="tableTitle"> Updated At</div>;
      },
      dataIndex: "updatedAt",
      width: "15%",
    },
    {
      title: () => {
        return <div className="tableTitle"> Action</div>;
      },
      dataIndex: "action",
      width: "10%",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    }
  );
  columns.push(...additionalColumns);
  const mergedColumns =
    columns &&
    columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
          statuses: statuses,
        }),
      };
    });
  const [selectionType, setSelectionType] = useState("checkbox");

  /*begin::getPermissionsList will fetch list of users*/
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 50 });

  const getPermissionsList = async (source = "", pagination = "") => {
    let hasSearchedValue, searchKeyword;
    if (secureLocalStorage.getItem("hasSearchedValue")) {
      hasSearchedValue = "yes";
      searchKeyword = secureLocalStorage.getItem("searchKeyword");
    } else {
      hasSearchedValue = "";
      searchKeyword = "";
    }
    const searchIn = location.pathname.replace("/", "");

    const callActionFrom = source ? source : "";
    const globalStates = {
      globalState: globalState,
      setGlobalState: setGlobalState,
    };

    const allStates = {
      showLoader: showLoader,
      setShowLoader: setShowLoader,
      pagination: pagination
        ? pagination
        : { current: 1, page: 1, pageSize: 50 },
      setPagination: setPagination,
      list: list,
      setList: setList,
    };

    await HandleSearch(
      hasSearchedValue,
      searchIn,
      searchKeyword,
      callActionFrom,
      globalStates,
      allStates
    );
  };
  useEffect(() => {
    getPermissionsList();
  }, []);

  /*end::getPermissionsList will fetch list of users*/

  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;
  const edit = (record) => {
    tableForm.setFieldsValue({
      name: "",
      status: "",
      createdAt: "",
      updatedAt: "",
      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey("");
  };
  const save = async (key) => {
    try {
      const row = await tableForm.validateFields();
      const newData = globalState.list;
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];

        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey("");

        const endpoint = "/permissions/" + key;
        const method = "PUT";
        const payload = {
          name: newData[index]?.name,
          status: newData[index]?.status,
          historySource: "backend",
          historyAction: "update",
          historyUserID: slsLIUId,
        };
        APICaller(slsRole, slsToken, endpoint, method, payload)
          .then((response) => {
            message.success("Permission updated successfully.");
            tableForm.resetFields();
            getPermissionsList();
          })
          .catch((error) => {});
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };
  return (
    <>
      <div className="all-content">
        <BeforeTableHeader
          list={list && list.length}
          showListingCount="yes"
          href="/permissions/add"
        />
        <div className="all-table-holder">
          <CustomLoader showLoader={showLoader} />
          <Form form={tableForm} component={false}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              bordered
              columns={mergedColumns}
              //columns={columns}
              dataSource={globalState.list}
              // pagination={globalState?.pagination}
              // onChange={(e) => {
              //   getPermissionsList("pagination", e);
              // }}
              rowClassName="editable-row"
              pagination={{
                onChange: cancel,
              }}
            />
          </Form>
        </div>
      </div>
    </>
  );
};
export default Permissions;
