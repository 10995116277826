import secureLocalStorage from "react-secure-storage";
import APICaller from "./APICaller";
import moment from "moment";

const HandleSearch = async (
  hasSearchedValue,
  searchIn,
  searchKeyword,
  callActionFrom,
  globalStates,
  allStates
) => {
  // Clear search data if the input is empty
  const searchKeywordOnRefresh = document.querySelector(".inputS");

  if (!searchKeywordOnRefresh?.value) {
    if (secureLocalStorage.getItem("hasSearchedValue")) {
      secureLocalStorage.removeItem("hasSearchedValue");
    }
    if (secureLocalStorage.getItem("searchKeyword")) {
      secureLocalStorage.removeItem("searchKeyword");
    }
  }

  // Destructure state variables
  const { globalState, setGlobalState } = globalStates;
  const {
    setShowLoader,
    pagination,
    setAllTransactionIdsArr,
    setList,
    setListItems,
    tab,
  } = allStates;

  const { current, page, pageSize, total } = pagination;
  const pge = current || page;

  // API call parameters
  const action = "findAll";
  const role = secureLocalStorage.getItem("role");
  const accessToken = secureLocalStorage.getItem("accessToken");
  const lIUId = secureLocalStorage.getItem("lIUId");

  // Call the API and get records
  const allRecs = await APICaller(
    role,
    accessToken,
    `/${searchIn}/${action}`,
    "POST",
    {
      page: pge,
      pageSize,
      role: role,
      lIUId: lIUId,
      searchIn: searchIn,
      searchKeyword: searchKeyword
        ? searchKeyword
        : secureLocalStorage.getItem("searchKeyword")
        ? secureLocalStorage.getItem("searchKeyword")
        : "",
      tab: tab,
    }
  );

  // Process the response based on the searchIn type
  const { response, totalCount } = allRecs;

  if (!response) {
    setShowLoader(false);
    return; // Early exit if no response
  }

  //let temp = [];
  //const allTransactionIdsArr = [];

  // Function to update global state and list
  // const updateGlobalState = (recordCountFor) => {
  //   setList(temp);
  //   setGlobalState({
  //     ...globalState,
  //     list: temp,
  //     recordCountFor,
  //     recordCount: totalCount,
  //     pagination: {
  //       ...pagination,
  //       page: pge,
  //       pageSize: pageSize,
  //       total: totalCount,
  //     },
  //   });
  //   setShowLoader(false);
  // };

  // Switch case to handle different search types
  /*switch (searchIn) {
    case "auditForms":
      temp = response.map((dataList, index) => ({
        key: dataList._id,
        id: index + 1,
        form_name: dataList.formName,
        total_audits: dataList.totalAudits,
        active_audits: dataList.activeAudits,
        hasWeightage: dataList.hasWeightage,
        created_at: moment(dataList.createdAt).format("DD-MM-YY HH:mm A"),
      }));
      updateGlobalState("auditForms");
      break;

    case "audits":
    case "deleted-audits":
    case "escalations":
      response.forEach((audit, index) => {
        const objectToAdd = {
          key: index + 1,
          id: audit?._id,
          transaction_id: audit?.transaction_id,
          agent: audit?.agent,
          auditor: audit?.auditor,
          supervisor:
            audit?.agentReportingSupervisor?.name || audit?.agentAtl?.name,
          createdAt: audit?.createdAt,
          transaction_date: audit?.transactionDate,
          auditBackDateReason: audit?.auditBackDateReason,
          form: audit?.auditForm?.formName,
          formId: audit?.auditForm?._id,
          stage: audit?.stage,
          score: JSON.parse(audit?.auditedScoreInfo)?.score,
          active: audit?.status,
          comments: audit?.comment,
          hasWeightage: audit?.hasWeightage,
          formMethd: "update",
        };
        temp.push(objectToAdd);
        allTransactionIdsArr.push(audit?.transaction_id);
      });
      setAllTransactionIdsArr(allTransactionIdsArr);
      updateGlobalState(searchIn);
      break;

    case "roles":
      temp = response.map((dataList, index) => ({
        key: dataList._id,
        id: index + 1,
        name: dataList.name,
        permission: dataList.permission,
        created_at: moment(dataList.createdAt).format("DD-MM-YY HH:mm A"),
      }));
      updateGlobalState("roles");
      break;

    case "users":
      temp = response.map((dataList, index) => ({
        key: dataList._id,
        id: index + 1,
        empID: dataList.empID,
        name: dataList.name,
        email: dataList.email,
        role: dataList.role,
        reportingSupervisor: dataList.reportingSupervisor?.name || "",
        atl: dataList.atl?.name || "",
        client: dataList.client?.name || "",
        status: dataList.status || "",
        created_at: moment(dataList.createdAt).format("DD-MM-YY HH:mm A"),
      }));
      secureLocalStorage.setItem("searchData", temp);
      updateGlobalState("users");
      break;

    case "clients":
    case "processes":
    case "departments":
      temp = response.map((dataList, index) => ({
        key: dataList._id,
        id: index + 1,
        name: dataList.name,
        created_at: moment(dataList.createdAt).format("DD-MM-YY HH:mm A"),
      }));
      updateGlobalState(searchIn);
      break;

    case "sendForms":
      temp = response.map((dataList, index) => ({
        key: dataList?._id,
        id: dataList?._id,
        formDisplayName: dataList?.formName?.formDisplayName,
        recipient: dataList?.recipients.toString(),
        status: dataList?.status,
        created_at:
          dataList.createdAt &&
          moment(dataList.createdAt).format("DD-MM-YY HH:mm A"),
        updated_at:
          dataList.updatedAt &&
          moment(dataList.updatedAt).format("DD-MM-YY HH:mm A"),
      }));
      updateGlobalState("sendForms");
      break;

    case "feedback-requests":
      const { sent, reverted } = allRecs;
      temp = response.map((dataList) => ({
        key: dataList?._id,
        id: dataList?._id,
        formType: dataList?.form?.formType,
        recipient: dataList?.recipient,
        status: dataList?.status,
        createdAt:
          dataList.createdAt &&
          moment(dataList.createdAt).format("DD-MM-YY HH:mm A"),
        capturedOn:
          dataList.capturedOn &&
          moment(dataList.capturedOn).format("DD-MM-YY HH:mm A"),
        feedBackLink: `${process.env.REACT_APP_URI}/survey/${dataList?._id}`,
        previewBy: dataList?.status === "CLOSED" ? dataList?._id : "",
      }));
      updateGlobalState("feedback-requests");
      setGlobalState((prev) => ({
        ...prev,
        sent,
        reverted,
        notReverted: sent && reverted >= 0 ? sent - reverted : 0,
      }));
      break;

    default:
      setShowLoader(false);
      break;
  }*/
  switch (searchIn) {
    case "auditForms": {
      const { response, totalCount } = allRecs;
      if (response) {
        const temp = response.map((dataList, index) => ({
          key: dataList._id,
          id: index + 1,
          form_name: dataList.formName,
          total_audits: dataList.totalAudits,
          active_audits: dataList.activeAudits,
          hasWeightage: dataList.hasWeightage,
          status: dataList.status || "",
          createdAt: dataList.createdAt
            ? moment(dataList.createdAt).format("DD-MM-YY HH:mm A")
            : "",
          updatedAt: dataList.updatedAt
            ? moment(dataList.updatedAt).format("DD-MM-YY HH:mm A")
            : "",
        }));
        setList(temp);
        setGlobalState({
          ...globalState,
          list: temp,
          recordCountFor: "auditForms",
          recordCount: totalCount,
          // exportAuditReportJson: "[{}]",
          selectedRowKeysChildInfo: [],
          searchResult: "empty",
          pagination: {
            ...pagination,
            page: pge,
            pageSize: pageSize,
            total: totalCount,
          },
        });
        setShowLoader(false);
      }
      break;
    }
    case "audits": {
      setGlobalState({ ...globalState, list: [] });
      const { response, totalCount } = allRecs;
      if (response) {
        const temp = [];
        const allTransactionIdsArr = [];
        response?.map((audit, index) => {
          // Initialize the object with both key and value properties
          const objectToAdd = {
            key: index + 1,
            id: audit?._id,
            processId: audit?.process?._id,
            transaction_id: audit?.transaction_id,
            agent: audit?.agent,
            auditor: audit?.auditor,
            supervisor: audit?.agentReportingSupervisor?.name
              ? audit?.agentReportingSupervisor?.name
              : audit?.agentAtl?.name,
            createdAt: audit?.createdAt,
            transaction_date: audit?.transactionDate,
            auditBackDateReason: audit?.auditBackDateReason,
            form: audit?.auditForm?.formName,
            formId: audit?.auditForm?._id,
            stage: audit?.stage,
            score: JSON.parse(audit?.auditedScoreInfo)?.score,
            active: audit?.status,
            comments: audit?.comment,
            hasWeightage: audit?.hasWeightage,
            formMethd: "update",
          };
          temp.push(objectToAdd);

          allTransactionIdsArr.push(audit?.transaction_id);
          setAllTransactionIdsArr([
            ...allTransactionIdsArr,
            allTransactionIdsArr,
          ]);
        });

        setList(temp);
        setListItems(totalCount);

        setGlobalState({
          ...globalState,
          list: temp,
          recordCountFor: "audits",
          recordCount: totalCount,
          auditLists: allRecs,
          exportAuditReportJson: "[{}]",
          selectedRowKeysChildInfo: [],
          searchResult: "empty",
          pagination: {
            ...pagination,
            page: pge,
            pageSize: pageSize,
            total: totalCount,
          },
        });
        setShowLoader(false);
      }
      break;
    }
    case "deleted-audits": {
      setGlobalState({ ...globalState, list: [] });
      const { response, totalCount } = allRecs;
      if (response) {
        const temp = [];
        const allTransactionIdsArr = [];
        response?.map((deletedAudit, index) => {
          // Initialize the object with both key and value properties
          const objectToAdd = {
            key: index + 1,
            id: deletedAudit?._id,
            transaction_id: deletedAudit?.transaction_id,
            agent: deletedAudit?.agent,
            auditor: deletedAudit?.auditor,
            supervisor: deletedAudit?.agentReportingSupervisor?.name
              ? deletedAudit?.agentReportingSupervisor?.name
              : deletedAudit?.agentAtl?.name,
            createdAt: deletedAudit?.createdAt,
            transaction_date: deletedAudit?.transactionDate,
            auditBackDateReason: deletedAudit?.auditBackDateReason,
            auditDeleteReason: deletedAudit?.auditDeleteReason,
            form: deletedAudit?.auditForm?.formName,
            formId: deletedAudit?.auditForm?._id,
            stage: deletedAudit?.stage,
            score: JSON.parse(deletedAudit?.auditedScoreInfo)?.score,
            active: deletedAudit?.status,
            comments: deletedAudit?.comment,
            hasWeightage: deletedAudit?.hasWeightage,
            formMethd: "update",
          };
          temp.push(objectToAdd);

          allTransactionIdsArr.push(deletedAudit?.transaction_id);
          setAllTransactionIdsArr([
            ...allTransactionIdsArr,
            allTransactionIdsArr,
          ]);
        });

        setList(temp);
        setListItems(totalCount);

        setGlobalState({
          ...globalState,
          list: temp,
          recordCountFor: "deleted-audits",
          recordCount: totalCount,
          deletedAuditLists: allRecs,
          exportAuditReportJson: "[{}]",
          selectedRowKeysChildInfo: [],
          searchResult: "empty",
          pagination: {
            ...pagination,
            page: pge,
            pageSize: pageSize,
            total: totalCount,
          },
        });
        setShowLoader(false);
      }
      break;
    }
    case "escalations": {
      setGlobalState({ ...globalState, list: [] });
      const { response, totalCount } = allRecs;
      if (response) {
        const temp = [];
        const allTransactionIdsArr = [];
        response?.map((dataList, index) => {
          // Initialize the object with both key and value properties
          const objectToAdd = {
            key: index + 1,
            id: dataList?._id,
            agent: dataList?.agent,
            auditor: dataList?.auditor,
            reportingTl: dataList?.reportingTl,
            errorImpact: dataList?.errorImpact,
            transaction: dataList?.transactionId,
            transactionDuplicateReason: dataList?.transactionDuplicateReason,
            department: dataList?.department?.name,
            client: dataList?.client?.name,
            stage: dataList?.stage,
            status: dataList.status || "",
            errorRaiseDate: moment(dataList?.errorRaiseDate).format(
              "DD/MM/YYYY H:mm A"
            ),
            escalation_id: "122234",

            //created_at: moment(dataList.createdAt).format("DD-MM-YY HH:mm A"),
            //updated_at: moment(dataList.updatedAt).format("DD-MM-YY HH:mm A"),
          };

          temp.push(objectToAdd);
          if (dataList?.transactionId) {
            allTransactionIdsArr.push(dataList?.transactionId);
            setAllTransactionIdsArr([
              ...allTransactionIdsArr,
              allTransactionIdsArr,
            ]);
          }
        });

        setList(temp);
        setListItems(totalCount);
        setGlobalState({
          ...globalState,
          list: temp,
          escalationLists: allRecs,
          recordCountFor: "escalations",
          recordCount: totalCount,
          exportEscalationReportJson: "[{}]",
          selectedRowKeysChildInfo: [],
          searchResult: "empty",
          pagination: {
            ...pagination,
            page: pge,
            pageSize: pageSize,
            total: totalCount,
          },
        });

        setShowLoader(false);
      }
      break;
    }
    case "processes": {
      setGlobalState({ ...globalState, list: [] });
      const { response, totalCount } = allRecs;
      if (response) {
        const temp = response.map((dataList, index) => ({
          key: dataList._id,
          id: index + 1,
          name: dataList.name,
          department: dataList.department ? dataList.department.name : "",
          status: dataList.status || "",
          createdAt: dataList.createdAt
            ? moment(dataList.createdAt).format("DD-MM-YY HH:mm A")
            : "",
          updatedAt: dataList.updatedAt
            ? moment(dataList.updatedAt).format("DD-MM-YY HH:mm A")
            : "",
        }));
        setList(temp);
        setGlobalState({
          ...globalState,
          list: temp,
          recordCountFor: "processes",
          recordCount: totalCount,
          pagination: {
            ...pagination,
            page: pge,
            pageSize: pageSize,
            total: totalCount,
          },
        });
        setShowLoader(false);
      }
      break;
    }
    case "clients": {
      setGlobalState({ ...globalState, list: [] });
      const { response, totalCount } = allRecs;
      if (response) {
        const temp = response.map((dataList, index) => ({
          key: dataList._id,
          id: index + 1,
          name: dataList.name,
          status: dataList.status || "",
          createdAt: dataList.createdAt
            ? moment(dataList.createdAt).format("DD-MM-YY HH:mm A")
            : "",
          updatedAt: dataList.updatedAt
            ? moment(dataList.updatedAt).format("DD-MM-YY HH:mm A")
            : "",
        }));
        setList(temp);
        setGlobalState({
          ...globalState,
          list: temp,
          recordCountFor: "clients",
          recordCount: totalCount,
          pagination: {
            ...pagination,
            page: pge,
            pageSize: pageSize,
            total: totalCount,
          },
        });
        setShowLoader(false);
      }
      break;
    }
    case "departments": {
      setGlobalState({ ...globalState, list: [] });
      const { response, totalCount } = allRecs;
      if (response) {
        const temp = response.map((dataList, index) => ({
          key: dataList._id,
          id: index + 1,
          name: dataList.name,
          status: dataList.status || "",
          createdAt: dataList.createdAt
            ? moment(dataList.createdAt).format("DD-MM-YY HH:mm A")
            : "",
          updatedAt: dataList.updatedAt
            ? moment(dataList.updatedAt).format("DD-MM-YY HH:mm A")
            : "",
        }));
        setList(temp);
        setGlobalState({
          ...globalState,
          list: temp,
          recordCountFor: "departments",
          recordCount: totalCount,
          pagination: {
            ...pagination,
            page: pge,
            pageSize: pageSize,
            total: totalCount,
          },
        });
        setShowLoader(false);
      }
      break;
    }
    case "permissions": {
      setShowLoader(true);
      setGlobalState({ ...globalState, list: [] });
      const { response, totalCount } = allRecs;
      if (response) {
        const temp = response.map((dataList, index) => ({
          key: dataList._id,
          id: index + 1,
          name: dataList.name,
          status: dataList.status || "",
          createdAt: dataList.createdAt
            ? moment(dataList.createdAt).format("DD-MM-YY HH:mm A")
            : "",
          updatedAt: dataList.updatedAt
            ? moment(dataList.updatedAt).format("DD-MM-YY HH:mm A")
            : "",
        }));
        setList(temp);
        setGlobalState({
          ...globalState,
          list: temp,
          recordCountFor: "permissions",
          recordCount: totalCount,
          pagination: {
            ...pagination,
            page: pge,
            pageSize: pageSize,
            total: totalCount,
          },
        });
        setShowLoader(false);
      }
      break;
    }
    case "sections": {
      setShowLoader(true);
      setGlobalState({ ...globalState, list: [] });
      const { response, totalCount } = allRecs;
      if (response) {
        const temp = response.map((dataList, index) => ({
          key: dataList._id,
          id: index + 1,
          name: dataList.name,
          icon: dataList.icon,
          permissions: dataList.permissions,
          status: dataList.status || "",
          createdAt: dataList.createdAt
            ? moment(dataList.createdAt).format("DD-MM-YY HH:mm A")
            : "",
          updatedAt: dataList.updatedAt
            ? moment(dataList.updatedAt).format("DD-MM-YY HH:mm A")
            : "",
        }));
        setList(temp);
        setGlobalState({
          ...globalState,
          list: temp,
          recordCountFor: "sections",
          recordCount: totalCount,
          pagination: {
            ...pagination,
            page: pge,
            pageSize: pageSize,
            total: totalCount,
          },
        });
        setShowLoader(false);
      }
      break;
    }
    case "roles": {
      setShowLoader(true);
      setGlobalState({ ...globalState, list: [] });
      const { response, totalCount } = allRecs;
      if (response) {
        const temp = response.map((dataList, index) => ({
          key: dataList._id,
          id: index + 1,
          name: dataList.name,
          sections: dataList?.sections,
          status: dataList.status || "",
          createdAt: dataList.createdAt
            ? moment(dataList.createdAt).format("DD-MM-YY HH:mm A")
            : "",
          updatedAt: dataList.updatedAt
            ? moment(dataList.updatedAt).format("DD-MM-YY HH:mm A")
            : "",
        }));
        setList(temp);
        setGlobalState({
          ...globalState,
          list: temp,
          recordCountFor: "roles",
          recordCount: totalCount,
          pagination: {
            ...pagination,
            page: pge,
            pageSize: pageSize,
            total: totalCount,
          },
        });
        setShowLoader(false);
      }
      break;
    }
    case "users": {
      setGlobalState({ ...globalState, list: [] });
      const { response, totalCount } = allRecs;
      if (response) {
        const temp = response.map((dataList, index) => ({
          key: dataList._id,
          id: index + 1,
          empID: dataList.empID,
          name: dataList.name,
          email: dataList.email,
          role: dataList.role,
          reportingSupervisor: dataList.reportingSupervisor
            ? dataList?.reportingSupervisor?.name
            : "",
          atl: dataList.atl ? dataList.atl.name : "",
          client: dataList.client ? dataList.client.name : "",
          status: dataList.status || "",
          createdAt: dataList.createdAt
            ? moment(dataList.createdAt).format("DD-MM-YY HH:mm A")
            : "",
          updatedAt: dataList.updatedAt
            ? moment(dataList.updatedAt).format("DD-MM-YY HH:mm A")
            : "",
        }));
        setList(temp);
        secureLocalStorage.setItem("searchData", temp);
        setGlobalState({
          ...globalState,
          list: temp,
          recordCountFor: "users",
          recordCount: totalCount,
          pagination: {
            ...pagination,
            page: pge,
            pageSize: pageSize,
            total: totalCount,
          },
        });
        setShowLoader(false);
      }
      break;
    }

    //VOC
    case "sendForms": {
      setGlobalState({ ...globalState, list: [] });
      const { response, totalCount } = allRecs;

      if (response) {
        const temp = response.map((dataList, index) => ({
          key: dataList?._id,
          id: dataList?._id,
          formDisplayName: dataList?.formName?.formDisplayName,
          recipient: dataList?.recipients.toString(),
          status: dataList?.status,
          created_at:
            dataList.createdAt &&
            moment(dataList.createdAt).format("DD-MM-YY HH:mm A"),
          updated_at:
            dataList.updatedAt &&
            moment(dataList.updatedAt).format("DD-MM-YY HH:mm A"),
        }));
        setList(temp);
        setGlobalState({
          ...globalState,
          list: temp,
          formLists: allRecs,
          recordCountFor: "sendForms",
          recordCount: totalCount,
          exportFormsJson: "[{}]",
          selectedRowKeysChildInfo: [],
          searchResult: "empty",
          pagination: {
            ...pagination,
            page: pge,
            pageSize: pageSize,
            total: totalCount,
          },
        });
        setShowLoader(false);
      }

      break;
    }
    case "feedback-requests": {
      const { response, totalCount, sent, reverted } = allRecs;

      if (response) {
        const temp = response.map((dataList, index) => ({
          key: dataList?._id,
          id: dataList?._id,
          formType: dataList?.form?.formType,
          recipient: dataList?.recipient,
          status: dataList?.status,
          createdAt:
            dataList.createdAt &&
            moment(dataList.createdAt).format("DD-MM-YY HH:mm A"),
          capturedOn:
            dataList.capturedOn &&
            moment(dataList.capturedOn).format("DD-MM-YY HH:mm A"),
          feedBackLink: process.env.REACT_APP_URI + "/survey/" + dataList?._id,
          previewBy: dataList?.status === "CLOSED" ? dataList?._id : "",
        }));
        setList(temp);
        setGlobalState({
          ...globalState,
          list: temp,
          sent: sent,
          reverted: reverted,
          notReverted: sent && reverted >= 0 ? sent - reverted : 0,
          feedbackRequestLists: allRecs,
          recordCountFor: "feedback-requests",
          recordCount: totalCount,
          exportFeedbackRequestsJson: "[{}]",
          selectedRowKeysChildInfo: [],
          searchResult: "empty",
          pagination: {
            ...pagination,
            page: pge,
            pageSize: pageSize,
            total: totalCount,
          },
        });
      }

      break;
    }
    default: {
      setGlobalState({ ...globalState, list: [] });
      break;
    }
  }
};

export default HandleSearch;
