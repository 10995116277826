import axios from "axios";
import { message } from "antd";
const mainDomain = process.env.REACT_APP_BACKEND;

const APICaller = async (
  role,
  token,
  endpoint,
  method,
  payload,
  responseType = "json"
) => {
  try {
    const response = await axios({
      url: `${mainDomain}${endpoint}`,
      method: method || "GET",
      data: payload,
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        role: role,
      },
      responseType: responseType,
    });
    let data = response.data;
    return data;
  } catch (error) {
    handleErrorResponse(error, token, role);
    throw error;
  }
};

// Centralized error handling function
const handleErrorResponse = (error, token, role) => {
  let errorMsg = "";

  // Case: No server response
  if (!error.response) {
    if (token && role !== "") {
      errorMsg = "Unable to connect to the server. Please sign-in later.";
    } else {
      errorMsg = "The server is currently offline. Please try again later.";
    }
    if (Array.isArray(errorMsg)) {
      // If it's an array (multiple validation errors), join with a new line for readability
      showError(errorMsg.join("\n"), "yes"); // Call a function to show the error in Ant Design's message component
    } else {
      // If it's a string, display as-is
      showError(errorMsg);
    }
  } else {
    // // Case: Server responded with error
    // const { statusCode, message: errorMessage } =
    //   error.response.data || typeof error.response.data === "string"
    //     ? JSON.parse(error.response.data)
    //     : {};

    // if (error.response.status === 404) {
    //   errorMsg = errorMessage || "Resource not found.";
    // } else if (error.response.status === 500) {
    //   errorMsg = errorMessage || "Internal server error. Please try again.";
    // } else if (error.response.data && typeof error.response.data === "string") {
    //   try {
    //     const parsedError = JSON.parse(error.response.data);
    //     errorMsg = parsedError.message || "An error occurred.";
    //   } catch (e) {
    //     errorMsg = error.response.data;
    //   }
    // } else {
    //   errorMsg = errorMessage || "An unexpected error occurred.";
    // }

    // Case: Server responded with error
    let statusCode, errorMessage;

    // Only attempt to parse if response.data is a string
    try {
      // Handle case when error.response.data is an object (as expected)
      if (error.response.data && typeof error.response.data === "object") {
        const { statusCode, message } = error.response.data;
        errorMessage = message || "An error occurred.";
      }
      // Handle case when error.response.data is a string (malformed or custom error)
      else if (typeof error.response.data === "string") {
        const parsedError = JSON.parse(error.response.data); // Try to parse the string
        statusCode = parsedError.statusCode || error.response.status;
        errorMessage = parsedError.message || "An error occurred.";
      } else {
        errorMsg = "An unexpected error occurred.";
      }
    } catch (e) {
      // In case of a parsing error, fallback to a default error message
      errorMsg = "Error parsing server response.";
    }
    // Specific case for 403 Forbidden
    if (error.response.status === 403) {
      errorMsg =
        errorMessage || "You do not have permission to access this resource.";
    } else if (error.response.status === 404) {
      errorMsg = errorMessage || "Resource not found.";
    } else if (error.response.status === 500) {
      errorMsg = errorMessage || "Internal server error. Please try again.";
    } else {
      errorMsg = errorMessage || "An unexpected error occurred.";
    }
    if (Array.isArray(errorMsg)) {
      // If it's an array (multiple validation errors), join with a new line for readability
      showError(errorMsg.join("\n"), "yes"); // Call a function to show the error in Ant Design's message component
    } else {
      // If it's a string, display as-is
      showError(errorMsg);
    }
  }
};

// Helper function to show Ant Design error message
const showError = (errorMsg, moreThan = "no") => {
  message.error({
    content: (
      <div style={{ whiteSpace: "pre-line" }}>
        {moreThan === "yes" ? (
          <strong>Errors occurred:</strong>
        ) : (
          <strong>An error occurred:</strong>
        )}
        <p>{errorMsg}</p> {/* This ensures line breaks are respected */}
      </div>
    ),
    duration: 5, // duration in seconds
    className: "error-message", // Add any custom styles you need
  });
};

export default APICaller;
