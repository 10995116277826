import { Divider } from "antd";
import { basic_json } from "./basicJsonStruct";
import FormElement from "./components/qms/form/form";
import secureLocalStorage from "react-secure-storage";
import { useLocation } from "react-router-dom";
export const ParseElementsIntoForm = (data) => {
  let aggJson = basic_json;
  let questions = data.elements.questions;
  let topLevelMeta = data.elements.meta;
  let section = { sectionId: 0, done: false };
  let check_groupings = 0;
  let groupObj = {};
  if (topLevelMeta.groupings.length > 0) {
    check_groupings = topLevelMeta.groupings.length;
    for (let i of topLevelMeta.groupings) {
      groupObj[i["group"]] = -1;
    }
  }
  for (let i = 0; i < questions.length; i++) {
    if (
      questions[i].meta.group !== undefined &&
      groupObj[questions[i].meta.group] === -1
    ) {
      if (questions[i].meta.groupType === "matrix") {
        groupObj[questions[i].meta.group] = aggJson.elements.length;
        aggJson.elements.push({
          type: questions[i].meta.groupType,
          name: questions[i].meta.group,
          titleLocation: "hidden",
          columns: questions[i].options.map((item) => {
            return { value: item, text: item };
          }),
          rows: [
            {
              value: String(questions[i].qkey),
              text: questions[i].serial_no + ". " + questions[i].display_text,
            },
          ],
          alternateRows: true,
          isAllRowRequired: questions[i].meta.required,
        });
      }
      if (questions[i].meta.groupType === "panel") {
        groupObj[questions[i].meta.group] = aggJson.elements.length;
        aggJson.elements.push({
          type: questions[i].meta.groupType,
          name: questions[i].meta.group,
          elements: [
            {
              type: questions[i].meta.inputField || questions[i].input_type,
              name: questions[i].qkey,
              title: questions[i].serial_no + ". " + questions[i].display_text,
              isRequired: questions[i].meta.required,
              startWithNewLine: true,
              maxWidth: questions[i].meta.maxWidth,
              minWidth: questions[i].meta.minWidth,
              rows: questions[i].meta.rows,
              choices: questions[i].choices,
            },
          ],
        });
      }
      continue;
    }
    if (
      questions[i].meta.group !== undefined &&
      groupObj[questions[i].meta.group] !== -1
    ) {
      if (questions[i].meta.groupType === "matrix") {
        aggJson.elements[groupObj[questions[i].meta.group]].rows.push({
          value: String(questions[i].qkey),
          text: questions[i].serial_no + ". " + questions[i].display_text,
        });
      }
      if (questions[i].meta.groupType === "panel") {
        aggJson.elements[groupObj[questions[i].meta.group]].elements.push({
          type: questions[i].meta.inputField || questions[i].input_type,
          name: questions[i].qkey,
          title: questions[i].serial_no + ". " + questions[i].display_text,
          isRequired: questions[i].meta.required,
          startWithNewLine: true,
          maxWidth: questions[i].meta.maxWidth,
          minWidth: questions[i].meta.minWidth,
          rows: questions[i].meta.rows,
          choices: questions[i].choices,
        });
      }
    }
    if (questions[i].meta.group === undefined) {
      aggJson.elements.push({
        type: questions[i].meta.inputField,
        name: questions[i].qkey,
        title: questions[i].serial_no + ". " + questions[i].display_text,
        isRequired: questions[i].meta.required,
        startWithNewLine: true,
        maxWidth: "50%",
        minWidth: "400px",
        rows: 2,
      });
    }
  }

  return aggJson;
};
export const ParseElementsIntoFormNew = (data, getValue) => {
  const location = useLocation();
  let questions = data?.elements?.questions;

  const formElementList = [];

  for (let i = 0; i < questions?.length; i++) {
    formElementList.push(
      <FormElement
        label={questions[i].qkey}
        key={questions[i].qkey} // Unique key for this element
        type={questions[i].input_type}
        showAsterisk={questions[i].meta.showAsterisk}
        required={questions[i].meta.required}
        isQualityMetric={questions[i].meta.isQualityMetric}
        f1={getValue}
        questionId={questions[i].qkey}
        questionDetail={
          questions[i].serial_no + ". " + questions[i].display_text
        }
        options={
          (location?.pathname.split("/")[1] === "audits" &&
            location?.state?.formMethd === "create" &&
            questions[i].qkey === "correction-user") ||
          (questions[i].qkey === "correction-user" &&
            location?.pathname.split("/")[1] === "auditForms")
            ? questions[i]?.createChoices
            : questions[i].choices
        }
        selectedValue={questions[i].selectedValue} //Passing saved value for edit audit purpose
        weightage={questions[i].meta.weightage} //Passing saved value for edit audit purpose
        totalWeightage={questions[i].totalWeightage} //Passing saved value for edit audit purpose
        serial_no={questions[i].serial_no} //Passing saved value for edit audit purpose
      />
    );

    formElementList.push(<Divider key={questions[i].qkey + "_cus"} />);
  }

  return formElementList;
};

export const getQualityQuestionCount = (data) => {
  let qualityCount = 0;
  let questions = data.elements.questions;
  for (let i = 0; i < questions.length; i++) {
    if (questions[i].meta.isQualityMetric) {
      qualityCount++;
    }
  }
  return qualityCount;
};
export const getScoreQuestionCount = (data) => {
  let qualityCount = 0;
  let questions = data?.elements?.questions;

  for (let i = 0; i < questions?.length; i++) {
    if (questions[i]?.input_type == "radio-group") {
      qualityCount++;
    }
  }
  return qualityCount;
};
export const getScoreOld = (data, maxScore) => {
  if (Object.keys(data).length === 0) {
    return 0;
  }

  let currScore = 0;
  let scoreFlag = 0;
  for (let item of Object.keys(data)) {
    if (scoreFlag) {
      break;
    }
    if (data[item][1]) {
      if (data[item][0] == "Yes") {
        currScore++;
      } else if (data[item][0] == "Not Applicable") {
        maxScore--;
      } else {
        currScore = 0;
        scoreFlag = 1;
      }
    } else {
      if (data[item][0] == "Yes") {
        currScore++;
      }
      if (data[item][0] == "Not Applicable") {
        maxScore--;
      }
    }
  }

  return ((currScore / maxScore) * 100).toFixed(2);
};

export const getScore = (
  data,
  maxScore,
  auditFormHasWeightage,
  crrScore,
  isCritical
) => {
  let aFWithoutWeightageHasCritical = false;
  const keyOrder =
    secureLocalStorage.getItem("parameterSelectedList") &&
    secureLocalStorage.getItem("parameterSelectedList");
    
  if (Object.keys(data).length === 0) {
    return 0;
  }
  let currScore = 0;

  let scoreFlag = 0;
  let nCurrScore = 0;
  for (let item of Object.keys(data)) {
    if (scoreFlag) {
      break;
    }
    if (data[item][1]) {
      if (data[item][0] === "Yes") {
        if (data[item][3] !== 0) {
          currScore += data[item][2];
          maxScore =
            nCurrScore != 0 ? data[item][3] - nCurrScore : data[item][3];
        } else {
          currScore++;
        }
      } else if (data[item][0] === "Not Applicable") {
        if (auditFormHasWeightage === "TRUE") {
          if (data[item][3] !== 0) {
            nCurrScore += data[item][2];
            maxScore = data[item][3] - nCurrScore;
          }
        }
        //maxScore--;
      } else {
        if (data[item][3] !== 0 && data[item][0] === "No") {
          currScore = currScore + data[item][2] - data[item][2];
          maxScore =
            nCurrScore != 0 ? data[item][3] - nCurrScore : data[item][3];
        } else {
          if (isCritical === true) {
            currScore = 0;
            scoreFlag = 1;
          }
        }
      }
    } else {
      if (data[item][0] === "Yes") {
        if (data[item][3] !== 0) {
          currScore += data[item][2];
          maxScore = data[item][3];
        } else {
          currScore++;
        }
      }
      if (data[item][0] === "Not Applicable") {
        if (data[item][3] !== 0) {
          maxScore = data[item][3] - data[item][2];
        } else {
          maxScore--;
        }
      }
    }
  }

  let countMap = secureLocalStorage.getItem("countMap");
  if (countMap?.NotApplicable && auditFormHasWeightage === "FALSE") {
    // maxScore = maxScore - countMap?.NotApplicable;
  }
 
  if (
    secureLocalStorage.getItem("clickedLink") !== "textOnChangeRequest" &&
    secureLocalStorage.getItem("clickedLink") !== "auditsDetailLinkVisit"
  ) {
    
    //Calculation over Perform Audit as well as Audit Detail Screen
    if (secureLocalStorage.getItem("auditedQuestionScoreCount")) { 
      if (auditFormHasWeightage === "TRUE") {
        secureLocalStorage.setItem(
          "auditScore",
          ((currScore / maxScore) * 100).toFixed(2)
        );
        return secureLocalStorage.getItem("auditScore");
      } else {
        if (isCritical === true) {
          secureLocalStorage.setItem("auditedQuestionScoreCount", "0");
        }

        secureLocalStorage.setItem(
          "auditScore",
          (
            (secureLocalStorage.getItem("auditedQuestionScoreCount") /
              maxScore) *
            100
          ).toFixed(2)
        );

        return (
          (secureLocalStorage.getItem("auditedQuestionScoreCount") / maxScore) *
          100
        ).toFixed(2);
      }
    } else { 
      if (auditFormHasWeightage === "TRUE") {
        secureLocalStorage.setItem(
          "auditScore",
          ((currScore / maxScore) * 100).toFixed(2)
        );
        return secureLocalStorage.getItem("auditScore");
      }else{
        if (isCritical === true) {
          currScore = 0;
        }

        //Calculation over AuditForms Detail Screen
        secureLocalStorage.setItem(
          "auditScore",
          ((currScore / maxScore) * 100).toFixed(2)
        );
        return ((currScore / maxScore) * 100).toFixed(2);
      }
    }
  }
};

export const getDateAfterWeek = (date, returnType) => {
  // Create a specific date (e.g., "2023-10-07")
  const specificDate = new Date(date);

  // Calculate the date one week (7 days) from the specific date
  const oneWeekLater = new Date(specificDate);
  oneWeekLater.setDate(specificDate.getDate() + 7);

  // Format the date as a string (you can adjust the format as needed)
  const formattedDate = oneWeekLater.toDateString();

  // Convert the date to a timestamp (milliseconds since Unix epoch)
  const formattedDateTimeStamp = oneWeekLater.getTime();
  return returnType === "date" ? formattedDate : formattedDateTimeStamp;
};

export const getFormattedDateTime = (date) => {
  const rawDate = date;
  const formattedDate = new Date(rawDate);

  const year = formattedDate.getUTCFullYear();
  const month = (formattedDate.getUTCMonth() + 1).toString().padStart(2, "0");
  const day = formattedDate.getUTCDate().toString().padStart(2, "0");

  let hours = formattedDate.getUTCHours();
  const minutes = formattedDate.getUTCMinutes().toString().padStart(2, "0");
  const seconds = formattedDate.getUTCSeconds().toString().padStart(2, "0");

  let amPm = "AM";
  if (hours >= 12) {
    amPm = "PM";
    if (hours > 12) {
      hours -= 12;
    }
  }

  const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes} ${amPm}`;
  return formattedDateTime;
};
