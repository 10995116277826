import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const NavBar = () => {
  const location = useLocation();
  const [isActive, setIsActive] = useState(
    secureLocalStorage.getItem("isSubMenuActive") || "qms"
  );

  const [isSubMenuActive, setIsSubMenuActive] = useState(
    secureLocalStorage.getItem("isSubMenuActive") || "qms"
  );

  const [isFeedbackRequestsActive, setIsFeedbackRequestsActive] = useState(
    secureLocalStorage.getItem("isFeedbackRequestsActive") || false
  );

  const handleClick = (val) => {
    setIsActive(val);
    setIsFeedbackRequestsActive(false);
  };

  const handleSubMenuClick = (val, path) => {
    path = path && path.replace("/", "");
    const lastPageSearchIn = secureLocalStorage.getItem("lastPageSearchIn");
    if (path && lastPageSearchIn) {
      if (path !== lastPageSearchIn) {
        const searchKeywordOnRefresh = document.querySelector(".inputS");
        if (searchKeywordOnRefresh) {
          searchKeywordOnRefresh.value = ""; // Clears the text in the input field
        }
        if (secureLocalStorage.getItem("hasSearchedValue")) {
          secureLocalStorage.removeItem("hasSearchedValue");
        }
        if (secureLocalStorage.getItem("searchKeyword")) {
          secureLocalStorage.removeItem("searchKeyword");
        }
      }
    }

    setIsSubMenuActive((state) => ({
      ...state,
      isSubMenuActive: val,
    }));
    secureLocalStorage.setItem("isSubMenuActive", val);
    setIsFeedbackRequestsActive(false);
  };

  const handleFeedbackRequestsClick = (val) => {
    if (secureLocalStorage.getItem("isFeedbackRequestsActive") === "freq") {
      setIsFeedbackRequestsActive(false);
      secureLocalStorage.setItem("isFeedbackRequestsActive", false);
    } else {
      setIsFeedbackRequestsActive(val);
      secureLocalStorage.setItem("isFeedbackRequestsActive", val);
    }
  };

  const role = secureLocalStorage.getItem("role");

  const qmsLinks = {
    admin: [
      { path: "/dashboard", label: "Dashboard", icon: "fa fa-home" },
      { path: "/auditForms", label: "Audit Forms", icon: "fa fa-wpforms" },
      { path: "/audits", label: "Audits", icon: "Audits.png" },
      { path: "/deleted-audits", label: "Deleted Audits", icon: "Audits.png" },
      { path: "/escalations", label: "Escalations", icon: "escalations.png" },
      { path: "/processes", label: "Processes", icon: "fa fa-refresh" },
      { path: "/clients", label: "Clients", icon: "fa fa-user-secret" },
      { path: "/departments", label: "Departments", icon: "fa fa-building-o" },
    ],
    agent: [
      { path: "/dashboard", label: "Dashboard", icon: "fa fa-home" },
      { path: "/audits", label: "Audits", icon: "Audits.png" },
    ],
    atl: [
      { path: "/dashboard", label: "Dashboard", icon: "fa fa-home" },
      { path: "/audits", label: "Audits", icon: "Audits.png" },
    ],
    auditor: [
      { path: "/dashboard", label: "Dashboard", icon: "fa fa-home" },
      { path: "/auditForms", label: "Audit Forms", icon: "fa fa-wpforms" },
      { path: "/audits", label: "Audits", icon: "Audits.png" },
      { path: "/deleted-audits", label: "Deleted Audits", icon: "Audits.png" },
    ],
    qc: [
      { path: "/dashboard", label: "Dashboard", icon: "fa fa-home" },
      { path: "/departments", label: "Departments", icon: "fa fa-building-o" },
      { path: "/processes", label: "Processes", icon: "fa fa-refresh" },
      { path: "/auditForms", label: "Audit Forms", icon: "fa fa-wpforms" },
      { path: "/audits", label: "Audits", icon: "Audits.png" },
      { path: "/deleted-audits", label: "Deleted Audits", icon: "Audits.png" },
      { path: "/escalations", label: "Escalations", icon: "escalations.png" },
    ],
    tl: [
      { path: "/dashboard", label: "Dashboard", icon: "fa fa-home" },
      { path: "/audits", label: "Audits", icon: "Audits.png" },
    ],
    manager: [{ path: "/dashboard", label: "Dashboard", icon: "fa fa-home" }],
  };

  const vocLinks = {
    admin: [
      { path: "/forms/add", label: "Add Form", icon: "fa fa-wpforms" },
      { path: "/forms", label: "Forms", icon: "fa fa-wpforms" },
      {
        path: "",
        label: "Feedback Requests",
        icon: "fa fa-commenting-o",
        subLinks: [
          {
            path: "/feedback-requests/open",
            label: "Open",
            icon: "fa fa-commenting-o",
          },
          {
            path: "/feedback-requests/closed",
            label: "Closed",
            icon: "fa fa-commenting-o",
          },
          {
            path: "/feedback-requests/expired",
            label: "Expired",
            icon: "fa fa-commenting-o",
          },
        ],
      },
    ],
    qc: [
      { path: "/forms", label: "Forms", icon: "fa fa-wpforms" },
      {
        path: "",
        label: "Feedback Requests",
        icon: "fa fa-commenting-o",
        subLinks: [
          {
            path: "/feedback-requests/open",
            label: "Open",
            icon: "fa fa-commenting-o",
          },
          {
            path: "/feedback-requests/closed",
            label: "Closed",
            icon: "fa fa-commenting-o",
          },
          {
            path: "/feedback-requests/expired",
            label: "Expired",
            icon: "fa fa-commenting-o",
          },
        ],
      },
    ],
  };

  const mpsruLinks = {
    admin: [
      { path: "/permissions", label: "Permissions", icon: "fa fa-wpforms" },
      { path: "/sections", label: "Sections", icon: "fa fa-wpforms" },
      { path: "/roles", label: "Roles", icon: "fa fa-wpforms" },
      { path: "/users", label: "Users", icon: "fa fa-users" },
    ],
  };

  const renderSubLinks = (subLinks) => {
    return subLinks.map(({ path, label, icon }) => (
      <Link
        key={label}
        to={path}
        onClick={() => {
          label === "Feedback Requests"
            ? handleFeedbackRequestsClick("freq")
            : handleSubMenuClick("voc", path);
        }}
        className="links"
        style={{ backgroundColor: location.pathname === path ? "#EDF1F4" : "" }}
      >
        {icon.startsWith("fa") ? (
          <i className={icon} aria-hidden="true"></i>
        ) : (
          <img src={`/${icon}`} alt="logo" />
        )}
        <span className="dashboardstyle">{label}</span>
      </Link>
    ));
  };

  return (
    <>
      {((location.pathname.split("/")[1] !== "auditForms" ||
        location.pathname.split("/")[1] !== "audits" ||
        location.pathname.split("/")[1] === "escalations") &&
        !location.pathname.split("/")[2]) ||
      ((location.pathname.split("/")[1] === "users" ||
        location.pathname.split("/")[1] === "roles" ||
        location.pathname.split("/")[1] === "feedback-requests" ||
        location.pathname.split("/")[1] === "forms") &&
        location.pathname.split("/")[2] &&
        location.pathname.split("/")[2] !== "preview") ? (
        <div className="sidenav">
          <div className="menu">
            <div className={isActive === "qms" ? "menuActive" : ""}>
              <span
                className="d-block links"
                onClick={() => handleClick("qms")}
              >
                QMS
                <i
                  className={
                    isActive === "qms"
                      ? "fa fa-angle-up dropdown"
                      : "fa fa-angle-down dropdown"
                  }
                />
              </span>
              <div
                className={
                  isActive === "qms" ? "activeClass" : "inactiveClass dropwdown"
                }
              >
                {qmsLinks[role].map(({ path, label, icon }) => (
                  <Link
                    key={label}
                    to={path}
                    className="links"
                    onClick={() => handleSubMenuClick("qms", path)}
                    style={{
                      backgroundColor:
                        location.pathname === path ? "#EDF1F4" : "",
                    }}
                  >
                    {icon.startsWith("fa") ? (
                      <i className={icon} aria-hidden="true"></i>
                    ) : (
                      <img src={`/${icon}`} alt="logo" />
                    )}
                    <span className="dashboardstyle">{label}</span>
                  </Link>
                ))}
              </div>
            </div>
            {role === "admin" || role === "qc" ? (
              <div className={isActive === "voc" ? "menuActive" : ""}>
                <span
                  className="d-block links"
                  onClick={() => handleClick("voc")}
                >
                  VOC
                  <i
                    className={
                      isActive === "voc"
                        ? "fa fa-angle-up dropdown"
                        : "fa fa-angle-down dropdown"
                    }
                  />
                </span>
                <div
                  className={
                    isActive === "voc"
                      ? "activeClass"
                      : "inactiveClass dropwdown"
                  }
                >
                  {vocLinks[role].map(({ path, label, icon, subLinks }) => (
                    <>
                      {label === "Feedback Requests" ? (
                        <span
                          key={label}
                          className={
                            (location.pathname.split("/")[1] ===
                              "feedback-requests" &&
                              location.pathname.split("/")[2]) ||
                            isFeedbackRequestsActive !== false
                              ? "d-block links subMnu subMnuActive"
                              : "d-block links subMnu"
                          }
                          onClick={() => {
                            label === "Feedback Requests"
                              ? handleFeedbackRequestsClick("freq")
                              : handleSubMenuClick("voc", path);
                          }}
                        >
                          {label}
                          <i
                            className={
                              (location.pathname.split("/")[1] ===
                                "feedback-requests" &&
                                location.pathname.split("/")[2]) ||
                              isFeedbackRequestsActive !== false
                                ? "fa fa-angle-up dropdown"
                                : "fa fa-angle-down dropdown"
                            }
                          />
                        </span>
                      ) : (
                        <Link
                          key={label}
                          to={path}
                          className="links"
                          onClick={() => handleSubMenuClick("voc", path)}
                          style={{
                            backgroundColor:
                              location.pathname === path ? "#EDF1F4" : "",
                          }}
                        >
                          <i className={icon} aria-hidden="true"></i>
                          <span className="dashboardstyle">{label}</span>
                          {subLinks && (
                            <i
                              className={
                                (location.pathname.split("/")[1] ===
                                  "feedback-requests" &&
                                  location.pathname.split("/")[2]) ||
                                isFeedbackRequestsActive !== false
                                  ? "fa fa-angle-up dropdown"
                                  : "fa fa-angle-down dropdown"
                              }
                              style={{ float: "right" }}
                            />
                          )}
                        </Link>
                      )}
                      {subLinks && (
                        <>
                          {" "}
                          <div
                            className={
                              (location.pathname.split("/")[1] ===
                                "feedback-requests" &&
                                location.pathname.split("/")[2]) ||
                              isFeedbackRequestsActive !== false
                                ? "activeClass subMnuChildActive"
                                : "inactiveClass dropwdown"
                            }
                          >
                            {renderSubLinks(subLinks)}{" "}
                          </div>
                        </>
                      )}
                    </>
                  ))}
                </div>
              </div>
            ) : null}
            {role === "admin" ? (
              <div className={isActive === "mpsru" ? "menuActive" : ""}>
                <span
                  className="d-block links"
                  onClick={() => handleClick("mpsru")}
                >
                  Manage Users
                  <i
                    className={
                      isActive === "mpsru"
                        ? "fa fa-angle-up dropdown"
                        : "fa fa-angle-down dropdown"
                    }
                  />
                </span>
                <div
                  className={
                    isActive === "mpsru"
                      ? "activeClass"
                      : "inactiveClass dropwdown"
                  }
                >
                  {mpsruLinks[role].map(({ path, label, icon, subLinks }) => (
                    <Link
                      key={label}
                      to={path}
                      className="links"
                      onClick={() => handleSubMenuClick("mpsru", path)}
                      style={{
                        backgroundColor:
                          location.pathname === path ? "#EDF1F4" : "",
                      }}
                    >
                      <i className={icon} aria-hidden="true"></i>
                      <span className="dashboardstyle">{label}</span>
                    </Link>
                  ))}
                </div>
              </div>
            ) : null}
            {role === "admin" && (
              <div className={isActive === "settings" ? "menuActive" : ""}>
                <>
                  <Link
                    to="/settings"
                    className="d-block links"
                    onClick={() => {
                      handleClick("settings");
                      handleSubMenuClick("settings");
                    }}
                  >
                    <span className="dashboardstyle settings">Settings</span>
                  </Link>
                </>
              </div>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default NavBar;
