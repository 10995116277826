import React from "react";
import { Modal, Form, Input } from "antd";

const DuplicateTransactionReasonForm = (props) => {
  const { open, setVisible, onCreate } = props;
  const [form] = Form.useForm();

  const handleCreate = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        onCreate(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  return (
    <Modal
      open={open}
      title=""
      okText="Submit"
      onCancel={() => {
        setVisible(false);
      }}
      onOk={handleCreate}
      width={450}
      className="duplicationTransactionReasonModal"
    >
      <Form form={form} layout="">
        <Form.Item
          label={
            <>
              <span
                style={{ whiteSpace: "normal", fontWeight: "600", padding: 0 }}
              >
                This is a duplicate entry. Please give a reason why same
                transaction id needs to be audited again
              </span>
            </>
          }
          name="transactionDuplicateReason"
          rules={[{ required: true, message: "Please input the reason!" }]}
          labelAlign="left"
          className="transactionDuplicateReason"
        >
          <Input.TextArea rows={2} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DuplicateTransactionReasonForm;
